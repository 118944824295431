.review-modal .modal-dialog {
  max-width: 450px;
}

.review-modal .modal-content {
  border-radius: 24px;
  background: #ffffff 0% 0% no-repeat padding-box;
}

.review-modal .review {
  display: flex;
  flex-direction: column;
}

.review-modal .review .review-header {
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 0px;
}

.review-modal .review .review-header h5 {
  font-size: 19px;
  color: #152232;
  font-family: "Inter", sans-serif !important;
  font-weight: 600;
  text-align: center;
}

.review-modal .review .form-group .form-control {
  height: 145px;
  border-radius: 6px;
  font-size: 14px;
  color: #b7b7b7;
}

.review-modal .review .review-btn {
  background: #fcbb52;
  border-radius: 7px;
  height: 55px;
  border-color: #fcbb52;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  margin: 15px 0px;
}

.review-modal .review h6 {
  font-size: 15px;
  color: #7b818d;
  font-weight: 500;
  margin: 10px 0px;
}

.review ul {
  text-align: center;
  padding: 10px 30px;
}

.review ul li {
  display: inline-block;
  padding: 0px 10px;
}

.review img {
  height: 40px;
  cursor: pointer;
}

.review img.angry_gray:hover {
  content: url(../../../assets/angry_emoji.png);
  transform: scale(1.13);
}

.review img.poor_gray:hover {
  content: url(../../../assets/poor_emoji.png);
  transform: scale(1.13);
}

.review img.confused_gray:hover {
  content: url(../../../assets/confused_emoji.png);
  transform: scale(1.13);
}

.review img.good_gray:hover {
  content: url(../../../assets/good_emoji.png);
  transform: scale(1.13);
}

.review img.excellent_gray:hover {
  content: url(../../../assets/excellent_emoji.png);
  transform: scale(1.13);
}
