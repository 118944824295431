.specialist-contents .card .card-body h6{
    font-family: Karla, sans-serif;
}

.specialist-contents .card{
    cursor: pointer;
}

.specialist-contents .push-noticon{
    padding-right: 10px;
    cursor: pointer;
}

.specialist-contents .font-13{
    font-size: 13px !important;
    font-family: Karla, sans-serif;
    font-weight: 400!important;
    color: #98a6ad;
}
.specialist-contents .avatar-group .avatar-group-item .avatar-sm{
    width: 25px;
    height: 25px;
}
.specialist-contents .admin.avatar-group .admin.avatar-group-item{
    border: 2px solid white;
}
.specialist-contents p{
    font-family: Roboto, sans-serif!important;
}
.specialist-contents .text-muted{
    color: #98a6ad;
    font-weight: 400!important;
    font-size: 12px!important;
}

.specialist-contents .noti-icon{
    cursor: pointer;
}
.specialist-contents .card .card-header-divs{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.specialist-contents .card .card-header-divs .ellipsis{
    color: #98a6ad;
    font-size: 18px;
    display: inline-block;
    line-height: 1px;
    background-color: transparent;
    border-color: transparent!important;
}
.specialist-contents .card .card-header-divs .ellipsis::after{
    display: none;
}
.specialist-contents .card .card-header-divs .ellipsis:focus{
    box-shadow: 0 0 0 0rem rgb(49 132 253 / 50%);
}
.specialist-contents .action-menu .dropdown-menu{
    padding: 12px 6px;
}
.specialist-contents .action-menu .dropdown-menu .dropdown-item:hover{
    background-color: #fcbb52;
    color: #fff;
    border-radius: 3px;
}
.specialist-contents .card-body .card-names{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.specialist-contents .card-body .title-name h6{
    font-size: 17px;
    margin-top: 9px!important;
}
.specialist-contents .card-body .title-name p{
    font-size: 14px!important;
    font-family: Karla, sans-serif;
    font-weight: 450!important;
    margin-bottom: 0px;
}
.specialist-contents .card .list-inline h5{
    font-size: 18px;
}
.specialist-contents .card .list-inline{
    margin-bottom: 0px!important;
    margin-top: 1.8rem;
}
.specialist-contents .card .list-inline p{
    margin-bottom: 0px;
}
.specialist-contents .card .list-inline ~ .float-end{
    margin-top: 1.8rem;
}
.specialist-contents .card .card-header-divs .badge{
    padding: 10px 10px;
}

.specialist-contents .card .project-box .float-end .noti-icon {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

/* --------------------------------------*/
/* Mini desktop and All laptop View Media Query (1224px)  */
/* --------------------------------------*/
@media all and (max-width: 1224px){
    .specialist-contents .admin.col-xl-4{
        width: 50%;
    }
}
/* --------------------------------------*/
/* Mini laptop and All Tabs View Media Query (1024px)  */
/* --------------------------------------*/
@media all and (max-width: 1024px){
    .specialist-contents .admin.col-xl-4{
        width: 50%;
    }
}

/* --------------------------------------*/
/* Mini tab and All mobile View Media Query (780px)  */
/* --------------------------------------*/
@media all and (max-width: 780px){
    .specialist-contents .admin.col-xl-4{
        width: 100%;
    }
}