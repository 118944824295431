.faqs .container{
    padding-bottom: 4rem;
    padding-top: 8rem;
}
.faqs .req-list .req-list-div{
    margin: 0px 0px 10px 0px;
    border: 1px solid #E6E6E6;
    border-radius: 4px 4px 4px 4px;
}
.faqs .collapsible {
    background: #EFEFEF;
    cursor: pointer;
    padding: 16px 23px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    border-radius: 4px 4px 4px 4px;
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faqs .active,.faqs  .collapsible:hover {
    background-color: #ebeced;
}

.faqs .content {
    background-color: #ffffff;
    transition: all .5s ease-in-out;
    height: 0px;
    overflow: hidden;
}
.faqs  .collapsible.active + .collapsible-bottom  .content{
    height:  100%;
    overflow: hidden;
    font-family: 'Inter', sans-serif!important;

}
.faqs .faqs-list-collapse th{
    color: #7B818D;
    font-family: 'Inter', sans-serif!important;
    font-weight: 500;
    font-size: 13px;
}
.faqs .faqs-list-collapse .primaryTr{
    color: #152232;
    font-family: 'Inter', sans-serif!important;
    font-size: 15px;
    font-weight: 500;
}

.faqs  .collapsible.active + .collapsible-bottom{
    height:  100%;
    overflow: visible;
    display: block!important;
    padding: 30px 20px;
    transform: scaleY(1);
}
.faqs .collapsible-bottom {
    display: none;
    background: white;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    border-radius: 4px 4px 4px 4px;
    transition: all .5s ease-in-out;
    transform-origin: left top;
    transform: scaleY(0);
}
.faqs h6{
    font-size: 27px;
    font-family: 'Inter', sans-serif!important;
    font-weight: 600;
    color: #152232;
}
.faqs label{
    font-size: 15px;
    font-family: 'Inter', sans-serif!important;
    font-weight: 400;
    color: #58657E;
}
.faqs .collapsible-bottom,.faqs  .collapsible-bottom:hover {
    background-color: #ffffff;
}

.faqs .collapsible-bottom .image-data-list{
    display: flex;
}
/* ----------------------------------------------- */
/* Mini Laptop and All Tab devices Media Query (767px) */
/* ------------------------------------------------*/
@media all and (max-width:  1024px){
    .faqs .container{
        max-width: 90%;
    }
}
/* ----------------------------------------------- */
/* Mini Tab and All Tab devices Media Query (767px) */
/* ------------------------------------------------*/
@media all and (max-width: 767px){
    .faqs .container{
        max-width: 94%;
    }
}
/* ----------------------------------------------- */
/* Mini Mobile and All Mobile devices Media Query (480px) */
/* ------------------------------------------------*/
@media all and (max-width: 480px){
    .faqs .collapsible{
        font-size: 13px;
        padding: 10px 10px 10px 10px;
    }
    .faqs .content{
        font-size: 12px;
    }
    .faqs .container{
        max-width: 100%;
    }
    .faqs label{
        font-size: 14px;
    }
    .faqs .collapsible.active + .collapsible-bottom{
        padding: 15px 15px;
    }
    .faqs h6{
        font-size: 24px;
    }
}

/* ----------------------------------------------- */
/* Mini Mobile devices Media Query (320px) */
/* ------------------------------------------------*/
@media all and (max-width: 320px){
    .faqs h6{
        font-size: 24px;
    }
    .faqs .container{
        max-width: 100%;
    }
    .faqs label{
        font-size: 12px;
    }
    .faqs .collapsible{
        font-size: 12px;
    }
}