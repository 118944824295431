.attached-files .files-list .file-box .img-thumbnail{height: 70px; width: 65px; object-fit: cover; border-radius: 10px; cursor: pointer;}

.attached-files .files-list .file-box {
  position: relative;
}

.req-detail .form-control:disabled{
    background-color: #e9ecef;
}

.req-detail .req-images-label{
  display: block;
}

.req-detail .vehicle-location-place {
  position: relative;
  z-index: 999;
  cursor: pointer;
}

.req-detail .vehicle-location-place .autoplace-location{
    position: absolute;
    width: 100%;
    overflow: scroll;
}

.req-detail .markas{
  background: #FCBB52;
  height: 45px;
  color: white;
  border-radius: 10px;
}

.req-detail .close-media {
  position: absolute;
  right: -5px;
  top: -10%;
  border-radius: 50px;
  background: #F2F2F2;
  width: 20px;
  cursor: pointer;
}
.req-detail .payment_receipt-div img{
  width: 149px;
  margin: 13px 0px;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/* --------------------------------------*/
/* Mini Mobile and All Mobile View Media Query (480px)  */
/* --------------------------------------*/
@media all and (max-width: 480px) {
  .req-detail .markas {
      width: 190px;
      line-height: 5px;
      white-space: nowrap;
      margin-top: 5px;
      font-size: 14px;
  }
 
  .admin.d-flex{
    flex-direction: column;
  }
}

/* --------------------------------------*/
/* Mini Mobile View Media Query (320px)  */
/* --------------------------------------*/
@media all and (max-width: 320px) {
.req-detail .markas {
   width: 180px;
   line-height: 5px;
   white-space: nowrap;
   margin-top: 5px;
}

.admin.d-flex{
flex-direction: column;
}
}