.header-nav {
  height: 100px;
  width: 100%;
  position: absolute;
  margin-bottom: 14px;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 199;
  transition: all 0.5s ease 0s;
}
.header-nav.stick {
  background-color: black !important;
  position: fixed;
  top: 0px;
  z-index: 100;
  height: 60px;
  z-index: 1299;
}
.header-nav.stick .header-nav-inner .header-right a.header-pages button,
.header-nav.stick .header-nav-inner .header-right .register {
  color: white !important;
  border-radius: 0px;
}
.header-nav .header-nav-inner .header-right a.header-pages button {
  transition: all 0.5s ease-in-out;
  border-bottom: 2px solid transparent;
  border-radius: 0px;
}
.header-nav .header-nav-inner .header-right a.header-pages button:hover {
  border-bottom: 2px solid #fcbb52;
  transform-origin: 0% 0%;
  transform: scaleX(1);
}
.header-nav .mobile-viewdrawer,
.header-nav .mo-drawer-icon {
  display: none;
}
.mobile-drawer.offcanvas.show {
  background-color: #000000e6 !important;
  padding-top: 30px;
  width: 100%;
  transition: transform 0.3s ease-in-out !important;
  z-index: 1999;
}
.mobile-drawer h4 {
  color: #ffffff;
  font-size: 30px;
  font-weight: 600;
}
.mobile-drawer .fa.fa-times {
  color: white;
  position: fixed;
  right: 0px;
  top: 0px;
  padding: 14px 19px;
  margin: 4px;
}
.mobile-drawer h4 span {
  color: #fcbb52;
}
.mobile-drawer .offcanvas-body {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.mobile-drawer .offcanvas-body a {
  margin: 20px;
}
.mobile-drawer .offcanvas-body a button {
  font-size: 16px;
  color: #ffffff !important;
  font-family: "Inter", sans-serif;
  font-weight: 500;
}
.mobile-drawer .offcanvas-body a button:focus {
  box-shadow: 0 0 0 0rem rgb(13 110 253 / 25%);
}
.header-nav .Logos-div {
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.header-nav .Logos-div h4 {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  line-height: 23px;
  vertical-align: middle;
  margin: 0px;
}
.header-nav.stick .Logos-div h4 {
  color: white !important;
}
.header-nav .Logos-div h4 span {
  color: #fcbb52;
}
.header-nav .header-nav-inner {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.header-nav .header-nav-inner nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 30px;
}
.header-nav .header-nav-inner .header-right {
  display: flex;
  align-items: center;
  flex-direction: row;
  visibility: visible;
}
.header-nav .header-nav-inner .header-right a.active button {
  border-bottom: 2px solid #fcbb52;
}
.header-nav .header-nav-inner .header-right a button {
  margin: 0px 4px;
}
.header-nav .header-nav-inner .header-right a button:focus {
  box-shadow: 0 0 0 0rem rgb(13 110 253 / 25%);
}
.header-nav .text-upper {
  text-transform: capitalize;
}
.header-nav button {
  font-family: "Inter", sans-serif;
  font-weight: 550;
}
.header-nav .primaryText {
  color: #152232;
}
.header-nav .login-btn {
  width: 90px;
  border: 2px solid #fcbb52;
}

.specialist-btn {
  width: 120px;
  border: 2px solid #1BCAF4;
  background: #1BCAF4;
  border-radius: 7px;
  color: rgb(21, 34, 50);
  font-size: 14px;
  font-weight: 500;
  padding: 6px 20px;
}

.specialist-btn:hover{
  background-color: transparent !important;
  color: #1BCAF4 !important;
  border: 2px solid #1BCAF4 !important;
}

.header-nav .login-btn:hover {
  background-color: transparent !important;
  color: #fcbb52 !important;
  border: 2px solid #fcbb52 !important;
}
.dropdown-user button{
  border: 2px solid transparent !important;
}

.dropdown-user button:focus-visible,
.dropdown-user button:hover,
.dropdown-user .show > .btn-primary.dropdown-toggle {
  border: 2px solid transparent !important;
}
.dropdown-user button:focus {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%) !important;
}
.dropdown-user .user_circle {
  height: 29px;
  width: 29px;
  border-radius: 100px;
  margin-right: 4px;
}
.dropdown-user .user_circle img {
  height: 100%;
  width: 100%;
  border-radius: 100px;
}
.dropdown-user button {
  display: flex;
  align-items: center;
}
.slide-effect.desktop {
  display: flex;
  align-items: center;
}
.dropdown-user .dropdown-menu .dropdown-item {
  display: flex;
  padding: 7px 10px;
  align-items: center;
  justify-content: flex-start;
  margin: 0px;
}
.dropdown-user .dropdown-menu .dropdown-item .notification_icon {
  height: 100%;
  width: 30px;
}
.dropdown-user .dropdown-menu .dropdown-item .notification_icon img {
  height: 100%;
  width: 15px;
}
.dropdown-user .dropdown-menu .dropdown-item {
  font-family: "Inter", sans-serif;
  font-size: 14px;
}
.mobile-viewdrawer.offcanvas.show {
  z-index: 1999 !important;
}
.bounceInRight {
  animation-duration: 2s;
  animation-name: slideout;
}
.bounceIn {
  animation-duration: 2s;
  animation-name: bounceIn;
}
.slideOut {
  animation-duration: 2s;
  animation-name: slideout;
}
.header-nav.stick .header-nav-inner .header-right .mo-drawer-icon i {
  color: white !important;
}
.header-nav .mo-drawer-icon {
  padding: 15px 20px;
}
.dropdown-user .dropdown-menu .dropdown-item:active {
  background-color: #fff;
}
.header-nav .flag-en,
.flag-en{
  height: 23px;
  margin-right: 6px;
  object-fit: cover;
}
.header-nav .language-dropdown .dropdown-toggle{
  font-family: "Inter", sans-serif;
  background-color: transparent;
  border-color: transparent;
}
.language-dropdown .dropdown-toggle:focus,
.language-dropdown.show>.btn-primary.dropdown-toggle{
  background-color: transparent!important;
  border-color: transparent!important;
  box-shadow: 0 0 0 0.25rem transparent!important;
}

.language-dropdown .dropdown-item,
.language-dropdown .dropdown-header{
  font-size: 12px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  margin: 0px!important;
}
.language-dropdown .dropdown-item{
  padding: 0.25rem 0.8rem;
}
.language-dropdown .dropdown-item:hover{
  background-color: #fcbb52;
  color: white;
}
.language-dropdown .dropdown-menu{
  padding: 5px 10px 10px 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border: 1px solid #f1f2f3;
}
.language-dropdown button,
.language-dropdown button:hover{
  background-color: transparent;
  border: 1px solid transparent;
}
.header-nav.stick button.dropdown-toggle{
  color: white!important;
}
.language-dropdown .flag-select-en{
  height: 26px;
}

/*
@keyframes slideLeft {
    from {
        margin-left: -100%;
        width: 100%;
    }

    to {
        margin-right: 10%;
        width: 100%;
    }
}
@keyframes slidein {
    from {
        margin-left: 100%;
        width: 100%;
    }
    to {
        width: 100%;
        margin-right: 0%;
    }
}
@keyframes bounceIn {
    0% { 
       opacity: 0; 
       transform: scale(.3);
    }
    50% { 
       opacity: 1;
       transform: scale(1.05);
    }
    70% {
       transform: scale(.9);
    }
    100% {
       transform: scale(1);
    }
 }
@keyframes slideout {
    from {
        margin-left: -300%;
        width: 100%;
    }

    to {
        margin-left: 10%;
        width: 100%;
    }
}
@keyframes bounceInRight {
   0% {
      opacity: 0;
      transform: translateX(2000px);
   }
   60% {
      opacity: 1;
      transform: translateX(-30px);
   }
   80% {
      transform: translateX(10px);
   }
   100% {
      transform: translateX(0);
   }
}  */

/* 
  Tab View Media Query
*/
@media all and (max-width: 1024px) {
  .header-nav .header-nav-inner nav {
    padding: 0px 10px 0px 25px !important;
  }
  .header-right .slide-effect.desktop {
    display: none;
  }
  .header-right .mo-drawer-icon {
    display: block;
  }
  .mobile-drawer .offcanvas-body div.dropdown-user {
    position: absolute;
    /* bottom: 19px; */
    top: 3%;
  }
  .mobile-drawer .offcanvas-body.log {
    padding: 66px;
    position: relative;
  }
  
}
/* Mini Tabs  */
@media all and (max-width: 992px) {
}
/* Mini Tab */
@media all and (max-width: 767px) {
  .header-nav .header-nav-inner nav {
    padding: 0px 10px 0px 25px !important;
  }
  .mobile-drawer .offcanvas-body div.dropdown-user {
    position: absolute;
    /* bottom: 19px; */
    top: 3%;
  }
  .mobile-drawer .offcanvas-body.log {
    padding: 66px;
    position: relative;
  }
  .header-nav .mobile-viewdrawer,
  .header-nav .mo-drawer-icon {
    display: block;
  }
  .header-nav .slide-effect {
    display: none;
  }
}
@media all and (max-width: 667px) {
  .offcanvas-body .specialist-btn{
    width: 100%;
  }
}
/* 
  Mobile View Media Query
*/
@media all and (max-width: 480px) {
  .header-nav .header-nav-inner nav {
    padding: 0px 0px 0px 15px !important;
  }
  .mobile-drawer .offcanvas-body div.dropdown-user {
    position: absolute;
    /* bottom: 19px; */
    top: 5%;
  }
  .mobile-drawer .offcanvas-body.log {
    padding: 66px;
    position: relative;
  }
  .header-nav .header-nav-inner nav {
    padding: 0px 10px;
  }
  .header-nav .header-nav-inner .header-right .desktop {
    display: none;
  }
  .header-nav .mo-drawer-icon {
    display: block;
  }
  .header-nav.stick .mo-drawer-icon i {
    color: white !important;
  }
  .header-nav.stick .Logos-div h4,
  .header-nav .Logos-div h4 {
    font-size: 25px;
  }
}

@media all and (max-width: 320px) {
  .header-nav.stick .Logos-div h4,
  .header-nav .Logos-div h4 {
    font-size: 20px;
  }
  .specialist-btn{
    width: 90px;
    font-size: 13px;
  }
}
