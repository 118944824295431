.privacy-contents .form-label{
    margin-bottom: 0rem;
    font-weight: 600;
    font-size: 0.9rem;
    color: #343a40;
}
.privacy-contents .admin.form-control,.privacy-contents .admin.form-select{
    margin-top: 0.5rem;
}
.privacy-contents .upload-image-title{
    display: flex;
    align-items: center;
    justify-content: center;
}
.privacy-contents .about-tab.nav-pills .nav-link{
    border-radius: 0px;
    color: #a1a1a1;
}
.privacy-contents .about-tab.nav-pills .nav-link.active{
    background-color: transparent!important;
    color: #f9c851;
    border-bottom: 2px solid #f9c851;
}

.privacy-contents li::marker{
    unicode-bidi: isolate;
    font-feature-settings: "tnum";
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 2px !important;
    text-align: start !important;
    text-align-last: start !important;
    color: #fcbb52;
    font-size: 20px;
}
.privacy-contents .privacy-display-right{
    display: flex;
    justify-content: flex-end;
}
.privacy-contents .privacy-display-right button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    margin-top: -39px;
    height: 3rem;
    opacity: 1;
    background: linear-gradient(195deg, rgb(255 185 16), rgb(252 187 82));
    color: rgb(255, 255, 255);
    border-radius: 0.75rem;
    box-shadow: rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(64 64 64 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem;
}
.privacy-contents .privacy-display-right button:hover {
    box-shadow: rgb(255 255 254 / 18%) 0rem 0.875rem 1.625rem -0.75rem, rgb(192 192 192 / 22%) 0rem 0.25rem 1.4375rem 0rem, rgb(157 157 157 / 67%) 0rem 0.5rem 0.625rem 0;
}
.privacy-contents .header-title{
    font-size: 27px;
}