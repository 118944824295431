.container.sp-google-map{
    padding-top: 6rem;
    padding-bottom: 1rem;
    max-width: 95%;
    overflow-x: hidden;
}

.sp-google-map .main-map{
position: relative;
}

.sp-google-map .main-map .specialist-map-tol-info{
    max-width: 250px;
}

.proceed-div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.proceed-div .continue-btn {
    height: 46px;
    float: right;
    background: #fcbb52;
    border-radius: 6px;
    border-color: #fcbb52;
    color: #152232;
    font-size: 15px;
    font-weight: 450;
    
font-family: "Inter", sans-serif;

}

.sp-google-map .gm-style .gm-style-iw-c{
    padding: 0px;
} 
.sp-google-map .gm-style .gm-style-iw-t{
    bottom: 70px !important;
}

input[type="radio"] {
    display: none;
}

.sp-google-map .selected-sp{
    display: flex;
    flex-direction: column;
    padding: 15px;
}

.sp-google-map .selected-sp h6 {
     font-size: 12px;
}

.gm-style .gm-style-iw-t::after{
  display: none;
}

.sp-google-map #sp-opt:checked:checked ~ .selected-sp
{
    border-color: #fcbb52 !important;
    background: rgb(252, 187, 82) !important;
}

.gm-style .gm-ui-hover-effect{
    display: none !important;
}

/*Change location */

.sp-google-map h5 {
    white-space: nowrap;
    font-size: 18px;
    line-height: 40px;
    font-family: 'Inter', sans-serif!important;
}

.sp-google-map .spanish-chloc {
    white-space: nowrap;
    font-size: 14px;
    line-height: 40px;
    font-family: 'Inter', sans-serif!important;
}

.sp-google-map .vehicle-location-place {
    position: relative;
    z-index: 1;
    max-height: 25px;
    cursor: pointer;
}

.sp-google-map .vehicle-location-place .location-search{
    background: #F7F7F7;
    border: 2px solid #EBEBEB;
    border-radius: 8px;
    font-family: 'Inter', sans-serif!important;
    padding: 6px 0px;
}

.sp-google-map .vehicle-location-place .location-search .form-control,
.sp-google-map .vehicle-location-place .location-search .input-group-text{
 border: none;
 background: #F7F7F7;
}

.sp-google-map .vehicle-location-place .location-search .form-control:focus{
    box-shadow:none;
}

.sp-google-map .vehicle-location-place .location-search .input-group-text img{
    width: 17px;
}

.sp-google-map .vehicle-location-place .location-search .form-control{
    padding: 0px;
}


/* Specialist list */

.sp-list .overflow-list{
    height: 450px;
    overflow: auto;
   
}

.sp-list .specialist-list {
    height: 500;
}

.sp-list .sp-title{
    font-size: 18px;
    font-weight: 600;
    font-family: 'Inter', sans-serif!important;
}

.sp-list .splist-search {
    border: 1px solid #EBEBEB;
    padding: 5px;
    border-radius: 10px;
    margin: 13px 0px;
}

.sp-list .splist-search .input-group-text{
    background: white;
    border: none;
}

.sp-list .splist-search img {
    height: 20px;
    width: 20px;
}

.sp-list .splist-search .form-control {
    border: none;
}

.sp-list .splist-card.card {
    flex-direction: inherit;
    border-radius: 13px;
    border: 1px solid #EBEBEB;
    background: #ffff 0% 0% no-repeat padding-box;
    align-items: center;
    box-shadow: 0px 10px 10px #0000000a;
    margin-bottom: 13px;
   cursor: pointer;
}

.sp-list .splist-card.card .card-body{
 padding: 0px;
}

.sp-list .splist-card.card .card-img {
    width: 90px;
    border-radius: 35px;
    object-fit: cover;
    padding: 10px;
}
.sp-list .splist-card.card .sp-info{
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
}
.sp-list .splist-card.card .sp-info label {
    font-size: 16px;
    font-family: 'Inter', sans-serif!important;
    font-weight: 500;
    color: #222222;
}

.sp-list .splist-card.card .sp-info .sp-list-year{
    color: #989898;
    font-weight: 600;
    padding-right: 5px;
}


.sp-list .splist-card.card .sp-info .sp-list-specialisation {
    color: #FCBB52;
    font-weight: 600;
    padding-right: 5px;
}
 
.sp-list #sp-list-opt:checked ~ .splist-card.card {
    border: 1px solid #FFD590 !important;
    background: #FFEFD1 0% 0% no-repeat padding-box !important;
}

.sp-google-map .no-specialist-found{
    text-align: center;
}

.sp-google-map .no-specialist-found h5{
  font-size: 14px;
}


/*--------------------------------------------*/
/* Laptop with Desktop (1600px) View*/
/*--------------------------------------------*/
@media all and (max-width:1600px){
.sp-google-map .view-sp-list-btn {
       display: none;
     }
}

/*--------------------------------------------*/
/* Laptop with Desktop (1400px) View*/
/*--------------------------------------------*/
@media all and (max-width:1400px){
.sp-google-map .view-sp-list-btn {
        display: none;
      }
      .sp-google-map h5{
        font-size: 16px;
    }

    .sp-google-map .no-specialist-found h5{
        font-size: 12px;
    }
}


/*--------------------------------------------*/
/* Laptop with Tab (1199px) View*/
/*--------------------------------------------*/
@media all and (max-width:1199px){
.sp-google-map .view-sp-list-btn {
        display: none;
      
 }

}

/* ------------------------------------- */
/* Mini laptop and mini desktop View Media Query (1200px) */
/* --------------------------------------*/
@media (max-width: 1200px) {
.sp-google-map .view-sp-list-btn {
        display: none;
      
 }
/* .sp-google-map .change-location{
    right: 10px;
} */
}


/* --------------------------------------*/
/* Mini Laptop And All Tab View Media Query (1024px)  */
/* --------------------------------------*/
@media all and (max-width:1024px){
.sp-google-map .view-sp-list-btn {
        display: none;
      }
 
      .sp-google-map h5{
          font-size: 13px;
      }

      .sp-google-map .spanish-chloc {
        font-size: 12px;
      }

      .sp-google-map .no-specialist-found h5{
          font-size: 10px;
      }


}


/*--------------------------------------------*/
/* Mini Laptop with Tab (991px) View*/
/*--------------------------------------------*/
@media all and (max-width: 991px){

.sp-google-map h5{
        font-size: 16px;
    }

.sp-google-map .view-sp-list-btn {
        position: absolute;
        bottom: 85%;
        right: 15px;
        height: 45px;
        background: #fcbb52;
        border-radius: 6px;
        border-color: #fcbb52;
        font-size: 14px;
        border: 1px solid #fcbb52;
        font-family: 'Inter', sans-serif;
        display: initial;
    }
    
.sp-google-map .proceed-div{
        left: 20%;
    }
    
.sp-google-map .specialist-list {
      height: 100%;
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      overflow-x: hidden;
      background: #FFFFFF;
      padding-top: 6rem;
    }
    
.sp-google-map .collapse-list{
          display: none;
    }
    
.sp-google-map .specialist-list .sp-list .sp-list-header
{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
}
     
.sp-google-map .specialist-list .sp-list .sp-list-header .sp-list-close{
        cursor: pointer;
        }

/* .sp-google-map .change-location {
            right: 10px;
            bottom: 75%;
          } */




.sp-google-map .sp-list{
            padding-right: 0px;
        }
}

@media all and (max-width:768px) {
.sp-google-map h5{
        font-size: 14px;
    }
    
}

/* --------------------------------------*/
/* Mini tab And All Tab View Media Query (767px)  */
/* --------------------------------------*/
@media all and (max-width:767px){
.sp-google-map .view-sp-list-btn {
        position: absolute;
        bottom: 78%;
        right: 15px;
        height: 45px;
        background: #fcbb52;
        border-radius: 6px;
        border-color: #fcbb52;
        font-size: 14px;
        border: 1px solid #fcbb52;
        font-family: 'Inter', sans-serif;
        display: initial;
    }
    
.sp-google-map .proceed-div{
        left: 20%;
    }
    
.sp-google-map .specialist-list {
      height: 100%;
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      overflow-x: hidden;
      background: #FFFFFF;
      padding-top: 6rem;
    }
    
.sp-google-map .collapse-list{
          display: none;
    }
    
.sp-google-map .specialist-list .sp-list .sp-list-header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
    }
     
.sp-google-map .specialist-list .sp-list .sp-list-header .sp-list-close{
        cursor: pointer;
        }

/* .sp-google-map .change-location {
            right: 10px;
            bottom: 75%;
          } */
      
.sp-google-map .sp-list{
    padding-right: 0px;
}

.sp-google-map h5{
    font-size: 16px;
}

}

/* --------------------------------------*/
/* Mini tab And All Mobile View Media Query (575px)  */
/* --------------------------------------*/
@media all and (max-width:575px){
.sp-google-map .view-sp-list-btn {
        position: absolute;
        bottom: 78%;
        right: 15px;
        height: 45px;
        background: #fcbb52;
        border-radius: 6px;
        border-color: #fcbb52;
        font-size: 14px;
        border: 1px solid #fcbb52;
        font-family: 'Inter', sans-serif;
        display: initial;
    }
    
    .container.sp-google-map{
        margin-bottom: 1rem;
    }
    
.sp-google-map .proceed-div{
        left: 15%;
    }
    
.sp-google-map .specialist-list {
      height: 100%;
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      overflow-x: hidden;
      background: #FFFFFF;
      padding-top: 6rem;
    }
    
.sp-google-map .collapse-list{
          display: none;
    }
    
.sp-google-map .specialist-list .sp-list .sp-list-header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
    }
     
.sp-google-map .specialist-list .sp-list .sp-list-header .sp-list-close{
        cursor: pointer;
        }
/* .sp-google-map .change-location {
            right: 10px;
            bottom: 71%;
          } */
      

.sp-google-map .sp-list{
            padding-right: 0px;
        }

        .sp-google-map .main-map{
            margin-top: 2rem;
        }

}

/* --------------------------------------*/
/* Mini Mobile And All Mobile View Media Query (480px)  */
/* --------------------------------------*/
@media all and (max-width: 480px) {
    
.sp-google-map .view-sp-list-btn {
    position: absolute;
    bottom: 75%;
    right: 15px;
    height: 45px;
    background: #fcbb52;
    border-radius: 6px;
    border-color: #fcbb52;
    font-size: 14px;
    border: 1px solid #fcbb52;
    font-family: 'Inter', sans-serif;
    display: initial;
}

.container.sp-google-map{
    padding-bottom: 1rem;
}

.sp-google-map .main-map{
    margin-top: 2rem;
}

.sp-google-map .proceed-div{
    left: 9%;
}

.sp-google-map .specialist-list {
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  background: #FFFFFF;
  padding-top: 6rem;
}

.sp-google-map .collapse-list{
      display: none;
}

.sp-google-map .specialist-list .sp-list .sp-list-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
}
 
.sp-google-map .specialist-list .sp-list .sp-list-header .sp-list-close{
    cursor: pointer;
    }

/* .sp-google-map .change-location {
      right: 10px;
      bottom: 71%;
    } */

.sp-google-map .sp-list{
        padding-right: 0px;
    }
   
    .sp-google-map .no-specialist-found h5{
        font-size: 12px;
    }

}

/* --------------------------------------*/
/* Mini Mobile View Media Query (320px)  */
/* --------------------------------------*/
@media all and (max-width: 320px) {

    .sp-google-map .sp-list{
        padding-right: 0px;
    }

    .container.sp-google-map{
        padding-bottom: 1rem;
    }

    .sp-google-map .main-map{
        margin-top: 2rem;
    }

.sp-google-map .view-sp-list-btn {
        height: 35px;
        font-size: 12px;
        line-height: 12px;
        bottom: 80%;
}

.proceed-div .continue-btn{
height: 35px;
font-size: 12px;
line-height: 12px;
font-family: "Inter", sans-serif;
    }

.sp-google-map .proceed-div{
        right: 11%;
    }

.sp-google-map .no-specialist-found h5{
        font-size: 11px;
    }
}




