.privacy{
    padding-top: 8rem;
    font-family: 'Inter', sans-serif!important;
    text-align: justify;

}
.privacy .container{
    padding: 0px 36px;
    margin-bottom: 4rem;
}
.privacy .title{
    color: #152232;
    font-size: 27px;
    padding-bottom: 20px;
    font-weight: 800;
}
.privacy p{
    font-size: 15px;
    color: #58657E;
    font-family: 'Inter', sans-serif!important;
}
.privacy ul li{
    color: #58657E;
    font-size: 15px;
    font-family: 'Inter', sans-serif!important;
}
.privacy ul li::marker {
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 2px !important;
    text-align: start !important;
    text-align-last: start !important;
    color: #fcbb52;
    font-size: 20px;
}
.privacy h6{
    font-size: 30px;
}
.privacy label{
    color: #fcbb52;
    text-decoration: underline;
    margin: 0px 2px;
}
.privacy span{
    color: #58657E;
    font-size: 15px;
}
/* ------------------------------------- */
/* Mini Laptop and All Tab View Media Query (1024px) */
/* --------------------------------------*/
@media all and (max-width:  1024px){
    .privacy .container{
        max-width: 95%;
    }
}
/* ------------------------------------- */
/* Mini tab and All Tab View Media Query (767px) */
/* --------------------------------------*/
@media all and (max-width: 767px){
    .privacy .container{
        max-width: 94%;
    }
}
/* ----------------------------------------------- */
/* Mini Mobile and Mobile View Media Query (480px) */
/* ------------------------------------------------*/
@media all and (max-width: 480px){
    .privacy .container{
        max-width: 100%;
        padding: 0px 15px;
    }
    .privacy p,
    .privacy ul li{
        font-size: 14px;
    }
    .privacy .title{
        font-size: 24px;
    }
}
/* ------------------------------------- */
/* Mini Mobile View Media Query (320px)  */
/* --------------------------------------*/
@media all and (max-width: 320px){
    .privacy .title{
        font-size: 20px;
    }
    .privacy h6{
        font-size: 16px;
    }
    .privacy p, .privacy ul li{
        font-size: 12px;
    }
}