.change-password {
  padding-top: 8rem;
  padding-bottom: 5rem;
}

.change-password .container {
  max-width: 1230px;
}

.change-password h3 {
  font-size: 34px;
  color: #152232;
  margin-bottom: 20px;
  font-weight: 500;
}
.change-password .form-group .form-label {
  font-size: 16px;
  color: #152232;
  font-weight: 500;
  padding-bottom: 2px;
  font-family: "Inter", sans-serif !important;
}

.change-password .form-group .input-group {
  height: 52px;
  border-radius: 7px;
  color: rgb(123, 129, 141);
  font-weight: 400;
  border: 1px solid #dbdcde;
  padding: 0px 5px;
  margin-bottom: 10px;
}

.change-password .form-group .input-group .input-group-text {
  background: transparent;
  border: 0px;
  border-radius: 100px;
  padding: 0px 6px;
  height: 100%;
  width: 40px;
}

.change-password .form-group .input-group .form-control {
  border: 1px solid transparent;
  padding: 0px;
  font-family: "Inter", sans-serif !important;
  color: #7b818d;
  font-weight: 500;
  font-size: 14px;
}

.change-password .form-group .input-group .form-control:focus {
  border-color: transparent;
  box-shadow: rgb(49 130 206) 0px 0px 0px 0px;
}

.change-password .pass-footer {
  position: relative;
  text-align: center;
}

.change-password .save-pass-btn {
  display: inline-flex;
  flex-direction: column;
}

.change-password .save-pass-btn .save-password {
  font-family: "Inter", sans-serif !important;
  height: 43px;
  background: #fcbb52;
  border-radius: 7px;
  padding: 0px 120px;
  border: 2px solid #fcbb52;
  margin-top: 20px;
  font-size: 13px;
  font-weight: 500;
  color: #152232;
  margin-bottom: 10px;
}
.change-password .save-password,
.change-password .save-password:focus-visible,
.change-password .save-password:focus,
.change-password .save-password:active {
  box-shadow: rgb(26 115 232 / 15%) 0rem 0.1875rem 0.1875rem 0rem,
    rgb(26 115 232 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem,
    rgb(26 115 232 / 15%) 0rem 0.0625rem 0.3125rem 0rem !important;
  transition: all 150ms ease-in 0s;
  border: 1px solid transparent;
}
.change-password .save-password:focus-visible {
  border-color: transparent !important;
}
.change-password .save-password:hover {
  transform: scale(1.02);
}
.change-password .save-pass-btn .save-password:focus {
  border-color: transparent;
  box-shadow: rgb(49 130 206) 0px 0px 0px 0px;
}

.change-password .pass-footer .back-profile {
  display: flex;
  justify-content: center;
  margin: 10px 0px 0px 0px;
}
.change-password .pass-footer .back-profile img {
  height: 14px;
  width: 14px;
  margin: 0px 8px;
  cursor: pointer;
}

.change-password .pass-footer .back-profile h4 {
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter", sans-serif !important;
  cursor: pointer;
}

.change-password .text-danger {
  font-size: 13px;
  margin-bottom: 10px;
}

/* ------------------------------------- */
/* Mini Laptop and All Tab View Media Query (1024px) */
/* --------------------------------------*/
@media all and (max-width: 1024px) {
  .change-password .form-group .form-label {
    font-size: 14px;
  }
  .change-password .container {
    padding: 10px 4rem;
  }
  .change-password .container .row .col-11 {
    width: 100%;
  }
  .change-password .form-group .input-group {
    height: 43px;
  }
  .change-password .form-group .input-group .input-group-text {
    width: 38px;
  }
  .change-password .save-pass-btn .save-password {
    margin-bottom: 20px;
  }
  .change-password .save-pass-btn .save-password {
    height: 40px;
    line-height: 2;
  }
}
/* ------------------------------------- */
/* Mini tab and All Tab View Media Query (767px) */
/* --------------------------------------*/
@media all and (max-width: 767px) {
  .change-password .container {
    padding: 10px 3rem;
  }
  .change-password .container .row .col-11 {
    width: 100%;
  }
  .change-password .form-group .input-group {
    height: 43px;
  }
  .change-password .form-group .input-group .input-group-text {
    width: 38px;
  }
  .change-password .save-pass-btn .save-password {
    margin-bottom: 20px;
  }
  .change-password .save-pass-btn .save-password {
    height: 40px;
    line-height: 2;
  }
  .change-password .save-pass-btn {
    width: 100%;
  }
  .change-password .save-pass-btn .save-password {
    padding: 0px 0px;
  }
}

/* ------------------------------------- */
/* Mini Tab and All Mobile View Media Query (575px) */
/* --------------------------------------*/
@media all and (max-width: 575px) {
}

/* ------------------------------------- */
/* Mini tab and All Tab View Media Query (480px) */
/* --------------------------------------*/
@media all and (max-width: 480px) {
  .change-password .container {
    padding: 10px 1.2rem;
  }
  .change-password .container .row .col-11 {
    width: 100%;
  }
  .change-password .form-group .input-group {
    height: 43px;
  }
  .change-password .form-group .input-group .input-group-text {
    width: 38px;
  }
  .change-password .save-pass-btn .save-password {
    margin-bottom: 20px;
  }
  .change-password .save-pass-btn .save-password {
    height: 40px;
    line-height: 2;
  }
  .change-password .save-pass-btn {
    width: 100%;
  }
  .change-password .save-pass-btn .save-password {
    padding: 0px 0px;
  }
  .change-password h3 {
    font-size: 26px;
    font-weight: 500;
  }
}
/* ------------------------------------- */
/* Mini Mobile View Media Query (320px) */
/* --------------------------------------*/
@media all and (max-width: 320px) {
  .change-password h3 {
    font-size: 20px;
    font-weight: 500;
  }
  .change-password .form-group .input-group .input-group-text {
    height: 40px;
    width: 33px;
  }
  .change-password .form-group .form-label,
  .change-password .form-group .input-group .form-control,
  .change-password .pass-footer .back-profile h4 {
    font-size: 12px;
  }
  .change-password .form-group .form-label {
    margin-bottom: 2px !important;
  }
}
