.request-reject-modal .modal-content{
    border-radius: 20px;
}
.request-reject-modal{
    font-family: "Inter", sans-serif!important;
}
.request-reject-modal .modal-content .text-danger{
    font-size: 12px;
}
.request-reject-modal .reject-form .float-right{
    text-align: right;
}


.reject-form .center{
   text-align: center;
}
.reject-form .center .fa.fa-exclamation-triangle{
    font-size: 60px;
    color: tomato;
}

.reject-form .form-label{
    text-align: center;
}
.request-reject-modal .modal-content .text-danger{
    text-align: left;
}
/*--------------------------------------------*/
/* Mini mobile with All Mobile Devices (480px) View*/
/*--------------------------------------------*/
@media all and (max-width:480px){
    .request-reject-modal .reject-form .float-right{
        display: flex;
    }
    .request-reject-modal .reject-form .float-right button{
        min-width: 49%;
    }
    .request-reject-modal .reject-form .form-label{
        padding-bottom: 0px!important;
    }
}