@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

::-webkit-scrollbar {
  display: none;
}
body {
  scrollbar-width: none!important;
}

@-moz-document url-prefix() { /* Disable scrollbar Firefox */
  html{
    scrollbar-width: none;
  }
}
body {
  margin: 0; /* remove default margin */
  scrollbar-width: none; /* Also needed to disable scrollbar Firefox */
  -ms-overflow-style: none;  /* Disable scrollbar IE 10+ */
  overflow-y: scroll;
}
body::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Disable scrollbar Chrome/Safari/Webkit */
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
button,
label,
p {
  font-family: "Inter", sans-serif;
}
button {
  border: 2px solid transparent;
  font-family: "Inter", sans-serif;
  cursor: pointer;
  padding: 10px 20px;
}
.loader-effect{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
}
.loader-wrapper {
  width: 60px;
  height: 60px;
}
.loaders{
  box-sizing:border-box;
  width: 100%;
  height: 100%;
  border: 8px solid white;
  border-top-color: #4bc8eb;
  border-bottom-color: #e83855;
  border-radius: 50%;
  animation: rotate 5s linear infinite;
}
.loader-inner{
  border-top-color: #36f372;
  border-bottom-color: #162534;
  animation-duration: 2.5s;
}

.backend-loader .admin.content-page{
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes rotate{
  0%{
    transform: scale(1) rotate(360deg);
  }
  50%{
    transform: scale(.8) rotate(-360deg);
  }
  100%{
    transform: scale(1) rotate(360deg);
  }
}