.register p,
.register label,
.register button,
.register h1,
.register h5,
.register h6,
.register h3,
.register h4 {
  font-family: "Inter", sans-serif;
}
.register .register-heading {
  padding: 0px 0rem;
}
.register .register-title {
  font-size: 36px;
  color: #152232;
  font-weight: bolder;
}
.register .register-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 360px;
}
.register .mt-4 {
  margin-top: 4rem;
}
.register .register-title.m-4 {
  margin: 2rem 0px 4px 0px !important;
}
.register .register-box .secoundry {
  font-size: 15px;
  color: #7e828b;
}
.register .register-box .register-form {
  padding-bottom: 15px;
  width: 100%;
}
.register .register-box .register-form .form-title {
  font-size: 15px;
  color: #152232;
  font-weight: 500;
  padding-bottom: 5px;
  font-family: "Inter", sans-serif !important;
}
.register .register-box .register-form .input-field:focus {
  z-index: 1;
  border-color: transparent;
  box-shadow: rgb(49 130 206) 0px 0px 0px 0px;
}
.register .register-box .register-form .input-field {
  font-family: "Inter", sans-serif !important;
  outline: transparent solid 2px;
  height: 100%;
}
.register .register-box .register-form .input-group {
  border: 1px solid #dbdcde;
  padding: 0px 5px;
}
.register .register-box .register-form .input-group:hover {
  background-color: #fff;
}
.register .register-box .register-form .input-group-text {
  background: transparent;
  border: 0px;
  border-radius: 100px;
  padding: 0px 6px;
  height: 100%;
  width: 38px;
}
.register .register-box .register-form input.input-field {
  padding: 0px;
  border: 1px solid transparent;
  border-radius: 9px;
  font-size: 14px;
}
.register .register-box .register-form .input-group:focus-within {
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
.register .register-box .register-form .text-danger{
  font-size: 13px;
}
.register .register-box .form-group {
  padding: 18px 0px 0px 0px;
}
.register .text-upper {
  text-transform: capitalize;
}
.register .user-select-none {
  -webkit-user-drag: none;
  -webkit-user-select: none;
}
.register .alter-login {
  color: #7f838c;
  margin-top: 10px;
  cursor: pointer;
}
.register .alter-login strong {
  color: #152232;
  font-weight: 500;
  font-size: 14px;
}
.register .all-right-reserve {
  margin-top: 13px;
  color: #7b818d;
  font-size: 14px;
}
.register center {
  margin-top: 10px;
  width: 100%;
}
.register .px-10 {
  display: flex;
  justify-content: center;
  padding: 7rem 0px 0px 0px !important;
}
.register .shadow-btn,
.register .shadow-btn:focus-visible,
.register .shadow-btn:active,
.register .shadow-btn:focus {
  box-shadow: rgb(26 115 232 / 15%) 0rem 0.1875rem 0.1875rem 0rem,
    rgb(26 115 232 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem,
    rgb(26 115 232 / 15%) 0rem 0.0625rem 0.3125rem 0rem!important;
  transition: all 150ms ease-in 0s;
  border: 1px solid transparent;
}
.register .shadow-btn{
  border: 1px solid transparent!important;

}
.register .shadow-btn:focus-visible {
  border-color: transparent !important;
}
.register .register-btn:hover {
  border: 1px solid transparent !important;
  box-shadow: rgb(0 0 0 / 9%) 0rem 0.1875rem 0.3125rem -0.0625rem,
    rgb(0 0 0 / 7%) 0rem 0.125rem 0.3125rem;
  transform: scale(1.02);
}
.register .register-btn:focus {
  box-shadow: rgb(0 0 0 / 9%) 0rem 0.1875rem 0.3125rem -0.0625rem,
    rgb(0 0 0 / 7%) 0rem 0.125rem 0.3125rem;
}

/* Selection of buyer and seller */

.selection {
  display: inline-flex;
  background: #fff;
  height: 45px;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
}
.selection .option {
  background: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 8px;
  cursor: pointer;
  padding: 0 10px;
  border: 2px solid lightgrey;
  transition: all 0.3s ease;
}

.selection .buyer {
  margin: 0px 12px;
}

.selection .option .dot {
  height: 19px;
  width: 19px;
  background: #ffffff;
  border-radius: 50%;
  position: relative;
  border: 2px solid #152232;
  border-color: #152232;
}
.selection .option .dot::before {
  position: absolute;
  content: "";
  top: 3.324px;
  left: 3.38px;
  width: 8px;
  height: 8px;
  background: #fcbb52;
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}
input[type="radio"] {
  display: none;
}
#seller-opt:checked:checked ~ .seller-opt,
#buyer-opt:checked:checked ~ .buyer-opt {
  border-color: #fcbb52;
  background: #ffeccc;
}
#seller-opt:checked:checked ~ .seller-opt .dot,
#buyer-opt:checked:checked ~ .buyer-opt .dot {
  background: #ffeccc;
  border: 2px solid #fcbb52;
}
#seller-opt:checked:checked ~ .seller-opt .dot::before,
#buyer-opt:checked:checked ~ .buyer-opt .dot::before {
  opacity: 1;
  transform: scale(1);
}
.selection .option span {
  font-size: 17px;
  color: #152232;
  font-weight: 500;
  font-family: "Inter", sans-serif !important;
  margin: 0px 15px;
}
#seller-opt:checked:checked ~ .seller-opt span,
#buyer-opt:checked:checked ~ .buyer-opt span {
  color: #152232;
}

/*--------------------------------------------*/
/* Mini Laptop with Tab (991px) View*/
/*--------------------------------------------*/
@media all and (max-width: 1024px) {
  .register .register-title {
    font-size: 30px;
  }
  .register .register-box .secoundry {
    font-size: 14px;
  }
  .register .register-box{
    width: 76%!important;
  }
  .register .alter-login {
    margin-top: 0px;
  }
  .register .register-box .form-group{
    padding: 10px 0px 0px 0px;
  }
  .register .register-box .register-form .form-title{
    padding-bottom: 3px;
  }
  .register .register-box .register-form .text-danger{
    font-size: 14px;
  }
}

/*--------------------------------------------*/
/* Mini Tab with All Mobile Devices (767px) View*/
/*--------------------------------------------*/

@media all and (max-width: 767px) {
  .register.col-12 .col-6.px-10.py-5{
    width: 100%;
  }
  .register .user-select-none,
  .register.col.p-0{
    display: none;
  }
  .register .register-title {
    font-size: 30px;
  }
  .register .register-box .register-form .text-danger{
    font-size: 14px;
  }
}

/*--------------------------------------------*/
/* Mini Tab with All Mobile Devices (667px) View*/
/*--------------------------------------------*/

@media all and (max-width: 667px) {
  .register .user-select-none {
    display: none;
  }
  .register .register-title {
    font-size: 28px;
  }
  .register .px-10 {
    width: 100%;
  }
}

/*--------------------------------------------*/
/* Mini Tab with All Mobile Devices (480px) View*/
/*--------------------------------------------*/

@media all and (max-width: 480px) {
  .register{
    padding: 6rem 0px 0px 0px;
  }
  .register .px-10{
    padding: 0px!important;
  }
  .register .register-box{
    width: 85%;
  }
  .register .register-box .register-form .form-title{
    font-size: 14px;
  }
  .register .alter-login{
  font-size: 14px;
  }
  .register .register-box .register-form .text-danger{
    font-size: 12px;
  }
  .register .register-box{
    width: 85%!important;
  }
  .register .register-title {
    font-size: 26px;
  }
  .register .register-title.m-4{
    margin-top:1rem!important;
  }
  .selection .option span{
    font-size: 13px;
    margin: 5px;
  }
}

@media all and (max-width: 320px){
  .register .register-title.m-4{
    font-size: 20px;
    font-weight: 500;
  }
  .register .register-box .secoundry{
    font-size: 12px;
  }
  .register .register-box{
    width: 90%!important;
  }
  .register .register-box .register-form .form-title,
  .register .alter-login{
    font-size: 12px;
  }
  .register .register-box .register-form input.input-field{
    font-size: 12px;
  }
  .register .register-box .register-form .input-group-text{
    height: 43px;
    width: 34px;
  }
  .register .all-right-reserve{
    font-size: 12px;
  }
  .register .register-box .register-form .text-danger{
    font-size: 11px;
  }
}