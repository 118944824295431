.contactus {
  padding-top: 8rem;
  padding-bottom: 4rem;
}

.contactus .container {
  /* max-width: 1230px; */
  padding: 0px 20px;
}

.contactus .form-group .form-control:focus {
  background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.contactus .help h4 {
  font-size: 15px;
  color: #fcbb52;
  font-weight: 500;
  font-family: "Inter", sans-serif !important;
}

.contactus .help .help-text {
  font-size: 27px;
  font-weight: 600;
  color: #152232;
  font-family: "Inter", sans-serif !important;
}

.contactus .help p {
  font-size: 15px;
  color: #58657e;
  font-weight: 500;
  font-family: "Inter", sans-serif !important;
}

.contactus .email {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: flex-start;
}

.contactus .email .email-info {
  margin-left: 20px;
}

.contactus .email .email-info h4 {
  font-size: 18px;
  color: #152232;
  font-weight: 500;
  font-family: "Inter", sans-serif !important;
}

.contactus .email .email-info p {
  font-size: 15px;
  color: #58657e;
  font-weight: 500;
  font-family: "Inter", sans-serif !important;
}

.contactus .form-group {
  margin-bottom: 10px;
}

.contactus .form-group .form-label {
  font-size: 14px;
  color: #152232;
  font-weight: 500;
  padding-bottom: 2px;
  font-family: "Inter", sans-serif !important;
}

.contactus .form-group .form-control {
  border: 1px solid #dbdcde;
  font-family: "Inter", sans-serif !important;
  color: #7b818d;
  font-weight: 400;
  font-size: 14px;
  height: 52px;
  border-radius: 7px;
}
.contactus .submit:hover{
  background-color: transparent;
  border:2px solid #fcbb52; 
  color: #fcbb52;
}
.contactus .submit:focus,
.contactus .submit:active{
    color: #fcbb52;
    background-color: transparent;
    border-color: #fcbb52;
    box-shadow: 0 0 0 0 black !important;
}
.contactus .submit {
  height: 43px;
  background: #fcbb52;
  border-radius: 7px;
  border:2px solid transparent;
  color: #152232;
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter", sans-serif !important;
}


/* ------------------------------------------------*/
/* Mini Laptop and All Tab devices Media Query (1024px) */
/* ------------------------------------------------*/
@media all and (max-width:  1024px){
  .contactus .col-md-12{
    width: 50%;
  }
}

/* ------------------------------------------------*/
/* Mini Tab and All Tab devices Media Query (767px) */
/* ------------------------------------------------*/
@media all and (max-width: 767px){
  .contactus .container{
    max-width: 95%;
  }
  .contactus .col-md-12{
    width: 100%;
  }
}

/* ------------------------------------------------*/
/* Mini Mobile and All Mobile devices Media Query (480px) */
/* ------------------------------------------------*/
@media all and (max-width: 480px){
  .contactus .help .help-text{
    font-size: 24px!important;
  }
  .contactus .help p{
    font-size: 13px!important;
  }
  .contactus .email .email-info h4{
    font-size: 15px;
  }
  .contactus .email .email-info p{
    font-size: 11px;
  }
  .contactus .form-group .form-label{
    font-size: 14px;
  }
  .contactus .form-group .form-control{
    height: 43px;
    font-size: 14px;
  }
  .contactus .container{
    max-width: 100%;
  }
  .contactus .email img{
    width: 20px;
    height: 20px;
  }
  .contactus .email .email-info{
    margin-left: 15px;
  }
}

/* ------------------------------------------------*/
/* Mini Mobile devices Media Query (320px) */
/* ------------------------------------------------*/
@media all and (max-width: 320px){
  .contactus .help .help-text{
    font-size: 20px!important;
  }
  .contactus .help h4{
    font-size: 13px;
  }
  .contactus .help p{
    font-size: 11px!important;
  }
  .contactus .container{
    padding: 0px 10px;
  }
}