
.admin.footer {
    bottom: 0;
    padding: 19px 15px 20px;
    position: absolute;
    right: 0;
    color: var(--ct-footer-color);
    left: 240px;
    background-color: var(--ct-footer-bg)
}

.admin.footer .admin.footer-links a {
    color: var(--ct-footer-color);
    margin-left: 1.5rem;
    transition: all .4s
}

.admin.footer .admin.footer-links a:hover {
    color: var(--ct-footer-link-hover-color)
}

.admin.footer .admin.footer-links a:first-of-type {
    margin-left: 0
}

.admin.footer-alt {
    left: 0 !important;
    text-align: center;
    background-color: transparent
}

@media (max-width:767.98px) {
    .admin.footer {
        left: 0 !important;
        text-align: center
    }
}

body[data-layout-mode=horizontal] .admin.footer {
    left: 0 !important
}

body[data-layout-mode=horizontal][data-layout-size=boxed] .admin.footer {
    max-width: 1300px !important
}

@media (min-width:992px) {
    body[data-layout-mode=detached] .admin.footer {
        position: inherit;
        margin: 0 10px
    }
}
