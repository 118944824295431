.addfaq-contents{
    display: block;
}
.addfaq-contents .about-tab.nav-pills .nav-link{
    border-radius: 0px;
    color: #a1a1a1;
}
.addfaq-contents .about-tab.nav-pills .nav-link.active{
    background-color: transparent!important;
    color: #f9c851;
    border-bottom: 2px solid #f9c851;
}

.addfaq-contents .action-bottom-right{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.addfaq-contents .faq-list-outer .collapsible-bottom{
    display: none;
}
.addfaq-contents .btn-action-delete{
    display: flex;
    justify-content: center;
    align-items: center;
    width:6rem;
    height: 2.5rem;
    margin-right: 20px;
    opacity: 1;
    background:linear-gradient(195deg, rgb(171 171 171), rgb(171 171 171));
    color: rgb(255, 255, 255);
    border-radius: 0.55rem;
    box-shadow: rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(64 64 64 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem;
}
.addfaq-contents .btn-action-edit{
    display: flex;
    justify-content: center;
    align-items: center;
    width:2.5rem;
    margin-right: 20px;
    height: 2.5rem;
    opacity: 1;
    background: linear-gradient(195deg, rgb(255 185 16), rgb(252 187 82));
    color: rgb(255, 255, 255);
    border-radius: 0.55rem;
    box-shadow: rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(64 64 64 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem;
}
.addfaq-contents .btn-action-plus{
    display: flex;
    justify-content: center;
    align-items: center;
    width:2.5rem;
    margin-top: -40px;
    height: 2.5rem;
    opacity: 1;
    background: linear-gradient(195deg, rgb(255 185 16), rgb(252 187 82));
    color: rgb(255, 255, 255);
    border-radius: 0.55rem;
    box-shadow: rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(64 64 64 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem;
}
.addfaq-contents .faq-list-inner{
    margin-bottom: 15px;
    border: 1px solid #efefef;
    border-radius: 6px 6px 6px 6px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.addfaq-contents .faq-list-inner .collapsible label{
    cursor: pointer;
}
.addfaq-contents .faq-list-inner .collapsible i.fa-plus{
    cursor: pointer;
    
}
.addfaq-contents .faq-list-inner .collapsible{
    background: #EFEFEF;
    padding: 16px 23px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    border-radius: 6px 6px 6px 6px;
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.addfaq-contents .faq-list-inner.active .collapsible-bottom{
    display: block!important;
    padding: 15px 20px 20px 20px;
}
.addfaq-contents .align-right{
    display: flex;
    justify-content: flex-end;
}