.feedback-content .rating .span{
    display: flex;
    align-items: center;
    font-size: 22px;
    justify-content: center;
    height: 30px;
    width: 66px;
    background: #ffe9d5;
    border-radius: 4px;
    color: #f19f50;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.feedback-content .rating .span label{
    font-size: 15px;
    margin-left: 5px;
    font-weight: 600;
}

.feedback-content .rating .span label span{
    font-size: 13px;
}