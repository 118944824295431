/*--------------------------------------------*/
/*Header Section*/
/*--------------------------------------------*/

#header {
  display: block;
  position: fixed;
  z-index: 999;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  padding: 0 50px;
  top: 20px;
  left: 0;
  right: 0;
}
#header.sticky {
  top: 0;
  width: 100%;
  z-index: 999;
  background-color: #000000;
}
#header.sticky .navbar {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0;
}
.sticky + #header {
  padding-top: 40px;
}
#header .logo {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  line-height: 23px;
  text-decoration: none;
}
#header .logo span {
  color: #fcbb52;
}
.mobile-logo {
  display: none;
}

#header header nav ul li {
  float: left;
  line-height: 40px;
  position: relative;
  overflow: hidden;
}
#header header nav ul li a:hover {
  color: #fff;
}
#header .navbar-nav {
  display: inline-block;
}
#header .navbar-nav li a {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 0 20px;
}
#header .navbar-nav .btn-login a {
  height: 40px;
  line-height: 35px;
  background: #fcbb52;
  color: #152232;
  font-size: 14px;
  font-weight: 500;
  padding: 0 20px;
  border-radius: 6px;
  display: block;
  border: 2px solid #fcbb52;
}
#header .navbar-nav .btn-login a:hover {
  background-color: transparent;
  color: #fff;
}
#header .navbar-nav .btn-login:hover::after {
  display: none;
}
#header .navbar .navbar-nav .signup a {
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
}
#header .navbar .navbar-nav .signup:hover::after {
  display: none;
}

#header .navbar-nav li::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 50px;
  background-color: #fcbb52;
  bottom: 5px;
  transform: translateX(-100%);
  transition: transform 0.5s ease-in-out;
}
#header .navbar-nav li:hover::after,
#header .navbar-nav li:focus::after {
  transform: translateX(0);
}

/*--------------------------------------------*/
/*Slider Section*/
/*--------------------------------------------*/
#slider {
  background-image: url(../../../assets/home_page.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  min-height: 712px;
  height: 100vh;
  position: relative;
}
#slider .slider_content {
  position: absolute;
  z-index: 99;
  display: inline-block;
  top: 50%;
  left: 3%;
  transform: translate(0%, -50%);
}
#slider .slider_content h3 {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 20px;
  white-space: nowrap;
  text-transform: uppercase;
}
#slider .slider_content h3 p{
  margin-bottom: 1rem;
}
#slider .slider_content h3 span p {
  font-weight: 300;
  margin-bottom: 0.4rem;
  font-size: 23px;
}
#slider .slider_content .btn-more {
  height: 55px;
  line-height: 50px;
  text-transform: uppercase;
  background: #fcbb52;
  font-size: 14px;
  font-weight: 500;
  color: #152232;
  border-radius: 6px;
  border: 2px solid #fcbb52;
  padding: 0 25px;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
#slider .slider_content .btn-more:hover {
  background-color: transparent;
  color: #fff;
}

/*--------------------------------------------*/
/*Packages Section*/
/*--------------------------------------------*/

.package-login {
  padding-top: 7rem;
}

#packages {
  padding: 30px 50px;
}

#packages h2 {
  text-align: center;
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #152232;
}
#packages .package {
  background: #ffebc6;
  text-align: center;
  margin-bottom: 30px;
  position: relative;
  cursor: pointer;
  border-radius: 30px;
  transition: all 0.5s ease 0s;
}

#packages .package h4 {
  color: #212226;
  font-size: 42px;
  font-weight: 600;
  line-height: 32px;
}
#packages .package hr {
  color: #f0b13b;
}
#packages .package h6 {
  color: #a89c87;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 17px;
  line-height: 20px;
}
#packages .package .process-details {
  font-size: 19px;
  color: #212226;
  font-weight: 500;
}
#packages .package .btn-package {
  background-color: #fff;
  color: #212226;
  font-size: 14px;
  font-weight: 500;
  height: 55px;
  line-height: 30px;
  text-transform: uppercase;
  display: block;
  position: absolute;
  bottom: 20px;
  text-decoration: none;
  border-radius: 15px;
  width: 90%;
  white-space: nowrap;
}
#packages .package .flex-center{
  display: flex;
  align-items: center;
  justify-content: center;
}
#packages .disabled.package{
  opacity: 0.7;
}
#packages .enabled.package:hover {
  transform: scaleY(1.05);
  background-color: #0a1122;
  box-shadow: 0 13px 27px -5px rgb(50 50 93 / 25%),
    0 8px 16px -8px rgb(0 0 0 / 30%), 0 -6px 16px -6px rgb(0 0 0 / 3%);
}
#packages .enabled.package:hover .package.rdio label::before {
  background-color: #0a1122;
}
#packages .enabled.package:hover h4,
#packages .enabled.package:hover h6,
#packages .enabled.package:hover .process-details,
#packages .enabled.package:hover h5 {
  color: white !important;
}

/* #packages .enabled.package:hover label {
  padding: 20px 20px !important;
} */

#packages .package.rdio {
  padding: 0px;
  height: 100%;
}
#packages .package.rdio label {
  position: relative;
  padding: 20px 20px 72px 20px;
  display: block;
  cursor: pointer;
  border: 2px solid transparent;
  font-family: "Inter", sans-serif;
  border-radius: 30px;
  border: 2px solid transparent;
  height: 100%;
}
#packages .package.rdio label h5 {
  color: #a29683;
  font-size: 16px;
}
#packages .package.rdio label::before {
  top: 20px;
  right: 20px;
  left: auto;
}
#packages .package.rdio label::before {
  width: 27px;
  height: 27px;
  position: absolute;
  top: 34px;
  right: 21px;
  content: "";
  display: inline-block;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  border: 3px solid #a29683;
}
#packages .package.rdio label::before + label::after {
  border: 3px solid #ffb339;
}
#packages .package.rdio input[type="radio"]:checked + label::after {
  top: 27px;
  right: 27px;
  left: auto;
}
#packages .package.rdio input[type="radio"]:checked + label:before {
  border: 3px solid #f0b13b;
}
#packages .package.rdio input[type="radio"]:checked + label::after {
  background-color: #f0b13b !important;
}
#packages .package.rdio input[type="radio"]:checked + label::after {
  content: "";
  position: absolute;
  top: 41px;
  right: 28px;
  display: inline-block;
  font-size: 11px;
  width: 13px;
  height: 13px;
  background-color: #fbc52d;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
}
#packages .package.rdio input[type="radio"]:checked + label {
  background-color: #0a1122;
  border: 2px solid #f0b13b;
}

#packages .package.rdio input[type="radio"]:checked + label h4,
#packages .package.rdio input[type="radio"]:checked + label h6,
#packages .package.rdio input[type="radio"]:checked + label .process-details,
#packages .package.rdio input[type="radio"]:checked + label h5 {
  color: white;
}

#packages .package.rdio input[type="radio"]:checked + label button {
  background-color: #f0b13b !important;
}

#packages .package.rdio input[type="radio"] {
  position: absolute;
  display: none;
}
#packages .package.rdio label .input-group-text {
  background-color: transparent;
  border: 0;
  font-weight: 500;
  font-size: 15px;
  height: 20px;
  margin-top: 15px;
  padding: 0 5px 0 0;
  color: #002953;
}
#packages .package.rdio:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  cursor: pointer;
  border-radius: 30px;
}
#packages .btn-common {
  border-radius: 9px;
  background-color: #f0b13b;
  color: black;
  width: 166px;
  height: 50px;
  border: 2px solid transparent;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  margin-top: 20px;
}
#packages .btn-common:hover {
  background-color: transparent;
  color: #f0b13b;
  border: 2px solid #f0b13b;
}

#packages .packages-list-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#packages .packages-list-div .col-sm-12 {
  padding: 0px 20px;
}

/*--------------------------------------------*/
/*How it work Section*/
/*--------------------------------------------*/
#how_it_work {
  padding: 30px 50px;
}
#how_it_work h2 {
  text-align: center;
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #152232;
  line-height: 30px;
}
#how_it_work .work-div.mobile {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
#how_it_work .steps {
  text-align: center;
}
#how_it_work .steps h5 {
  color: #212226;
  font-size: 21px;
  font-weight: 500;
}
#how_it_work .steps {
  color: #58586c;
  font-size: 14px;
  font-weight: 500;
}

/*--------------------------------------------*/
/*Why choose us Section*/
/*--------------------------------------------*/
#why_choose_us .col-sm-12 {
  width: 50%;
}
#why_choose_us {
  padding-top: 30px;
  padding-bottom: 90px;
  position: relative;
}
#why_choose_us h2 {
  font-size: 27px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #152232;
  line-height: 30px;
  margin-top: 90px;
}
#why_choose_us p.text-primary{
  color: #f0b13b!important;
  font-weight: 600;
}
#why_choose_us .sub-text {
  font-size: 15px;
  font-weight: 500;
  color: #58657e;
  line-height: 25px;
  margin-bottom: 30px;
}
#why_choose_us .left-img {
  width: 100%;
}
#why_choose_us .feature {
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;
}
#why_choose_us .feature .content-panel {
  margin-left: 10px;
}
#why_choose_us .feature .content-panel h5 {
  font-size: 15px;
  color: #152232;
  font-weight: 600;
}
#why_choose_us .feature .content-panel p {
  font-size: 10px;
  font-weight: 500;
  color: #58657e;
  line-height: 18px;
  margin-bottom: 0;
}

/*--------------------------------------------*/
/*Subscribe*/
/*--------------------------------------------*/
#subscribe {
  background: #fee2b4;
  position: relative;
  padding: 100px 0;
}
#subscribe h3 {
  font-size: 28px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
}
#subscribe p {
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  font-family: "Inter", sans-serif;
  color: #6e624f;
}
#subscribe .input-group {
  height: 45px;
}
#subscribe .input-group .form-control {
  font-size: 10px;
  border: 0;
  border-radius: 6px 0px 0px 6px;
}
#subscribe .input-group .btn-subscribe {
  background: #fcbb52;
  font-size: 10px;
  color: #000000;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0 20px;
  border-radius: 0px 6px 6px 0px;
}
#subscribe .input-group .btn-subscribe:focus {
  box-shadow: none;
}
#subscribe .right-img {
  position: absolute;
  top: 0px;
  right: 0;
  height: 100%;
}

/*--------------------------------------------*/
/*Testimonial*/
/*--------------------------------------------*/
#testimonial .swiper-container.desktop {
  display: block;
}
#testimonial .swiper-container.mobile {
  display: none;
}
#testimonial {
  padding: 40px 0;
}
#testimonial h2 {
  text-align: center;
  font-size: 27px;
  font-weight: 700;
  margin-bottom: 15px;
  font-family: "Inter", sans-serif;
  color: #152232;
  line-height: 30px;
}
#testimonial .sub-text {
  font-size: 15px;
  font-weight: 500;
  color: #58657e;
  line-height: 25px;
  font-family: "Inter", sans-serif;
  margin-bottom: 30px;
  text-align: center;
}

#testimonial .content-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: ".";
  width: 86%;
  align-items: center;
  padding: 20px;
  box-shadow: 0px 0px 20px #00000021;
}
#testimonial .content-wrapper .content {
  display: flex;
  align-items: center;
  position: relative;
}
#testimonial .content-wrapper .content :first-child {
  margin: 0;
}
#testimonial .content-wrapper .content .swiper-avatar {
  width: 100%;
  max-width: 47px;
  height: auto;
}
#testimonial .content-wrapper .content .swiper-avatar img {
  border-radius: 100%;
  box-shadow: 0px 0px 6px #00000021;
  border: 1px solid #ffffff;
  object-fit: cover;
  height: 47px;
  width: 47px;
}
#testimonial .content-wrapper .content .user_details {
  margin-left: 10px;
}
#testimonial .content-wrapper .content .user_details .name {
  font-size: 14px;
  color: #58657e;
  font-weight: 600;
}
#testimonial .content-wrapper .content .user_details .rating i {
  color: #f8af2d;
  font-size: 10px;
}
#testimonial .content-wrapper .content .user_details .rating span {
  font-size: 11px;
  color: #58657e;
  font-weight: 500;
}
#testimonial .content-wrapper p {
  font-size: 11px;
  font-weight: 500;
  color: #58657e;
  margin-top: 15px;
  line-height: 19px;
}
#testimonial .content-wrapper .content .quote {
  position: absolute;
  right: 0;
}
#testimonial .home-wrapper .swiper-button-prev,
#testimonial .home-wrapper .swiper-button-next {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background-color: #f0b13b;
}
#testimonial .home-wrapper .swiper-button-prev {
  left: 0px;
}
#testimonial .home-wrapper .swiper-button-next {
  right: 0px;
}
#testimonial .home-wrapper .swiper-button-prev:after,
#testimonial .home-wrapper .swiper-button-next:after {
  font-size: 18px;
  color: white;
  font-weight: 600;
}
#testimonial .feedback {
  margin: 16px 25px;
}
#testimonial .swiper-slide {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.fadeInDown {
  animation-duration: 0.5s;
  animation-name: fadeInDown;
}
.bounceInLeft {
  animation-duration: 0.5s;
  animation-name: bounceInLeft;
}

#testimonial .swiper-container {
  padding: 10px 0px;
}

/* ------------------------------------- */
/* Big Screen Desktop View Media Query (1999px) */
/* --------------------------------------*/
@media all and (max-width: 1999px) {
  #why_choose_us .container-fluid {
    max-width: 85%;
  }
  #slider .slider_content h3{
    font-size: 40px;
  }
  #slider .slider_content h3 span p{
    font-size: 30px;
    margin-bottom: 0.9rem;
  }
  #why_choose_us .left-img {
    width: 100%;
  }
  #testimonial .container {
    max-width: 85%;
  }
  #packages{
    padding: 30px 10px;
  }
  #packages .container-fluid{
    max-width: 85%;
  }
  #subscribe {
    padding: 120px 0px;
  }
  #subscribe .container-fluid {
    max-width: 85%;
  }
  #subscribe p {
    font-size: 15px;
    line-height: 1.4;
  }
  #subscribe .input-group .form-control,
  #subscribe .input-group .btn-subscribe {
    font-size: 17px;
  }
  #subscribe .container-fluid .col-sm-12 {
    width: 50%;
    margin-left: 0px;
  }
}
/* ------------------------------------- */
/* Mini decktop and desktop View Media Query (1600px) */
/* --------------------------------------*/
@media all and (max-width: 1600px) {
  #subscribe .col-xl-4 {
    margin-left: 30px;
  }
  #slider .slider_content h3{
    font-size: 28px;
  }
  #slider .slider_content h3 span p{
    font-size: 22px;
  }
  #why_choose_us .left-img {
    width: 100%;
  }
  #subscribe .input-group .form-control,
  #subscribe .input-group .btn-subscribe {
    font-size: 14px;
  }
  #subscribe p {
    font-size: 13px;
  }
  #packages .container-fluid,
  #why_choose_us .container-fluid,
  #subscribe .container-fluid,
  #testimonial .container{
    max-width: 90%;
  }
}
/* ------------------------------------- */
/* Mini decktop and desktop View Media Query (1499px) */
/* --------------------------------------*/
@media all and (max-width: 1499px) {
  #subscribe .right-img {
    height: 100%;
  }
  #slider .slider_content h3{
    font-size: 26px;
  }
  #slider .slider_content h3 span p{
    font-size: 20px;
  }
  #subscribe .container-fluid {
    max-width: 94%;
  }
  #subscribe p {
    font-size: 12px;
  }
  #subscribe .input-group .form-control,
  #subscribe .input-group .btn-subscribe {
    font-size: 12px;
  }
  #packages .container-fluid{
    max-width: 94%;
  }
  #why_choose_us .container-fluid,
  #testimonial .container{
    max-width: 94%;
  }
}
/* ------------------------------------- */
/* Mini decktop and desktop View Media Query (1400px) */
/* --------------------------------------*/
@media all and (max-width:1400px){
  #subscribe .right-img {
    height: 100%;
    top: 0px;
  }
}
/* ------------------------------------- */
/* Mini laptop and mini desktop View Media Query (1200px) */
/* --------------------------------------*/
@media (max-width: 1200px) {
  #subscribe .right-img {
    height: 100%;
    top: 0px;
  }
  #subscribe {
    padding: 80px 0px;
  }
  #packages{
    padding: 30px 0px;
  }
  #packages .packages-list-div{
    flex-wrap: wrap;
  }
  #packages .container-fluid,
  #why_choose_us .container-fluid,
  #testimonial .container,
  #subscribe .container-fluid{
    max-width: 100%;
  }
  #testimonial .container{
    max-width: 97%;
  }
  #packages .packages-list-div .col-sm-12{
    width: 30%;
    margin-bottom: 20px;
  }
}
/* --------------------------- */
/* mini laptop and tablet View Media Qeury (1024px) */
/* --------------------------- */
@media all and (max-width: 1024px) {
  #slider {
    min-height: 515px;
    height: 96vh;
    width: 100%;
  }
  #packages .container-fluid, #why_choose_us .container-fluid, #testimonial .container, #subscribe .container-fluid{
    max-width: 94%;
  }
  
  #slider .slider_content h3 {
    padding-top: 40px;
    font-size: 23px;
  }
  #slider .slider_content h3 span p{
    font-size: 16px;
  }
  #packages {
    padding: 20px 20px;
  }
  #testimonial .content-wrapper {
    width: 100%;
  }

  #why_choose_us {
    padding-top: 0px;
    padding-bottom: 39px;
    position: relative;
  }
  #why_choose_us .left-img {
    width: 100%;
  }

  #why_choose_us .container-fluid {
    padding: 0px 14px;
    max-width: 97%;
  }
  #why_choose_us .container-fluid .wow.bounceInRight {
    width: 50%;
  }
  #why_choose_us .col-sm-12 {
    width: 50%;
  }

  #subscribe .container-fluid .row .col-sm-12 {
    width: 50%;
    margin: 0px;
  }
  #subscribe .right-img {
    position: absolute;
    right: 0px;
    height: 100%;
    top: 0px;
  }
  #subscribe {
    padding: 50px 0px;
  }

  #how_it_work .container {
    margin: 0px;
    max-width: 100%;
  }
  #how_it_work {
    padding: 20px 0px;
  }
  #how_it_work .steps img {
    width: 100%;
    object-fit: contain;
    object-position: center;
  }

  #testimonial .container {
    max-width: 96%;
  }
  /* Package list */
  #packages .packages-list-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  #packages .packages-list-div .col-sm-12{
    width: 33.33%;
  }
  #packages .package.rdio label{
    min-height: 300px;
  }
}
/* --------------------------- */
/* Tab and Mini Tab View Media Qeury (891px) */
/* --------------------------- */
@media all and (max-width: 891px) {
  #slider {
    min-height: 550px;
    height: 63vh;
  }
  #subscribe {
    padding: 80px 0px;
  }
  #packages .container-fluid, #why_choose_us .container-fluid, #testimonial .container{
    max-width: 99%;
  }
  #subscribe .container-fluid{
    max-width: 90%;
  }
  #subscribe .container-fluid .row .col-sm-12 {
    width: 100%;
  }
  #subscribe .right-img {
    top: -80px;
    height: 160px;
    display: none;
  }
  #why_choose_us .container-fluid {
    padding: 0px 25px;
  }

  #why_choose_us .left-img {
    position: relative;
    width: 100%;
  }
  #why_choose_us .col-sm-12 {
    width: 100%;
  }

  /* Package list */

  #packages .packages-list-div .col-sm-12 {
    margin: 20px 0px 0px 0px;
    width: 50%;
  }
  #packages .package{
    margin-bottom: 15px;
  }
  
}
/* --------------------------- */
/* Tab and Mini Tab View Media Qeury (767px) */
/* --------------------------- */
@media all and (max-width: 767px){
  #how_it_work {
    padding: 10px 0px;
  }
  #testimonial .content-wrapper {
    width: 100%;
  }
  #testimonial .feedback {
    padding: 16px 35px;
  }

  #packages {
    padding: 30px 0px;
  }
  #packages h2 {
    font-size: 25px;
  }
  #packages .container-fluid .row .col-sm-12 {
    width: 50%;
  }
  #packages .package.rdio label {
    padding: 20px 20px;
    min-height: 290px;
  }
  #packages .package h4 {
    font-size: 30px;
    margin-bottom: 0px;
  }
  #packages .package.rdio label h5 {
    margin: 0px;
  }
  #packages .package .btn-package {
    height: 40px;
    line-height: 10px;
    font-size: 12px;
  }
  #packages .package h6 {
    font-size: 13px;
    margin-bottom: 5px;
  }
  #packages .package .process-details {
    margin: 10px;
    font-size: 17px;
  }

  #packages .container-fluid .row .col-sm-12.text-center {
    width: 100%;
  }
  #packages .container-fluid .row .col-sm-12{
    padding: 0px 14px;
  }
  #why_choose_us .col-sm-12 {
    width: 100%;
  }
  #why_choose_us .left-img {
    width: 87%;
    height: 100%;
  }
  #why_choose_us .sub-text {
    text-align: justify;
  }
  #why_choose_us .feature {
    margin-bottom: 20px;
  }
  #why_choose_us .col-sm-12 .row .col-sm-6 {
    width: 50% !important;
  }
  #why_choose_us .feature {
    margin-bottom: 30px;
  }
  #why_choose_us .feature .content-panel h5 {
    font-size: 15px;
  }
  #why_choose_us .feature .content-panel p {
    font-size: 12px;
  }
  #why_choose_us .feature img {
    height: 29px;
    width: 29px;
  }
  #slider .slider_content {
    padding-top: 4rem;
  }
  #slider .slider_content h3 {
    font-size: 20px;
  }
  #slider .slider_content h3 span p{
    font-size: 14px;
    margin-bottom: 0.5rem;
  }
  #slider .slider_content h3 p{
    margin-bottom: 0.8rem;
  }
  #slider .slider_content .btn-more,
  #packages .btn-common {
    height: 45px;
    line-height: 36px;
    padding: 0px 15px;
    font-size: 12px;
  }
  #packages h2 {
    font-size: 20px;
  }
  #slider {
    min-height: 70vh;
    height: 80vh;
    width: 100%;
  }
  #why_choose_us h2 {
    margin-top: 20px;
  }
  #testimonial h2,
  #why_choose_us h2,
  #how_it_work .steps h5,
  #how_it_work h2 {
    font-size: 20px;
  }
  #testimonial .sub-text,
  #why_choose_us .sub-text,
  #how_it_work .steps p {
    font-size: 10px;
  }
  #how_it_work .work-div.desktop {
    display: none;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }
  #how_it_work .work-div.mobile {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  #how_it_work .work-div.mobile .carousel-indicators,
  #how_it_work .work-div.mobile .carousel-control-prev,
  #how_it_work .work-div.mobile .carousel-control-next {
    display: none;
  }
  #subscribe .right-img {
    height: 50%;
    top: -60px;
  }
  #subscribe h3 {
    font-size: 21px;
    text-align: left;
  }
  #subscribe {
    padding: 80px 0px;
  }
  #why_choose_us .container-fluid {
    padding: 0px 20px;
  }
  #why_choose_us {
    padding-top: 0px;
  }
  #testimonial .container {
    max-width: 100%;
  }
  #testimonial .swiper-container.desktop {
    display: none;
  }
  #testimonial .swiper-container.mobile {
    display: block;
    padding: 10px 10px;
  }
  #testimonial .home-wrapper .swiper-button-next {
    right: 20px;
  }
  #testimonial .home-wrapper .swiper-button-prev {
    left: 20px;
  }
  #subscribe .container-fluid .row .col-sm-12 {
    width: 100%;
  }
}

/*--------------------------------------------*/
/* Mini Tab and All Mobile View Media Query (575px)*/
/*--------------------------------------------*/
@media all and (max-width: 575px) {
  #subscribe .right-img {
    height: 45%;
  }
  #packages .package.rdio label {
    padding: 20px 20px;
    min-height: 290px;
  }
  #packages .packages-list-div{
    flex-direction: row;
    flex-wrap: wrap;
  }
  #packages .package.rdio label::before{
    top: 30px;
    right: 19px;
  }
  #packages .package.rdio input[type="radio"]:checked + label::after{
    top: 37px;
    right: 26px;
  }
}
/*--------------------------------------------*/
/*  Mobile View Media Query (480px)           */
/*--------------------------------------------*/
@media all and (max-width: 480px) {
  #how_it_work {
    padding: 10px 0px;
  }

  #testimonial .content-wrapper {
    width: 100%;
  }
  #testimonial .home-wrapper .swiper-button-prev {
    left: 5px;
    height: 24px;
    width: 24px;
  }
  #testimonial .home-wrapper .swiper-button-next {
    right: 5px;
    height: 24px;
    width: 24px;
  }
  #testimonial .home-wrapper .swiper-button-prev:after, #testimonial .home-wrapper .swiper-button-next:after{
    font-size: 13px;
  }
  #testimonial .container {
    max-width: 100%;
  }
  #packages {
    padding: 30px 0px;
  }
  #packages .container-fluid {
    padding: 0px 1rem;
    max-width: 100%;
  }
  #packages h2 {
    font-size: 25px;
  }
  #packages .container-fluid .row .col-sm-12 {
    width: 100%;
    padding: 0px 2rem;
  }

  #packages .package.rdio label {
    min-height: 280px;
    padding: 20px 20px;
  }
  #packages .package h5 {
    font-size: 13px !important;
  }
  #packages .package h4 {
    margin-bottom: 0px;
    font-size: 30px;
  }
  #packages .package .btn-package {
    font-size: 12px;
    border-radius: 12px;
  }
  #packages .package h6 {
    font-size: 13px;
    margin-bottom: 5px;
  }
  #packages .package .process-details {
    font-size: 18px;
  }
  #packages .container-fluid .row .col-sm-12.text-center {
    width: 100%;
  }
  #packages .package.rdio label::before {
    top: 24px;
  }
  #packages .package.rdio input[type="radio"]:checked + label::after {
    top: 31px;
  }
  #why_choose_us .col-sm-12 {
    width: 100%;
  }
  #why_choose_us .left-img {
    width: 100%;
  }
  #why_choose_us .sub-text {
    text-align: justify;
  }
  #why_choose_us .feature {
    margin-bottom: 20px;
  }
  #why_choose_us .col-sm-12 .row .col-sm-6 {
    width: 100% !important;
  }
  #why_choose_us .feature {
    margin-bottom: 30px;
  }
  #why_choose_us .feature .content-panel h5 {
    font-size: 15px;
  }
  #why_choose_us .feature .content-panel p {
    font-size: 12px;
  }
  #why_choose_us .feature img {
    height: 29px;
    width: 29px;
  }
  #slider .slider_content {
    padding-top: 5rem;
  }
  #slider .slider_content h3 {
    font-size: 15px;
    white-space: normal;
  }
  #slider .slider_content h3 p{
    margin-bottom: 0.5rem;
  }
  #slider .slider_content h3 span p{
    font-size: 10px;
    margin-bottom: 0.4rem;
  }
  #subscribe .container-fluid{
    max-width: 95%;
  }
  #slider .slider_content .btn-more,
  #packages .btn-common {
    height: 40px;
    line-height: 36px;
    padding: 0px 15px;
    font-size: 12px;
  }
  #packages h2 {
    font-size: 20px;
  }
  #slider {
    min-height: 70vh;
    height: 70vh;
    width: 100%;
  }
  #why_choose_us h2 {
    margin-top: 20px;
  }
  #testimonial h2,
  #why_choose_us h2,
  #how_it_work .steps h5,
  #how_it_work h2 {
    font-size: 20px;
  }
  #testimonial .sub-text,
  #why_choose_us .sub-text,
  #how_it_work .steps p {
    font-size: 10px;
  }
  #how_it_work .work-div.desktop {
    display: none;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }
  #how_it_work .work-div.mobile {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  #how_it_work .work-div.mobile .carousel-indicators,
  #how_it_work .work-div.mobile .carousel-control-prev,
  #how_it_work .work-div.mobile .carousel-control-next {
    display: none;
  }
  #subscribe .right-img {
    height: 44%;
    top: -80px;
  }
  #subscribe h3 {
    font-size: 16px;
    text-align: left;
  }
  #subscribe {
    padding: 55px 0px;
  }
  #why_choose_us {
    padding-top: 0px;
    padding-bottom: 56px;
  }
  #testimonial .swiper-container.desktop {
    display: none;
  }
  #testimonial .swiper-container.mobile {
    display: block;
    padding: 10px 10px;
  }
  #testimonial .feedback {
    padding: 10px 12px;
    margin: 0px;
  }
  #why_choose_us .container-fluid {
    padding: 0px 20px;
  }
}
/* ------------- */
/* rotate Mobile View Device media query 480px */
/* ------------- */
@media all and (max-height: 480px) and (orientation: landscape){
  #slider{
    height: 100vh;
  }
  #slider .slider_content h3 {
    font-size: 15px;
    white-space: normal;
  }
  #slider .slider_content h3 p{
    margin-bottom: 0.5rem;
  }
  #slider .slider_content h3 span p{
    font-size: 10px;
    margin-bottom: 0.4rem;
  }
}
/* ------------------------------------- */
/* Mini Mobile View Media Query (320px) */
/* --------------------------------------*/
@media all and (max-width: 320px) {
  #slider {
    min-height: 66vh;
  }

  #subscribe p {
    font-size: 10px;
  }
  #subscribe .input-group .form-control,
  #subscribe .input-group .btn-subscribe {
    font-size: 10px;
  }

  #slider .slider_content h3 {
    font-size: 15px;
    width: 100%;
  }
  #slider .slider_content h3 span p{
    font-size: 12px;
  }
  #packages .packages-list-div{
    padding: 0px;
  }

  #packages h2 {
    font-size: 18px;
  }

  #packages .package .btn-package {
    line-height: 10px;
    font-size: 10px;
    border-radius: 12px;
    height: 35px;
  }
  

  #packages .container-fluid .row .col-sm-12 {
    width: 100%;
    padding: 0px 1rem;
  }
  #packages .container-fluid {
    padding: 0px 20px;
  }

  #why_choose_us .feature img {
    width: 24px;
    height: 24px;
  }
  #why_choose_us .feature .content-panel h5 {
    font-size: 14px;
  }
  #why_choose_us .feature .content-panel p {
    font-size: 11px;
  }
  #subscribe .right-img {
    height: 40%;
    top: -48px;
  }
  #subscribe .container-fluid .row .col-sm-12 {
    width: 100%;
  }
  #subscribe {
    padding: 40px 0px;
  }
  #subscribe h3,
  #testimonial h2,
  #why_choose_us h2,
  #how_it_work .steps h5,
  #how_it_work h2 {
    font-size: 18px;
  }
  #subscribe .input-group {
    height: 37px;
  }
  #testimonial .home-wrapper .swiper-button-prev,
  #testimonial .home-wrapper .swiper-button-next {
    height: 23px;
    width: 23px;
  }
  #testimonial .home-wrapper .swiper-button-prev:after,
  #testimonial .home-wrapper .swiper-button-next:after {
    font-size: 13px;
  }
  #testimonial .feedback {
    padding: 10px 0px;
    margin: 0px;
  }
  #testimonial .swiper-container.mobile {
    padding: 10px 0px;
  }
  #testimonial .container {
    max-width: 95%;
  }
  #testimonial .home-wrapper .swiper-button-next {
    right: 0px;
  }
  #testimonial .home-wrapper .swiper-button-prev {
    left: 0px;
  }
}
