.fade.viewer.modal.show{
  z-index: 9999;
}

.viewer .document-iframe {
  height: 100%;
  width: 100%;
}

.viewer .modal-footer {
  z-index: 9999;
  border-top: 0px;
}

.video-player{
  top: 0px;
  left: 0px;
  overflow: hidden;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0);
}
.video-player .times-btn{
  padding: 10px 16px;
  color: white;
  position: fixed;
  top: 10px;
  right: 10px;
  font-size: 20px;
  background-color: transparent;
}
.previous-list{
  padding: 10px 16px;
  color: white;
  position: fixed;
  left: 0px;
  background-color: transparent;
  cursor: pointer;
  z-index:1999;
}
.next-list{
  padding: 10px 16px;
  color: white;
  position: absolute;
  right: 0px;
  z-index:1999;
  background-color: transparent;
}
.video-list-count{
  position: absolute;
    top: 0px;
    left: 0px;
    padding: 15px;
    color: white;
    font-weight: bold;
}
.url-videos{
  animation: slide-in 0.5s forwards;
}

@keyframes slide-in {
  100% { transform: translateX(0%); }
}