.request.specialist .req-list.desktop.specialist .req-list-div.specialist{
    padding: 0px;
}
.request.specialist .collapsible tr th,
.request.specialist .collapsible-bottom tr th,
.request.specialist .collapsible tr td,
.request.specialist .collapsible-bottom tr td {
   font-size: 16px;
}

.request .collapsible.new-request {
    background: white;
    border-radius: 8px 8px 8px 8px
}

.request .request-list .table-status .req-reject-btn {
    padding: 6px 25px;
    background: #e3e3e3;
    border-radius: 14px;
    color: #7b818d;
    white-space: nowrap;
}

.specialist-dashboards  .request .request-list .table-status .progress-section .progress-btn.accept-btn{
    margin-right: 1rem;
    line-height: 1.5;
    padding: 7px 25px;
}

.admin-content .request.specialist .current-work .req-list-div{
    border: 1px solid pink;
    border-radius: 8px;
}
.admin-content .request.specialist .current-work .req-list-div .collapsible{
    background-color: #f7f7f7;
}
.specialist-dashboards .request .req-list.mobile{
    display: none;
}
.specialist-dashboards .text-decoration-none.text-right{
    font-size: 12px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 10px;

}
.specialist-dashboards .text-decoration-none.text-right a{
    color: #7b818d;
    font-weight: 600;
    text-decoration: none;
}

/* --------------------------------------*/
/* Mini Laptop And All Tab View Media Query (1024px)  */
/* --------------------------------------*/
@media all and (max-width: 1024px) {
    .specialist-dashboards .request .collapsible tr th, 
    .specialist-dashboards .request .collapsible-bottom tr th, 
    .specialist-dashboards .request .collapsible tr td, 
    .specialist-dashboards .request .collapsible-bottom tr td{
        width: 20%;
    }
    
}
/* --------------------------------------*/
/* Mini Laptop And All Tab View Media Query (1024px)  */
/* --------------------------------------*/
@media all and (max-width: 780px) {
    .specialist-dashboards .request .req-list.mobile .req-list-div {
        padding: 0pc;
        border: 1px solid pink;
        border-radius: 8px;
    }
    .specialist-dashboards  .req-reject-btn{
        padding: 6px 26px;
        background: #e3e3e3;
        border-radius: 14px;
        color: #7b818d;
        white-space: nowrap;
        margin-left: 5px;
    }
    .specialist-dashboards .progress-btn.processing{
        padding: 6px 25px;
        background: #c7e3ff;
        border-radius: 14px;
        color: #2486ea;
        white-space: nowrap;
    }
    .specialist-dashboards .request .req-list.mobile{
        display: block;
    }
    .specialist-dashboards .request .req-list.mobile .collapsible{
        background-color: #f7f7f7;
        padding: 20px;
    }
    .specialist-dashboards .request .req-list.mobile .collapsible .row-div{
        display: flex;
    }
    .specialist-dashboards .request .mobile .table-status{
        align-items: flex-end;
        justify-content: flex-end;
    }
    .specialist-dashboards .request .secound{
        font-size: 16px;
    }
    .specialist-dashboards .request .primary{
        font-size: 16px;
        line-height: 2;
    }
}

/* --------------------------------------*/
/*    All Mobile View Media Query (480px)   */
/* --------------------------------------*/
@media all and (max-width: 480px){
    .specialist-dashboards .request .req-list.mobile .req-list-div {
        padding: 0pc;
        border: 1px solid pink;
        border-radius: 8px;
    }
    .specialist-dashboards  .req-reject-btn{
        padding: 6px 26px;
        background: #e3e3e3;
        border-radius: 14px;
        color: #7b818d;
        white-space: nowrap;
        text-align: center;
        margin-left: 5px;
        font-size: 13px;
        width: 100%;
    }
    .specialist-dashboards .progress-btn.processing{
        padding: 6px 25px;
        background: #c7e3ff;
        border-radius: 14px;
        color: #2486ea;
        white-space: nowrap;
        margin-bottom: 7px;
        text-align: center;
        font-size: 13px;
        width: 100%;
    }
    .specialist-dashboards .request .req-list.mobile{
        display: block;
    }
    .specialist-dashboards .request .req-list.mobile .collapsible{
        background-color: #f7f7f7;
        padding: 16px;
    }
    .request .collapsible-bottom{
        padding: 10px 15px;
    }
    .specialist-dashboards .request .req-list.mobile .collapsible .row-div{
        display: flex;
    }
    .specialist-dashboards .request .mobile .table-status{
        align-items: flex-end;
        flex-direction: column;
        padding: 0px 0px 0px 25px;
    }
    .specialist-dashboards .request .secound{
        font-size: 13px;
    }
    .specialist-dashboards .request .primary{
        font-size: 13px;
        line-height: 2;
    }
    .main-dashboard .admin-content .row-title{
        font-size: 15px;
    }
    .admin.text-secondary{
        font-size: 14px;
    }
    .specialist-dashboards .admin.header-title{
        font-size: 14px;
    }
    .main-dashboard .admin-content th{
        font-size: 13px;
    }
    .main-dashboard .admin-content td{
        font-size: 13px;
    }
    .specialist-dashboards .table{
        white-space: nowrap;
    }
}

/* --------------------------------------*/
/*    All Mobile View Media Query (480px)   */
/* --------------------------------------*/
@media all and (max-width: 320px){
    .specialist-dashboards .progress-btn.processing,
    .specialist-dashboards .req-reject-btn{
        padding: 4px 13px;
    }
    .specialist-dashboards .request .mobile .table-status{
        padding-left: 5px;
    }
    .specialist-dashboards .request .req-list.mobile .collapsible,
    .specialist-dashboards .request .collapsible-bottom{
        padding: 10px;
    }
    .admin.text-secondary{
        font-size: 13px;
    }
    .specialist-dashboards .admin.header-title{
        font-size: 13px;
    }
    .specialist-dashboards .request .primary{
        font-size: 12px;
    }
    .main-dashboard .admin-content th{
        font-size: 12px;
    }
    .main-dashboard .admin-content td{
        font-size: 12px;
    }
}