.myaccount{
    padding-top: 7rem;
    background: white;
}
.myaccount .container.myaccount-container{
    padding-left: 30px;
    padding-right: 30px;
}
.myaccount .myaccount-tabs .nav-pills{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    transition: 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.myaccount .myaccount-tabs .nav-pills .nav-item .nav-link{
    margin: 0px 5px;
    color: #152232;
    font-weight: 500;
}
.myaccount .myaccount-tabs .nav-pills .nav-item .nav-link:hover,
.myaccount .myaccount-tabs .nav-pills .nav-item .nav-link:focus-visible,
.myaccount .myaccount-tabs .nav-pills .nav-item .nav-link:focus,
.myaccount .myaccount-tabs .nav-pills .nav-item:focus{
    border-color:transparent!important;
    cursor: pointer;
    outline: 0px!important;
    font-family: 'Inter', sans-serif;
}
.myaccount .myaccount-tabs .nav-pills .nav-item:focus-visible{
    outline: 1px solid transparent!important;
}
.myaccount .myaccount-tabs .nav-pills .nav-item  .nav-link.active,
.myaccount .myaccount-tabs .nav-pills .nav-item .nav-link:focus {
    color: #495057;
    background-color: #fff;
    border-left: none!important;
    border-right: none!important;
    border-bottom: 3px solid #FCBB52!important;
    border-top: none!important;
    border-radius: 0px;
}
.myaccount .myaccount-tabs .nav-pills .nav-item .nav-link .tabs-list{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 12px;
    cursor: pointer;
}
.myaccount .myaccount-tabs .nav-pills .nav-item .nav-link .tabs-list label{
    cursor: pointer;
}
.myaccount .myaccount-tabs .nav-pills .nav-item .nav-link .tabs-list .tabs-img-div{
    margin-right: 6px;
    height: 100%;
    width: 20px;
}
.myaccount .myaccount-tabs .nav-pills .nav-item .nav-link{
    margin: 0px!important;
    white-space: nowrap;
    border-bottom: 3px solid transparent;
    border-radius: 0px;
}
/* 
  4:48 Feb - 5 / 2022
*/
/* ------------------------------------- */
/* Mini laptop and All Tabs View Media Query (1024px)  */
/* --------------------------------------*/
@media all and (max-width: 1024px){
    .myaccount .container{
        padding-left: 0px!important;
        padding-right: 0px!important;
        max-width: 90%;
    }
}

/* --------------------------------------*/
/* Mini Tab and All Tabs View Media Query (767px)  */
/* --------------------------------------*/
@media all and (max-width: 767px){
    .myaccount .container.myaccount-container{
        padding: 10px;
        max-width: 90%;
    }
    .myaccount{
        padding-top: 6rem;
    }
    .myaccount .myaccount-tabs .nav-pills .nav-item .nav-link{
        border-bottom: 1px solid #EDEDED!important;
        border-radius: 0px;
    }
    .myaccount .myaccount-tabs{
        overflow: scroll;
    }
    .myaccount .myaccount-tabs .nav-pills{
        overflow: scroll;
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
    .myaccount .myaccount-tabs .nav-pills .nav-item .nav-link .tabs-list label{
        font-size: 14px;
        text-align: left;
    }
}

/* --------------------------------------*/
/* Mini tab And All Mobile View Media Query (575px)  */
/* --------------------------------------*/
@media all and (max-width: 575px){
    .myaccount .container.myaccount-container {
        padding: 10px;
        max-width: 100%;
    }
}
/* ------------------------------------- */
/* Mini Mobile and All Mobile View Media Query (480px) */
/* --------------------------------------*/
@media all and (max-width: 480px){
    .myaccount .container{
        padding: 10px;
    }
    .myaccount{
        padding-top: 6rem;
    }
    .myaccount .container.myaccount-container{
        padding: 10px 0px;
        max-width: 100%;
    }
    .myaccount .myaccount-tabs .nav-pills .nav-item .nav-link{
        margin: 0px 3px;
        padding: 10px 8px;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .myaccount .myaccount-tabs .nav-pills .nav-item{
        border-bottom: 1px solid #EDEDED!important;
    }

    .myaccount .myaccount-tabs{
        overflow: scroll;
    }
    .myaccount .myaccount-tabs .nav-pills{
        overflow: scroll;
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
    .myaccount .myaccount-tabs .nav-pills .nav-item {
        width: 100%;
        border-bottom: 1px solid #EDEDED;
    }
    
    .myaccount .myaccount-tabs .nav-pills .nav-item .nav-link .tabs-list label{
        font-size: 14px;
        text-align: left;
    }
}
/* ------------------------------------- */
/* Mini Mobile View Media Query (320px)  */
/* --------------------------------------*/
@media all and (max-width: 320px){
    .myaccount .container.myaccount-container{
        max-width: 100%;
    }
}