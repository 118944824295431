.forgot p,
.forgot label,
.forgot button,
.forgot h1,
.forgot h5,
.forgot h6,
.forgot h3,
.forgot h4{
    font-family: 'Inter', sans-serif;
}
.forgot .forgot-heading{
    padding: 0px 0rem 1pc;
}
.forgot .forgot-title{
    font-size: 36px;
    color: #152232;
    font-weight: 900;
}
.forgot .forgot-box{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 360px;
}
.forgot .mt-4{
    margin-top: 4rem;
}
.forgot .forgot-title.m-4{
    margin: 2rem 0px 4px 0px!important;
}
.forgot .forgot-box .secoundry{
    font-size: 15px;
    color: #7E828B;
}
.forgot .forgot-box .forgot-form{
    padding-bottom: 15px;
    width: 100%;
}
.forgot .forgot-box .forgot-form .form-title{
    font-size: 15px;
    color: #152232;
    font-weight: 500;
    padding-bottom: 5px;
    font-family: 'Inter', sans-serif!important;

}
.forgot .forgot-box .forgot-form .input-field:focus{
    z-index: 1;
    border-color:transparent;
    box-shadow: rgb(49 130 206) 0px 0px 0px 0px;
}
.forgot .forgot-box .forgot-form .input-field{
    font-family: 'Inter', sans-serif!important;
    outline: transparent solid 2px;
    height: 100%;
}
.forgot .forgot-box .forgot-form  .input-group{
    border: 1px solid #DBDCDE;
    padding: 0px 5px;
}
.forgot .forgot-box .forgot-form  .input-group:hover{
    background-color: #fff;
}
.forgot .forgot-box .forgot-form .input-group-text{
    background: transparent;
    border: 0px;
    border-radius: 100px;
    padding: 0px 6px;
    height: 100%;
    width: 38px;
}
.forgot .forgot-box .forgot-form input.input-field {
    padding: 0px;
    border: 1px solid transparent;
    border-radius: 9px;
    font-size: 14px;
}
.forgot .forgot-box .forgot-form .input-group:focus-within{
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
.forgot .forgot-box .form-group{
    padding: 18px 0px 0px 0px;
}
.forgot .text-upper{
    text-transform: capitalize;
}
.forgot .user-select-none{
    -webkit-user-drag: none;
    -webkit-user-select: none;
}
.forgot .alter-forgot{
    color: #7F838C;
    margin-top: 10px;
    cursor: pointer;
}

.forgot .all-right-reserve{
    margin-top: 13px;
    color: #7B818D;
    font-size: 14px;
}
.forgot center{
    margin-top: 10px;
    width: 100%;
}
.forgot .px-10{
    display: flex;
    justify-content: space-around;
    padding: 8rem 0px 8px 0px!important;
    flex-direction: column;
    align-items: center;
}
.forgot .shadow-btn,
.forgot .shadow-btn:focus-visible,
.forgot .shadow-btn:focus,
.forgot .shadow-btn:active{
    box-shadow: rgb(26 115 232 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(26 115 232 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(26 115 232 / 15%) 0rem 0.0625rem 0.3125rem 0rem!important;
    transition: all 150ms ease-in 0s;
    border: 1px solid transparent;
}
.forgot .shadow-btn:focus-visible{
    border-color: transparent!important;
}
.forgot .forgot-btn:hover{
    border: 1px solid transparent!important;
    box-shadow: rgb(0 0 0 / 9%) 0rem 0.1875rem 0.3125rem -0.0625rem, rgb(0 0 0 / 7%) 0rem 0.125rem 0.3125rem;
        transform: scale(1.02);
}
.forgot .forgot-btn:focus{
    box-shadow: rgb(0 0 0 / 9%) 0rem 0.1875rem 0.3125rem -0.0625rem, rgb(0 0 0 / 7%) 0rem 0.125rem 0.3125rem;
}

.forgot .arrow_back{
    height: 15px;
    width: 15px;
    margin-right: 5px;
}
/* ------------------------------------- */
/* Mini laptop and All Tab View Media Query (1024px) */
/* --------------------------------------*/
@media all and (max-width:  1024px){
    .forgot .forgot-box{
        width: 76%!important;
    }
    .forgot .alter-forgot{
        font-size: 14px;
    }
}

/* ------------------------------------- */
/* Mini, Medium Tab and All Mobile View Media Query (767px) */
/* --------------------------------------*/
@media all  and (max-width: 767px){
    .forgot .col.p-0{
        display: none;
    }
    .forgot .col-6.px-10{
        width: 100%;
    }
    .forgot{
        padding: 0px 10px;
    }
    .forgot .forgot-box {
        width: 83%!important;
    }       
    .forgot .forgot-title.m-4{
        font-size: 30px;
        margin:2rem 0pc 6px 0px;
    }
    .forgot .forgot-box .secoundry{
        font-size: 13px!important;
    }
    .forgot .forgot-box .forgot-form .form-title{
        font-size: 14px;
    }
    .forgot .alter-forgot{
        font-size: 14px;
    }
}
/* ------------------------------------- */
/* Mini Tab and All Mobile View Media Query (575px) */
/* --------------------------------------*/
@media all and (max-width: 575px){

}
/* ------------------------------------- */
/* Mini mobile and mobile View Media Query (480px) */
/* --------------------------------------*/
@media all and (max-width: 480px){
    .forgot .col.p-0{
        display: none;
    }
    .forgot .col-6.px-10{
        width: 100%;
    }
    .forgot{
        padding: 0px 10px;
    }
    .forgot .forgot-box{
        width: 90%!important;
    }
    .forgot .forgot-title.m-4{
        font-size: 26px;
        margin:2rem 0pc 6px 0px;
    }
    .forgot .forgot-box .secoundry{
        font-size: 13px!important;
    }
    .forgot .forgot-box .forgot-form .form-title{
        font-size: 14px;
    }
    .forgot .alter-forgot{
        font-size: 12px;
        padding-top: 7px;
    }
    .forgot .col-6.px-10{
        padding: 6rem 0px 0px 0px!important;
    }
}

/* ------------------------------------- */
/* Mini mobile View Media Query (320px) */
/* --------------------------------------*/
@media all and (max-width: 320px){
    .forgot .forgot-title.m-4{
        font-size: 20px;
        font-weight: 500;
    }
    .forgot .forgot-box .secoundry,
    .forgot .forgot-box .forgot-form .form-title,
    .forgot .forgot-box .forgot-form input.input-field,
    .forgot .all-right-reserve{
        font-size: 12px!important;
    }
    .forgot .forgot-box .forgot-form .input-group-text{
        height: 43px;
        width: 33px;
    }
}