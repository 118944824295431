.notification .container {
    padding-bottom: 2rem;
    padding-top: 2rem;
}
.notification .container .noti-list-div{
    display: flex;
    flex-direction: column;
}
.notification .container .noti-list-div .noti-list{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background: #F2F2F2;
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    box-shadow: rgb(0 0 0 / 5%) 0rem 0.25rem 0.6875rem;
}
.notification .container .noti-list-div .noti-list .noti-left{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.notification .container .noti-list-div .noti-list .noti-left .noti-img{
    height: 37px;
    width: 37px;
    border-radius: 100px;
    margin-right: 8px;
}
.notification .container .noti-list-div .noti-list .noti-left .noti-img img{
    height: 100%;
    width: 100%;
    border-radius: 100px;
}
.notification .container .noti-list-div .noti-list .noti-left .noti-info .secoundTxt{
    color: #7B818D;
    font-size: 10px;
    line-height: 1.7;
}
.notification .container .noti-list-div .noti-list .noti-left .noti-info .primaryTxt{
    color: #152232;
    font-size: 13px;
    line-height: 1.7;
}
.notification .container .noti-list-div .noti-list .noti-left .noti-info{
    display: flex;
    flex-direction: column;
}
.notification .container .noti-list-div .noti-list  .noti-right label{
    font-size: 11px;
    color: #7B818D;
}

.notification label{
    font-family: 'Inter', sans-serif;
}
/* --------------------------------------*/
/* Mini Tab and All Tab View Media Query (767px)  */
/* --------------------------------------*/
@media all and (max-width: 767px){
    .notification .container{
        padding-top: 0px;
        max-width: 95%;
    }
}
/* --------------------------------------*/
/* Mini tab And All Mobile View Media Query (575px)  */
/* --------------------------------------*/
@media all and (max-width: 575px){
    .notification .container {
        padding-top: 0px;
        max-width: 93%;
    }
}
/* --------------------------------------*/
/* Mini Mobile And All Mobile View Media Query (480px)  */
/* --------------------------------------*/
@media all and (max-width: 480px){
    .notification .container{
        padding-top: 0px;
        max-width: 95%;
    }
    .notification .container .noti-list-div .noti-list{
        padding: 8px 8px;
    }
    .notification .container .noti-list-div .noti-list .noti-right label{
        font-size: 9px;
    }
    .notification .container .noti-list-div .noti-list .noti-left .noti-info .primaryTxt{
        font-size: 12px;
    }
    .notification .container .noti-list-div .noti-list .noti-left .noti-info .secoundTxt{
        font-size: 9px;
    }
}
/* --------------------------------------*/
/* Mini Mobile View Media Query (320px)  */
/* --------------------------------------*/
@media all and (max-width: 320px){
    .notification .container .noti-list-div .noti-list .noti-left .noti-img{
        height: 30px;
        width: 30px;
        margin-right: 1px;
    }
    .notification .container .noti-list-div .noti-list .noti-left .noti-info .primaryTxt{
        white-space: nowrap;
    }
    .notification .container .noti-list-div .noti-list .noti-left .noti-info .secoundTxt{
        font-size: 8px;
    }
}