.aboutus{
    padding-top: 8rem;
    background: #f7f7f7;
}
.aboutus p,
.aboutus h5,
.aboutus h6,
.aboutus label,
.aboutus button,
.aboutus{
    font-family: 'Inter', sans-serif;
}
.aboutus .container{
    padding-top: 5px;
    padding-left: 31px;

    padding-right: 31px;
}
.slideout{
    animation-duration: 2s;
    animation-name: slideout;
}

.aboutus .container .row{
    padding: 0px 0px 20px 0px;
}
.aboutus .container .aboutus-div .title{
    font-size: 27px;
    color: #152232;
    font-weight: 600;
    padding-bottom: 10px;
}
.aboutus .container .aboutus-div .title-secount{
    font-size: 21px;
    color: #FCBB52;
}
.aboutus .container .aboutus-div p{
    text-align: justify;
}
.aboutus .container .col-6 .img_section{
    width: 475px;
    height: 348px;
    margin-top: 5px;

}
.aboutus .container .col-6 .img_section .pic_1{
    height: 348px;
    width: 475px;
    position: absolute;
    margin-left: 15px;
    margin-top: -15px;
}
.aboutus .container .col-6 .img_section .pic_1 img{
    height: 100%;
    width: 100%;
    object-fit: contain;
    box-shadow: 0 5px 10px -3px rgb(0 0 0 / 23%), 0 6px 10px -5px rgb(0 0 0 / 25%);
}
.aboutus .container .col-6 .img_section .squer_pic{
    width: 475px;
    height: 348px;
    background-color: #FCBB52;
    position: absolute;
    box-shadow: 0 5px 10px -3px rgb(0 0 0 / 23%), 0 6px 10px -5px rgb(0 0 0 / 25%);
    overflow: visible;
}
.aboutus .container .col-12.company-info{
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
}
.aboutus .container .col-12 .customer-infos{
    margin: 30px 0px 30px 0px;
    padding: 0px 7px!important;
}
.aboutus .container .col-12 .customer-infos .cust-info{
    background-color: white;
    height: 149px;
    box-shadow: 0px 0px 20px #00000014;
    border: 1px solid #DEDEDE;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.aboutus .container .col-12 .customer-infos .cust-info .primary{
    line-height: 2;
    font-size: 35px;
    font-weight: 600px;
}
.aboutus .container .col-12 .customer-infos .cust-info .secoundry{
    font-size: 17px;
    color: #58657E;
}
.aboutus .contact-btn-div {
    border: 2px solid transparent;
    outline: 0px;
    width: 165px;
}
.aboutus .contact-btn ,
.aboutus .contact-btn:hover{
    border: 2px solid transparent;
    outline: 0px;
    width: 165px;
}

.aboutus .shadow-btn{
    box-shadow: rgb(26 115 232 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(26 115 232 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(26 115 232 / 15%) 0rem 0.0625rem 0.3125rem 0rem;
    transition: all 150ms ease-in 0s;
    border: 1px solid transparent;
    margin-bottom: 10px;
}
.aboutus .shadow-btn:hover{
    border-color: transparent!important;
    border: 1px solid transparent;
    transform: scale(1.02);
}
.aboutus .abouts-footer{
    background-color: white;
    padding: 30px 0px;
}
.aboutus .abouts-footer{
    font-size: 27px;
    color: #152232;
    text-align: center;
}
.aboutus .abouts-footer .ab-footer-innner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.aboutus .abouts-footer .footer-title-secound{
    width: 30%;
    align-items: center;
    color: #58657E;
    font-size: 15px;
    font-family: 'Inter', sans-serif;
}
.aboutus .abouts-footer .ab-footer-head{
    font-family: 'Inter', sans-serif;
    color: #152232;
    font-size: 27px;
}
.aboutus .aboutus-carosoul{
    margin-top: 20px;
    margin-bottom: 20px;
}

.aboutus .aboutus-carosoul .fa-angle-left,
.aboutus .aboutus-carosoul .fa-angle-right{
    font-size: 17px;
}
.aboutus .aboutus-carosoul div[data-arrow=right]{
    display: flex;
    
}
.aboutus-carosoul .swiper-slide{
    margin-right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.aboutus .aboutus-carosoul .cart-details{
    margin: 10px 10px;
    box-shadow: 0px 0px 20px #00000021;
    border: 1px solid transparent;

}
.aboutus .aboutus-carosoul .cart-details .card-body{
    padding-bottom: 0px;
}
.aboutus .aboutus-carosoul .cart-details .card-body .cart-top{
    display: flex;
    justify-content: space-between;
}
.aboutus .aboutus-carosoul .cart-details .card-body .cart-top .flex-row{
    display: flex;
    align-items: center;
}
.aboutus .aboutus-carosoul .cart-details .card-body .cart-top .card-img-top{
    width: 47px;
    height: 47px;
    box-shadow: 0px 0px 6px #00000021;
    border: 1px solid #FFFFFF;
    border-radius: 100px;
    margin-right: 10px;
}
.aboutus .aboutus-carosoul .cart-details .card-footer{
    font-size: 12px;
    background-color: white;
    border-top: 0px solid;
    margin-top: 0px;
}
.aboutus .aboutus-carosoul .cart-details .card-body .cart-top .card-title{
    font-size: 14px;
    color: #58657E;
    text-align: left;
}
.aboutus .aboutus-carosoul .cart-details .flex-col{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.aboutus-carosoul .swiper-button-next,
.aboutus-carosoul .swiper-button-prev{
    height: 30px;
    width: 30px;
    border-radius: 100px;
    background-color: #FCBB52;
}
.aboutus .aboutus-carosoul .ab-top-right-div{
    width: 36px;
    height: 27px;
}
.aboutus .aboutus-carosoul .ab-top-right-div img{
    height: 100%;
    width: 100%;
}
.aboutus-carosoul .swiper-button-next:after,
.aboutus-carosoul .swiper-button-prev:after{
    font-size: 18px;
    color: white;
}
.aboutus .aboutus-carosoul .text-muted .rate{
    font-family: 'Inter', sans-serif;
    font-size: 11px;
    margin-left: 5px;
}
.aboutus .aboutus-carosoul .text-muted i{
    color: #FCBB52;
    font-size: 14px;
}
.aboutus .aboutus-carosoul.desktop {
    display: block;
}
.aboutus .aboutus-carosoul.mobile{
    display: none;
}
.aboutus-carosoul .swiper-button-next{
    right: 0px;
}
.aboutus-carosoul .swiper-button-prev{
    left: 0px;
}
.aboutus .aboutus-carosoul .cart-details .card-footer{
    text-align: left;
}
/* 
  4:48 Feb - 5 / 2022
*/
/* Media Query for low resolution  Tablets, Ipads */
@media all and (max-width: 1024px){
    .aboutus .container .aboutus-div .title-secount{
        font-size: 18px;
    }
    .aboutus .container .col-6 .img_section{
        width: 100%;
        height: 301px;
        position: relative;
        margin-top: 20px;
    }
    .aboutus .container .col-6 .img_section .squer_pic{
        height: 301px;
        width: 335px;
    }
    .aboutus .container .col-6 .img_section .pic_1{
        height: 301px;
        width: 335px;
    }
    .aboutus .container .col-6 .img_section .pic_1 img{
        object-fit: cover;
    }
    .aboutus .container{
        padding-left: 0px;
        padding-right: 0px;
    }
    .aboutus .container{
        max-width: 92%;
    }
    .aboutus .aboutus-carosoul .cart-details{
        width: 100%!important;
    }
    .aboutus .container .col-12 .customer-infos .cust-info .primary{
        font-size: 30px;
        font-weight: 700;
    }
    .aboutus .container .col-12 .customer-infos .cust-info .secoundry{
        font-size: 15px;
    }
    .aboutus-carosoul .swiper-slide{
        padding: 20px 5px;
    }
    .aboutus .aboutus-carosoul .cart-details{
        margin: 0px;
    }
}
/* ------------------------------------- */
/* Mini tab and All Tab View Media Query (991px) */
/* --------------------------------------*/
@media all and (max-width: 991px){
    .aboutus .container{
        max-width: 96%;
    }
    .aboutus .aboutus-carosoul .ab-top-right-div{
        width: 26px;
        height: 19px;
    }
    .aboutus .aboutus-carosoul .cart-details .card-body .cart-top .card-img-top{
        height: 38px;
        width: 38px;
        margin-right: 5px;
    }
    .aboutus .aboutus-carosoul .cart-details .card-body .cart-top .card-title{
        font-size: 13px;
        margin-bottom: 3px;
    }
    .aboutus .aboutus-carosoul .text-muted i{
        font-size: 12px;
    }
}
/* ------------------------------------- */
/* Mini tab and All Tab View Media Query (767px) */
/* --------------------------------------*/
@media all and (max-width:767px){
    .aboutus .container .aboutus-div .title-secount{
        font-size: 18px;
    }
    .aboutus .container{
        max-width: 96%;
        padding: 0px 15px;
    }
    .aboutus .container .col-6.col{
        width: 100%;
        margin: 20px 0px;
        padding: 10px 20px;
    }
    .aboutus .container .col-6 .img_section{
        width: 100%;
        height: 355px;
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .aboutus .container .col-6 .img_section .squer_pic{
        height: 100%;
        width: 98%;
    }
    .aboutus .container .col-6 .img_section .pic_1{
        height: 100%;
        width: 98%;
        margin-left: 28px;
        margin-top: -28px;
    }
    .aboutus .container .col-6 .img_section .pic_1 img{
        object-fit: cover;
        object-position: top!important;
    }
    .aboutus .container .col-12.company-info{
        margin-top: 0px;
        flex-wrap: wrap;
    }
    .aboutus .container .col-12 .customer-infos{
        width: 50%;
        margin: 15px 0px;
    }
    .aboutus .abouts-footer .ab-footer-head{
        font-size: 24px;
    }
    .aboutus .abouts-footer .footer-title-secound{
        width: 80%;
    }
    .aboutus .aboutus-carosoul.desktop{
        display: none;
    }
    .aboutus .aboutus-carosoul.mobile{
        display: block;
    }
    .aboutus .aboutus-carosoul .cart-details{
        margin: 10px 0px;
        width: 69%!important;
    }
   
}
/* ------------------------------------- */
/* Mini tab and All Mobile View Media Query (667px) */
/* --------------------------------------*/
@media all and (max-width: 667px){
    .aboutus .container .row .col-6{
        width: 100% ;
    }
    .aboutus .aboutus-carosoul.mobile{
        display: block;
    }
    .aboutus .aboutus-carosoul .cart-details{
        width: 57%!important;
    }
}
/* ------------------------------------- */
/* Mini Mobile and All Mobile View Media Query (480px) */
/* --------------------------------------*/
@media all and (max-width: 480px){
    .aboutus{
        padding-top: 6rem;
    }
    .aboutus .container .aboutus-div .title-secount{
        font-size: 18px;
    }
    .aboutus .container .aboutus-div .title{
        font-size: 24px;
    }
    .aboutus .container .aboutus-div p{
        font-size: 14px;
    }
    .aboutus .container{
        max-width: 100%;
        padding: 0px 15px;
    }
    .aboutus .container .col-6.col{
        width: 100%;
        margin: 20px 0px;
    }
    .aboutus .container .col-6 .img_section{
        width: 100%;
        height: 255px;
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .aboutus .container .col-6 .img_section .squer_pic{
        height: 100%;
        width: 100%;
        left:-4px;
        top: 4px;
    }
    .aboutus .container .col-6 .img_section .pic_1{
        height: 100%;
        width: 100%;
        margin-left: 14px;
        margin-top: -14px
    }
    .aboutus .container .col-6 .img_section .pic_1 img{
        object-fit: cover;
        object-position: center;
    }
    .aboutus .container .col-12.company-info{
        margin-top: 0px;
        flex-wrap: wrap;
    }
    .aboutus .container .col-12 .customer-infos{
        width: 100%;
        margin: 15px 0px;
    }
    .aboutus .abouts-footer .ab-footer-head{
        font-size: 24px;
    }
    .aboutus .abouts-footer .footer-title-secound{
        width: 80%;
    }
    .aboutus .aboutus-carosoul.desktop{
        display: none;
    }
    .aboutus .aboutus-carosoul.mobile{
        display: block;
    }
    .aboutus .aboutus-carosoul .cart-details{
        width: 85%!important;
    }
    .aboutus-carosoul .swiper-button-next,
    .aboutus-carosoul .swiper-button-prev{
        height: 24px;
        width: 24px;
    }
    .aboutus-carosoul .swiper-button-next:after,
    .aboutus-carosoul .swiper-button-prev:after{
        font-size: 13px;
    }
}
/* ------------------------------------- */
/* Mini Mobile View Media Query (320px) */
/* --------------------------------------*/
@media all and (max-width: 320px){
    .aboutus .container{
        padding: 0px 10px;
    }
    .aboutus .container .aboutus-div .title{
        font-size: 20px;
    }
    .aboutus .container .aboutus-div .title-secount{
        font-size: 14px;
    }
    .aboutus .container .aboutus-div p{
        font-size: 12px;
    }
    .aboutus .contact-btn{
        font-size: 12px!important;
        height: 35px!important;
        padding: 6px 10px!important;
    }
    .aboutus .container .col-6 .img_section{
        height: 180px;
    }
    .aboutus .container .col-6 .img_section .squer_pic{
        height: 100%;
        width: 100%;
        left: -5px;
        top: 6px;
    }
    .aboutus .container .col-6 .img_section .pic_1{
        width: 100%;
        height: 100%;
        margin-left: 13px;
        margin-top: -13px;
    }
    .aboutus .abouts-footer .ab-footer-head{
        font-size: 17px;
    }
    .aboutus .abouts-footer .footer-title-secound{
        font-size: 14px;
    }
    .aboutus-carosoul .swiper-button-next,
    .aboutus-carosoul .swiper-button-prev{
        height: 20px;
        width: 20px;
    }
    .aboutus-carosoul .swiper-button-next:after, .aboutus-carosoul .swiper-button-prev:after{
        font-size: 10px;
    }
    .aboutus .aboutus-carosoul .cart-details .card-body .cart-top .card-img-top{
        height: 35px;
        width: 35px;
        margin-right: 5px;
    }
    .aboutus .aboutus-carosoul .cart-details .card-body .cart-top .card-title,
    .aboutus .aboutus-carosoul .text-muted i{
        font-size: 12px;
    }
    .aboutus .aboutus-carosoul .cart-details{
        width: 90%!important;
    }
    .aboutus .aboutus-carosoul .cart-details .card-body .cart-top .card-title{
        margin-bottom: 3px;
    }
    .aboutus .aboutus-carosoul .ab-top-right-div{
        width: 26px;
        height: 100%;
    }
}