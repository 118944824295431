.login p,
.login label,
.login button,
.login h1,
.login h5,
.login h6,
.login h3,
.login h4 {
  font-family: "Inter", sans-serif;
}
.login .login-heading {
  padding: 0px 0rem;
}
.login .login-title {
  font-size: 36px;
  color: #152232;
  font-weight: 500;
}
.login .login-box .login-form .text-danger{
  font-size: 13px;
}
.login .login-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 360px;
}
.login .mt-4 {
  margin-top: 4rem;
}
.login .login-title.m-4 {
  margin: 2rem 0px 4px 0px !important;
}
.login .login-box .secoundry {
  font-size: 15px;
  color: #7e828b;
}
.login .login-box .login-form {
  padding-bottom: 15px;
  width: 100%;
}
.login .login-box .login-form .form-title {
  font-size: 15px;
  color: #152232;
  font-weight: 500;
  padding-bottom: 5px;
  font-family: "Inter", sans-serif !important;
}
.login .login-box .login-form .input-field:focus {
  z-index: 1;
  border-color: transparent;
  box-shadow: rgb(49 130 206) 0px 0px 0px 0px;
}
.login .login-box .login-form .input-field {
  font-family: "Inter", sans-serif !important;
  outline: transparent solid 2px;
  height: 100%;
}
.login .login-box .login-form .input-group {
  border: 1px solid #dbdcde;
  padding: 0px 5px;
}
.login .login-box .login-form .input-group:hover {
  background-color: #fff;
}
.login .login-box .login-form .input-group-text {
  background: transparent;
  border: 0px;
  border-radius: 100px;
  padding: 0px 6px;
  height: 100%;
  width: 38px;
}
.login .login-box .login-form input.input-field {
  padding: 0px;
  border: 1px solid transparent;
  border-radius: 9px;
  font-size: 14px;
}
.login .login-box .login-form .input-group:focus-within {
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
.login .login-box .form-group {
  padding: 18px 0px 0px 0px;
}
.login .text-upper {
  text-transform: capitalize;
}
.login .user-select-none {
  -webkit-user-drag: none;
  -webkit-user-select: none;
}
.login .alter-login {
  color: #7f838c;
  margin-top: 10px;
  cursor: pointer;
}
.login .alter-login strong {
  color: #152232;
  font-weight: 500;
  font-size: 14px;
}
.login .all-right-reserve {
  margin-top: 13px;
  color: #7b818d;
  font-size: 14px;
}
.login center {
  margin-top: 10px;
  width: 100%;
}
.login .px-10 {
  display: flex;
  justify-content: space-around;
  padding: 7rem 0px 8px 0px !important;
  flex-direction: column;
  align-items: center;
}
.login .shadow-btn,
.login .shadow-btn:focus-visible,
.login .shadow-btn:focus,
.login .shadow-btn:active {
  box-shadow: rgb(26 115 232 / 15%) 0rem 0.1875rem 0.1875rem 0rem,
    rgb(26 115 232 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem,
    rgb(26 115 232 / 15%) 0rem 0.0625rem 0.3125rem 0rem!important;
  transition: all 150ms ease-in 0s;
  border: 1px solid transparent!important;
}
.login .shadow-btn{
  border: 1px solid transparent!important;

}
.login .shadow-btn:focus-visible {
  border-color: transparent !important;
}
.login .login-btn:hover {
  border: 1px solid transparent !important;
  box-shadow: rgb(0 0 0 / 9%) 0rem 0.1875rem 0.3125rem -0.0625rem,
    rgb(0 0 0 / 7%) 0rem 0.125rem 0.3125rem;
  transform: scale(1.02);
}
.login .login-btn:focus {
  box-shadow: rgb(0 0 0 / 9%) 0rem 0.1875rem 0.3125rem -0.0625rem,
    rgb(0 0 0 / 7%) 0rem 0.125rem 0.3125rem;
}
.login .pt-4.pb-4 {
  text-align: end;
}
.login .forgot-title {
  font-size: 14px;
  color: #152232;
  font-weight: 500;
  cursor: pointer;
}
.bounceIn {
  animation-duration: 10s;
  animation-name: bouncein;
}

@keyframes bouncein {
  0% {
     opacity: 0;
     transform: scale(.3);
  }
  50% {
     opacity: 1; 
     transform: scale(1.05);
  }
  70% {
     transform: scale(.9);
  }
  100% {
     transform: scale(1);
  }
}

@media all and (max-width: 1024px) {
  .login .login-title{
    font-size: 30px;
  }
}

/*--------------------------------------------*/
/* Mini Laptop with Tab (991px) View*/
/*--------------------------------------------*/
@media all and (max-width: 991px) {
  .login .login-box {
    width: 76%;
  }
  .login .login-box .login-form .text-danger{
    font-size: 14px;
  }
}

/*--------------------------------------------*/
/* Mini Tab with All Mobile Devices (767px) View*/
/*--------------------------------------------*/

@media all and (max-width: 767px) {
  .login .px-10.col-6{
    width: 100%;
  }
  .login .col.p-0{
    display: none;
  }
}

/*--------------------------------------------*/
/* Mini Tab with All Mobile Devices (667px) View*/
/*--------------------------------------------*/

@media all and (max-width: 667px) {
  .login .user-select-none {
    display: none;
  }

  .login .login-box .login-title {
    font-size: 28px;
  }

  .login .loginview {
    width: 100%;
  }

  .login .login-box .secoundry {
    font-size: 12px;
  }

  .login .login-box .login-form .form-title {
    font-size: 13px;
  }

  .login .alter-login {
    font-size: 13px;
    margin-top: 0px;
  }

  .login .alter-login strong {
    font-size: 13px;
  }
  .login .login-box .login-form .text-danger{
    font-size: 14px;
  }
  .login center {
    margin-top: 0px;
  }
}

/*--------------------------------------------*/
/* Mini Tab with All Mobile Devices (575px) View*/
/*--------------------------------------------*/

@media all and (max-width: 480px) {
  .login .user-select-none {
    display: none;
  }
  .login .login-box .login-title{
    margin-top: 1rem!important;
  }
  .login .loginview {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .login .login-box {
    width: 85%;
  }
  .login .login-box .login-form .form-title{
    font-size: 14px;
  }
  .login .login-box .login-title{
    font-size: 26px;
  }
  .login .login-box .login-form .text-danger{
    font-size: 12px;
  }
}

@media all and (max-width: 320px) {
  .login .login-title.m-4{
    font-size: 20px;
  }
  .login .login-box .secoundry{
    font-size: 12px;
  }
  .login .login-box{
    width: 90%;
  }
  .login .forgot-title,
  .login .alter-login{
    font-size: 12px;
  }
  .login .all-right-reserve,
  .login .login-box .login-form .form-title,
  .login .login-box .login-form input.input-field{
    font-size: 12px;
  }
  .login .login-box .login-form .input-group-text{
    height: 43px;
    width: 33px;
  }
  .login .login-box .login-title{
    font-size: 20px!important;
    font-weight: 500;
  }
  .login .login-box .login-form .text-danger{
    font-size: 11px;
  }
}