.main-dashboard .admin.content-page{
    background-color: #ebeff2;
}
.main-dashboard p,
.main-dashboard h1,
.main-dashboard h2,
.main-dashboard h3,
.main-dashboard h5,
.main-dashboard h4,
.main-dashboard span,
.main-dashboard label,
.main-dashboard a,
.main-dashboard button,
.main-dashboard td,
.main-dashboard th{
    font-family: Karla, sans-serif!important;
}
.main-dashboard .btn-request {
    background: #fcbb52;
    font-size: 11px;
    padding: 5px 25px;
    letter-spacing: 0.4px;
    color: #343a40;
}
.main-dashboard .nav-tabs.nav-bordered a.active {
    border-bottom: 2px solid #fcbb52 !important;
}
.main-dashboard h2.admin{
    font-size: 25px;
}
.main-dashboard h5.admin{
    font-size: .9175rem;
}
.main-dashboard p.admin{
    font-size: 14px;
    font-weight: 700;
    font-family: Karla, sans-serif;
}
.main-dashboard #sidebar-menu > ul > li > a{
    text-decoration: none!important;
}

.main-dashboard .bg-orange {
    background-color: #fd9554 !important;
}
.login-dashboard{
    background-image: url(../../../../assets/login_bg.png);
    background-size: cover;
    background-position: center;
}
.main-dashboard .file > input[type='file'] {
    display: none;
}
.main-dashboard .file--upload > label {
    color: hsl(204, 86%, 53%);
    border-color: hsl(204, 86%, 53%);
}
.main-dashboard .file > label {
    font-size: 11px;
    font-weight: 300;
    cursor: pointer;
    border-radius: 4px;
    padding: 5px 10px;
    background: #fcbb52;
    color: #383936 !important;
}
.main-dashboard .file {
    position: relative;
    display: inline-block;
    margin-top: 17px;
}

@media (max-width: 991.98px){
    .admin.footer{
        position: relative ;
        left: 0px;
    }
}