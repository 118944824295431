.changepass-contents .input-warning{
    font-size: 12px;
    line-height: 2;
}
.changepass-contents .form-control{
    border-right: 0px solid white!important;
}
.changepass-contents .input-group-text{
    background-color: transparent;
}
.changepass-contents .input-group-text i.fa{
    color: #585858;
}