.addspecialist-contents .form-label{
    margin-bottom: 0rem;
    font-weight: 600;
    font-size: 0.9rem;
    color: #343a40;
}
.addspecialist-contents .admin.form-control,.addspecialist-contents .admin.form-select{
    margin-top: 0rem;
}
.addspecialist-contents .upload-image-title{
    display: flex;
    align-items: center;
    justify-content: center;
}
.addspecialist-contents .text-danger{
    font-size: 12px;
}
.addspecialist-contents .vehicle-location-place{
    position: relative;
    cursor: pointer;
    z-index: 12;
}
.addspecialist-contents .vehicle-location-place .autoplace-location{
    position: absolute;
    width: 100%;
    overflow: scroll;
}

.addspecialist-contents .admin.form-control.password-box {
    border-right: 1px solid white;
}

.addspecialist-contents .input-group-text {
    background: transparent;
    cursor: pointer;
}

.addspecialist-contents .sepecialization-select{
     z-index: 99;
}
.addspecialist-contents [disabled] {
    background-color: #f2f2f2!important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}