a.admin{
    text-decoration: none;
    cursor: pointer;
}
a i.fa-cog{
    padding: 10px 5px;
    font-size: 14px;
    color: #98a6ad;
}
a i.fa-power-off{
    font-size: 14px;
    padding: 10px 5px;
    color: #f05151;
    cursor: pointer;
}
#sidebar-menu .admin.menuitem > a.active{
    color: #fcbb52!important;
    background: #383936!important;
}

.admin.open-side-nav.left-side-menu{
    display: block!important;
    z-index: 129!important;
}
.admin.left-side-menu{
    scrollbar-width: none;
}
.left-side-menu .menuitem .inner-menu-list{
    animation-name: DropDownSlide;
    animation-duration: .3s;
    animation-fill-mode: both;
}
.left-side-menu .menuitem .inner-menu-list .menuitem a{
    color: #6e768e;
    display: block;
    padding: 12px 20px;
    position: relative;
    transition: all .4s;
    font-family: Karla, sans-serif;
    font-size: .95rem;
    text-decoration: none!important;
}
.left-side-menu .menuitem .inner-menu-list .menuitem i{
    display: inline-block;
    line-height: 1.0625rem;
    margin: 0 7px 0 3px;
    text-align: center;
    vertical-align: middle;
    width: 20px;
    font-size: 1.2rem;
}
.left-side-menu .menuitem .inner-menu-list .menuitem span{
    text-decoration: none;
}
.left-side-menu .menuitem .fa-angle-down,
.left-side-menu .menuitem .fa-angle-up{
    color: #6e768e;
    font-size: 14px;
}

.left-side-menu .menuitem .pushnoti_icon {
  margin: 0 15px 0 3px;
}

