.specialist-detail .admin.card h1{
    margin: 0px;
    font-size: 55px;
}

.specialist-detail .admin.card h5{
    font-size: 17px;
    font-weight: 500;
}

.specialist-detail .spdetail-card-body{
    padding: 0.5rem 1rem;
}





.specialist-detail .sp-prodetail {
    background: white;
    padding: rem;
    padding: 1rem 1rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex-direction: row;
}

/* .specialist-detail .sp-prodetail .sp-info{
    padding: 0px 55px
} */

.specialist-detail .sp-user-info{
  padding: 1rem 1rem;
}

.specialist-detail .sp-profile-img {
    height: 50px;
    width: 50px;
    object-fit: contain;
    border-radius: 25px;
}

/*--------------------------------------------*/
/*Start Responsive and Mobile Views*/
/*--------------------------------------------*/

/*--------------------------------------------*/
/* Laptop with Desktop (1600px) View*/
/*--------------------------------------------*/
@media all and (max-width:1600px){

}

/*--------------------------------------------*/
/* Laptop with Desktop (1400px) View*/
/*--------------------------------------------*/
@media all and (max-width:1400px){

    
}


/*--------------------------------------------*/
/* Laptop with Tab (1199px) View*/
/*--------------------------------------------*/
@media all and (max-width:1199px){

    
}

/*--------------------------------------------*/
/* Mini Laptop with Tab (991px) View*/
/*--------------------------------------------*/
@media all and (max-width: 991px){  

    
}

/*--------------------------------------------*/
/* Mini Tab with All Mobile Devices (767px) View*/
/*--------------------------------------------*/
@media all and (max-width:767px){

    
}

/*--------------------------------------------*/
/* Mini Tab with All Mobile Devices (667px) View*/
/*--------------------------------------------*/
@media all and (max-width: 667px){

   
}

/*--------------------------------------------*/
/* Mini Tab with All Mobile Devices (575px) View*/
/*--------------------------------------------*/
@media all and (max-width: 575px){

}

