.request-contents .form-switch .form-check-input{
    height: 24px;
    width: 44px;
}
.request-contents .form-switch .form-check-input:checked{
    background-color: #f1b53d;
    border-color: #f1b53d;
}
.request-contents .ripple {
    background-position: center;
    transition: background 0.8s;
}
.request-contents .ripple:hover {
    background: #f1f3fa  radial-gradient(circle, transparent 1%, #f1f3fa  1%) center/15000%;
}
.request-contents .ripple:active {
    background-color: rgb(151, 201, 255);
    background-size: 100%;
    transition: background 0s;
}
.request-contents .search-div-outter{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.request-contents .search-div-outter input{
    border: 0px solid ;
    height: 100%;
    padding: 0px 0px 0px 15px;
    background-color: transparent;
}
.request-contents .search-div-outter input:focus-visible{
    border: 0px;
    outline: 0px;
}
.request-contents .search-div-outter span{
    padding: 5px;
}
.request-contents .search-div-outter span i{
    color: darkgrey;
}
.request-contents .search-div-outter .search-div-inner{
    border: 1px solid transparent;
    padding: 6px 14px;
    border-radius: 100px;
    background-color: #f1f3fa;
    display: flex;
    align-items:center;
    flex-direction: row;
    cursor: pointer;
}
.request-contents .admin.badge.reject{
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}
.admin.badge.assigned{
    background-color: #71b6f9;
    border-color: #71b6f9;
}

.admin.badge.initiated{
    background-color: #f9c851;
    border-color: #f9c851;
}

.admin.badge.complete{
    background-color: #10c469;
    border-color: #10c469;
}

.admin.badge.completed{
    background-color: #10c469;
    border-color: #10c469;
}





/* -------------------------------------------- */
/* --------- Mobile View Media query 480px----- */
/* -------------------------------------------- */
@media all and (max-width: 480px){
    /* .request-contents .col-12 .card .card-body .col-12 .col-6{
        width: 100%;
    } */
    .request-contents .search-div-outter{
        justify-content: flex-end;
        cursor: pointer;
        padding: 5px 10px!important;
    }
    .request-contents .search-div-outter .search-div-inner input,
    .request-contents .trash-icon{
        display: none;
    }
}