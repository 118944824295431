.payment .container {
    padding-bottom: 2rem;
    padding-top: 2rem;
}
.payment table{
    margin-bottom: 0px;
}
.payment thead th,
.payment tbody td{
    border-bottom: 1px solid #E6E6E6;
    padding: 13px 20px;
    font-size: 13px;
    font-family: 'Inter', sans-serif;
}
.payment thead th{
    color: #7B818D;
    font-weight: 500;
    padding: 19px 20px;
}
.payment tbody td{
    font-weight: 400;
    font-size: 13px;
    vertical-align: middle;
    color: #152232;
}
.payment .collapsible{
    border: 1px solid #E6E6E6;
    border-radius: 8px;
}
.payment .collapsible.mobile{
    display: none;
}
.payment .collapsible.desktop{
    display: block;
}
.payment .payment-method{
    width: 75px;
    height: 33px;
}
.payment .payment-method img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.payment table td .Paid{
    color: #29AC42;
}

/* --------------------------------------*/
/* Mini Tab and All Tab View Media Query (767px)  */
/* --------------------------------------*/
@media all and (max-width: 767px){
    .payment .container{
        padding-top: 0px;
        max-width: 95%;
    }
    .payment .collapsible{
        overflow: scroll;
    }
    .payment table{
        width: 100%;
    }
    .payment .collapsible.mobile{
        display: block;
        border-radius: 0px!important;
        border: none!important;
    }
    .payment .collapsible.desktop{
        display: none;
    }
    .payment .collapsible.mobile .history-list{
        margin: 15px 0px;
        border-radius: 8px;
        overflow: hidden;
        border: 1px solid #E6E6E6;
    }
    .payment .collapsible.desktop{
        display: none;
    }
    .payment .secount{
        color: #7B818D!important;
        font-size: 12px;
    }   
    .payment .primary{
        color: #152232!important;
        font-size: 13px;
    }
    .payment .td{
        padding: 10px 24px;
    }
}
/* --------------------------------------*/
/* Mini tab And All Mobile View Media Query (575px)  */
/* --------------------------------------*/
@media all and (max-width: 575px){
    .payment .container {
        padding-top: 0px;
        max-width: 93%;
    }
}
/* --------------------------------------*/
/* Mini Mobile and All Mobile View Media Query (480px)  */
/* --------------------------------------*/
@media all and (max-width: 480px){
    .payment .container{
        padding-top: 0px;
        max-width: 95%;

    }
    .payment .collapsible{
        overflow: scroll;
    }
    .payment table{
        width: 100%;
    }
    .payment .collapsible.mobile{
        display: block;
        border-radius: 0px!important;
        border: none!important;
    }
    .payment .collapsible.desktop{
        display: none;
    }
    .payment .collapsible.mobile .history-list{
        margin: 15px 0px;
        border-radius: 8px;
        overflow: hidden;
        border: 1px solid #E6E6E6;
    }
    .payment .collapsible.desktop{
        display: none;
    }
    .payment .secount{
        color: #7B818D!important;
        font-size: 12px;
    }   
    .payment .primary{
        color: #152232!important;
        font-size: 13px;
    }
}
/* --------------------------------------*/
/* Mini Mobile View Media Query (320px)  */
/* --------------------------------------*/
@media all and (max-width: 320px){
    .payment .payment-method{
        width: 50px;
    }
}