.seller-content .form-switch .form-check-input{
    height: 24px;
    width: 44px;
}
.seller-content .form-switch .form-check-input:checked{
    background-color: #f1b53d;
    border-color: #f1b53d;
}
.seller-content .ripple {
    background-position: center;
    transition: background 0.8s;
}
.seller-content .ripple:hover {
    background: #f1f3fa  radial-gradient(circle, transparent 1%, #f1f3fa  1%) center/15000%;
}
.seller-content .ripple:active {
    background-color: rgb(151, 201, 255);
    background-size: 100%;
    transition: background 0s;
}
.seller-content th,
.seller-content td{
    font-size: 0.9rem;
}
.seller-content th,
.seller-content td{
    font-size: 0.9rem;
    font-family: Roboto, sans-serif!important;
}

.seller-modal .modal-content{
    border-radius: 30px;
    border: 0px solid white;
    box-shadow: rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(64 64 64 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem;
    padding: 13px;
}

.delete-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    width:9rem;
    height: 3rem;
    opacity: 1;
    background: linear-gradient(195deg, rgb(255 185 16), rgb(252 187 82));
    color: rgb(255, 255, 255);
    border-radius: 0.75rem;
    box-shadow: rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(64 64 64 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem;
}
.cancel-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    width:9rem;
    height: 3rem;
    opacity: 1;
    background:linear-gradient(195deg, rgb(171 171 171), rgb(171 171 171));
    color: rgb(255, 255, 255);
    border-radius: 0.75rem;
    box-shadow: rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(64 64 64 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem;
}