.sp_notification .container {
    padding-bottom: 2rem;
    padding-top: 2rem;
}
.sp_notification .container .noti-list-div{
    display: flex;
    flex-direction: column;
}
.sp_notification .container .noti-list-div .noti-list{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background: #F2F2F2;
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    box-shadow: rgb(0 0 0 / 5%) 0rem 0.25rem 0.6875rem;
}
.sp_notification .container .noti-list-div .noti-list .noti-left{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.sp_notification .container .noti-list-div .noti-list .noti-left .noti-img{
    height: 37px;
    width: 37px;
    border-radius: 100px;
    margin-right: 8px;
}
.sp_notification .container .noti-list-div .noti-list .noti-left .noti-img img{
    height: 100%;
    width: 100%;
    border-radius: 100px;
}
.sp_notification .container .noti-list-div .noti-list .noti-left .noti-info .secoundTxt{
    color: #7B818D;
    font-size: 10px;
    line-height: 1.7;
}
.sp_notification .container .noti-list-div .noti-list .noti-left .noti-info .primaryTxt{
    color: #152232;
    font-size: 15px;
    line-height: 1.7;
}
.sp_notification .container .noti-list-div .noti-list .noti-left .noti-info{
    display: flex;
    flex-direction: column;
}
.sp_notification .container .noti-list-div .noti-list  .noti-right label{
    font-size: 11px;
    color: #7B818D;
}

.sp_notification label{
    font-family: 'Inter', sans-serif;
}

/*--------------------------------------------*/
/* Mini mobile with All Mobile Devices (480px) View*/
/*--------------------------------------------*/
@media all and (max-width:480px){
    .sp_notification .container{
        padding: 2rem 0px;
    }
    .content-page .content .container-fluid{
        padding: 0px;
    }
    .sp_notification .container .noti-list-div .noti-list{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    
    }
    .sp_notification .container .noti-list-div .noti-right{
        float: right;
    }
    .sp_notification .container .noti-list-div .noti-list .noti-left{
        display: flex;
        width: 100%;
    }
    .sp_notification .container .noti-list-div .noti-list .noti-left .noti-img{
        width: 30px;
        height: 30px;    
    }
    .sp_notification .container .noti-list-div .noti-list .noti-left .noti-info .primaryTxt{
        font-size: 13px;
    }
    .sp_notification .container .noti-list-div .noti-list .noti-left .noti-info{
        width: 100%;
    }
}