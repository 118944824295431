@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import url(https://fonts.googleapis.com/css?family=Karla:400,700);

.navbar-custom {
    background-color: #ebeff2;
    padding: 0 calc(1.5rem / 2) 0 0;
    position: fixed;
    left: 0;
    right: 0;
    height: 60px;
    z-index: 1001;
    top: 0px;
}
.navbar-custom .waves-effect {
    position: relative;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.navbar-custom .float-end {
    float: right !important;
}
.navbar-custom .list-unstyled {
    padding-left: 0;
    list-style: none;
}
.navbar-custom .mb-0 {
    margin-bottom: 0 !important;
}
.navbar-custom .d-lg-block {
    display: block !important;
}
.navbar-custom .topnav-menu  li{
    float: left;
}
.navbar-custom .app-search {
    overflow: hidden;
    height: 70px;
    display: table;
    max-width: 180px;
    margin-right: 20px;
}
.navbar-custom .app-search .app-search-box {
    display: table-cell;
    vertical-align: middle;
}
.navbar-custom .app-search .app-search-box .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}
.navbar-custom .d-lg-none {
    display: none !important;
}
.navbar-custom .app-search .form-control {
    border: none;
    height: 38px;
    padding-left: 20px;
    padding-right: 0;
    color: #666666;
    background-color: #e2e6ea;
    box-shadow: none;
    border-radius: 30px 0 0 30px;
    padding: 0.45rem 0.9rem;
    font-size: .8rem;
    font-weight: 400;
    line-height: 1.5;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    font-family: "Inter", sans-serif;
}

.navbar-custom .app-search .btn {
    background-color: #e2e6ea;
    border-color: transparent;
    color: #666666;
    border-radius: 0 30px 30px 0;
    box-shadow: none !important;
    font-size: 20px;
    padding: 0px 10px;
}
.navbar-custom .topnav-menu .nav-link {
    padding: 0 15px;
    color: #6c757d;
    min-width: 32px;
    display: block;
    line-height: 60px;
    text-align: center;
    max-height: 60px;
}
.notification-list .noti-icon-badge {
    display: inline-block;
    position: absolute;
    top: 16px;
    right: 8px;
    padding: 0.25em 0.4em;
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    background-color: #ff5b5b !important;
}
.navbar-custom #dropdown-menu-align-end .mdi-bell-outline{
    font-size: 20px;
}
.navbar-custom .topnav-menu .nav-link i{
    vertical-align: middle;
    font-size: 21px;
}
.navbar-custom .nav-user{
    padding: 0 12px !important;
}
.navbar-custom .nav-user img{
    height: 40px;
    width: 40px;
    border-radius: 50% !important;
    vertical-align: middle;
}
.navbar-custom .pro-user-name{
    font-family: "Inter", sans-serif;
}
.navbar-custom .button-menu-mobile.disable-btn {
    display: none;
}
.navbar-custom .button-menu-mobile{
    border: none;
    color: #323a46;
    display: inline-block;
    height: 60px;
    width: 60px;
    background-color: transparent;
    font-size: 24px;
    cursor: pointer;
    line-height: 30px;
}
.navbar-custom .logo-box {
    height: 60px;
    width: 240px;
    background-color: white;
    float: left;
    transition: all .1s ease-out;
}
.navbar-custom .page-title-main {
    margin: 0;
    line-height: 60px;
    padding: 0 24px;
    color: #343a40;
    font-family: 'Karla', sans-serif;
    font-weight: 600;
    font-size: 1.125rem;
}
.navbar-custom .logo span.logo-sm{
    display: none;
}
.navbar-custom .logo-light {
    display: none;
}
.navbar-custom .logo-dark{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.navbar-custom .logo span.logo-lg{
    display: block;
}
.navbar-custom a.logo{
    text-decoration: none;
}

.navbar-custom .logo span.logo-lg h5{
    display: flex;
    color: #383936;
    font-size: 26px;
    font-family: Karla, sans-serif;
    font-weight: 900;
}
.navbar-custom .logo span.logo-lg h5 label{
    color: #fcbb52;
    font-weight: 900;
}
.navbar-custom  .dropdown-item{
    cursor: pointer;
}
.navbar-custom .search-dropdown .dropdown-item:active{
    background-color: #e9ecef;
}
.navbar-custom .dropdown-menu-end{
    padding: 5px 5px;
}
.navbar-custom .dropdown-item:hover{
    background-color: #fcbb52;
    color: #fff;
    border-radius: 3px;
}
.language-dropdown button{
    line-height: 50px;
}
.navbar-custom .noti-list-drop .dropdown-toggle::after{
    display: none!important;
}
.navbar-custom .noti-list-drop .dropdown-item:hover{
    background-color: transparent;
    color: #323a46;
    border-radius: 3px;
}
.navbar-custom .noti-list-drop .notify-details{
    margin-bottom: 0.5px;
}
.navbar-custom .notification-list .notify-item .notify-icon {
    float: left;
    height: 36px;
    width: 36px;
    font-size: 18px;
    line-height: 36px;
    text-align: center;
    margin-right: 10px;
    border-radius: 50%;
    color: #fff;
}
.navbar-custom .notification-list .noti-title {
    background-color: transparent;
    padding: 15px 20px;
}
.navbar-custom .noti-list-drop .dropdown-header{
    padding: 0px;
}
.navbar-custom .notification-list .notify-item .notify-details {
    overflow: hidden;
    margin-left: 45px;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #343a40;
}
.navbar-custom .noti-list-drop .simplebar-content-wrapper .dropdown-item:hover{
    background-color: #f5f5f5;
}
.navbar-custom .notification-list .notify-item .user-msg {
    margin-left: 45px;
    white-space: normal;
    line-height: 16px;
    text-align: right;
}
.navbar-custom .small,.navbar-custom small {
    font-size: .75em;
}

.navbar-custom .myaccount-menu {
    background-color: transparent;
    line-height: 60px;
}
.navbar-custom .myaccount-menu button{
    background-color: transparent;
    color: #6c757d;
    border-color: transparent;
}
.navbar-custom .myaccount-menu button::after{
    display: none;
}
.navbar-custom .myaccount-menu button:hover,
.navbar-custom .myaccount-menu button:focus,
.navbar-custom .myaccount-menu .admin.show > .admin.btn-primary.admin.dropdown-toggle,
.navbar-custom .admin.show > .admin.btn-primary.admin.dropdown-toggle:focus,
.navbar-custom .myaccount-menu button:active,
.navbar-custom .myaccount-menu.admin.show > .admin.btn-primary.admin.dropdown-toggle{
    background-color: transparent!important;
    color: #6c757d!important;
    box-shadow: 0 0 0 0rem rgb(134 193 250 / 50%)!important;
    border-color: transparent!important;
}
.navbar-custom .myaccount-menu button:focus-visible{
    outline: 0px!important;
}
.navbar-custom .myaccount-menu .dropdown-item{
    line-height: 1.2;
    padding: 5px 10px;
    display: flex;
    align-items: center;
}
.navbar-custom .myaccount-menu .dropdown-item .mdi{
    font-size: 20px;
    margin-right: 5px;
}
/*--------------------------------------------*/
/* Mini Laptop with Tab (991px) View*/
/*--------------------------------------------*/
@media all and (max-width: 991px){  
    .navbar-custom .logo span.logo-sm{
        background: #383936;
        display: block!important;
    }
    .navbar-custom .logo-box .logo-lg {
        display: none !important;
    }
    .navbar-custom .logo-dark{
        background: #383936;
    }
    .navbar-custom .logo-box {
        background: 0 0 !important;
        width: 70px !important;
        padding-right: 0 !important;
    }
    .navbar-custom .logo span.logo-sm h5{
        font-size: 36px;
        color: white;
        margin: 0px;
        font-weight: 900;
    }
    .navbar-custom .d-none {
        display: none !important;
    }
    .navbar-custom .d-lg-none{
        display: block!important;
    }
    .navbar-custom .topnav-menu .nav-link i.mdi-magnify{
        font-size: 20px;
    }
    .navbar-custom .button-menu-mobile.disable-btn{
        display: block !important;
    }
    .navbar-custom .page-title-main {
        display: none;
    }
}


/*--------------------------------------------*/
/* Mini Tab with All Mobile Devices (767px) View*/
/*--------------------------------------------*/
@media all and (max-width:767px){
    .navbar-custom .pro-user-name{
        display: none;
    }
}
/*--------------------------------------------*/
/* Mini Tab with All Mobile Devices (600px) View*/
/*--------------------------------------------*/
@media all and (max-width: 600px){
    .navbar-custom .language-dropdowns .admin.dropdown-menu.admin.show{
        width: 300px;
    }
}

/*--------------------------------------------*/
/* Mini mobile with All Mobile Devices (480px) View*/
/*--------------------------------------------*/
@media all and (max-width:480px){
    .main-dashboard .navbar-custom i{
        font-size: 20px;
    }
    .navbar-custom{
        padding: 0px;
    }
    .navbar-custom .language-dropdown button{
        padding: .375rem .2rem;
    }
    .notification-list .noti-icon-badge{
        right: 11px;
    }
    .navbar-custom .language-dropdown .flag-select-en{
        height: 0px;
    }
    .navbar-custom .language-dropdowns .admin.dropdown-menu.admin.show{
        width: 270px!important;
    }
    .navbar-custom .notification-list .notify-item .notify-details{
        font-size: 11px;
    }
    .navbar-custom .user-msg .small, .navbar-custom .user-msg small{
        font-size: 9px;
    }
    .navbar-custom .topnav-menu .nav-link,
    .navbar-custom .myaccount-menu button{
        padding: 0px 10px!important;
    }
    .navbar-custom .topnav-menu .nav-link i.mdi-magnify{
        font-size: 20px;
    }
    .navbar-custom .nav-user img{
        height: 35px;
        width: 35px;
    }
    .navbar-custom .button-menu-mobile.disable-btn{
        padding: 10px 8px;
        width: 50px;
    }
}

/*--------------------------------------------*/
/* Mini Mobile Devices (320px) View*/
/*--------------------------------------------*/
@media all and (max-width:320px){
    .navbar-custom .topnav-menu .nav-link i.mdi-magnify{
        font-size: 20px;
    }
    .navbar-custom .logo-box{
        width: 54px !important;
    }
    .navbar-custom .button-menu-mobile.disable-btn{
        width: 40px;
    }
    .navbar-custom .language-dropdowns .admin.dropdown-menu.admin.show{
        width: 200px!important;
    }
}