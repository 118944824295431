.detail-form {
  padding-top: 8rem;
  padding-bottom: 5rem;
}

.detail-form .container {
  max-width: 1230px;
}

.detail-form h3 {
  font-size: 23px;
  color: #152232;
}

.detail-form .form-group .form-label {
  font-size: 16px;
  color: #152232;
  font-weight: 500;
  padding-bottom: 5px;
  font-family: "Inter", sans-serif !important;
}

.detail-form .form-group .input-group {
  height: 43px;
  border-radius: 7px;
  color: rgb(123, 129, 141);
  font-weight: 400;
  border: 1px solid #dbdcde;
  padding: 0px 5px;
  margin-bottom: 10px;
}

.detail-form .form-group .input-group.location-input {
  margin-bottom: 0px;
}

.detail-form .form-group .input-group .input-group-text {
  background: transparent;
  border: 0px;
  border-radius: 100px;
  padding: 0px 6px;
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.detail-form .form-group .input-group .form-control {
  border: 1px solid transparent;
  padding: 0px;
  font-family: "Inter", sans-serif !important;
  color: #7b818d;
  font-weight: 500;
  font-size: 14px;
}

.detail-form .form-group .input-group .form-control:focus {
  border-color: transparent;
  box-shadow: rgb(49 130 206) 0px 0px 0px 0px;
}

.detail-form .form-group .input-group:focus-within {
  box-shadow: 0 0 0 0.2rem rgb(13 110 253 / 25%);
  border: 1px solid #1f67d3;
}
.detail-form .form-group .input-group .form-control {
  font-weight: 400;
}
.detail-form .form-group .input-group .input-group-text i {
  color: #7b818d;
  font-size: 20px;
}

.detail-form .continue-btn {
  height: 46px;
  float: right;
  background: #fcbb52;
  border-radius: 6px;
  border-color: #fcbb52;
  color: #152232;
  font-size: 15px;
  font-weight: 450;
}

.detail-form .continue-btn:focus,
.detail-form .continue-btn:hover {
  background: #fcbb52;
  border-radius: 6px;
  color: #152232;
  border-color: #fcbb52;
  box-shadow: rgb(49 130 206) 0px 0px 0px 0px;
}

.detail-form .vehicle-location-place {
  cursor: pointer;
  width: 100%;
  position: relative;
  z-index: 21;
  border-radius: 10px;
}

.detail-form .location-err {
  margin-top: 10px;
}
.detail-form .vehicle-location-place .autoplace-location{
  position: absolute;
  width: 100%;
}

.detail-form .react-datepicker-wrapper{
  width: 100%;
  height: 100%;
}
.detail-form .react-datepicker-wrapper .react-datepicker__input-container{
  height: 100%;
  width: 100%;
}
.detail-form .react-datepicker-wrapper .react-datepicker__input-container input{
  height: 100%;
  width: 100%;
}
.detail-form .react-datepicker-popper{
  z-index: 199;
}
.detail-form .react-datepicker__year-read-view--down-arrow,
.detail-form .react-datepicker__month-read-view--down-arrow,
.detail-form .react-datepicker__month-year-read-view--down-arrow,
.detail-form .react-datepicker__navigation-icon::before{
  top: 13px;
}
.detail-form .form-control .date-pickers{
  border-color: transparent;
}
.detail-form .form-control .date-pickers:focus-visible{
  outline: 0px;
  border-color: transparent;
}
.detail-form .react-datepicker__year .react-datepicker__year-text{
  width: 3.3rem;
}
/* 
  4:48 Feb - 5 / 2022
*/
/* --------------------------------------------- */
/* Mini Laptop and All Tabs Media Query (1024px) */
/* --------------------------------------------- */

@media all and (max-width: 1024px) {
  .detail-form .container {
    max-width: 85%;
  }
 
  .detail-form .container .row .row .col-sm-12 {
    width: 50%;
  }
  
  .detail-form .container .col-sm-12 {
    width: 50%;
  }
  .detail-form .react-datepicker__year-read-view--down-arrow,
  .detail-form .react-datepicker__month-read-view--down-arrow,
  .detail-form .react-datepicker__month-year-read-view--down-arrow,
  .detail-form .react-datepicker__navigation-icon::before{
    top: 13px;
  }

}
/* --------------------------------------------- */
/* Mini Tab and All Tabs Media Query (991px) */
/* --------------------------------------------- */
@media all and (max-width: 991px) {
  .detail-form .container .col-sm-12 {
    width: 100%;
  }
  .detail-form .continue-btn {
    margin-top: 25px;
  }
}
/* --------------------------------------------- */
/* Mini Tab and All Tabs Media Query (767px) */
/* --------------------------------------------- */
@media all and (max-width: 767px) {
  .detail-form .container .col-sm-12 {
    width: 100%;
  }

  .detail-form .container .row .row .col-sm-12 {
    width: 50%;
  }
  
  .detail-form .form-group .input-group .form-control {
    font-size: 12px;
  }
  .detail-form .form-group .form-label {
    font-size: 15px;
  }
  .detail-form .form-group .input-group .input-group-text i {
    font-size: 18px;
  }
  .detail-form .form-group .input-group .input-group-text img {
    width: 25px !important;
  }
}
/* ----------------------------------------------- */
/* Mini Mobile and All Mobiles Media Query (480px) */
/* ----------------------------------------------- */
@media all and (max-width: 480px) {
  .detail-form h3 {
    font-size: 20px;
  }
  .detail-form .container .row .row .col-sm-12 {
    padding-top: 4px;
  }
  .detail-form .form-group .form-label {
    font-size: 14px;
    color: #152232;
    font-weight: 500;
    padding-bottom: 0px;
    margin-bottom: 5px;
    font-family: "Inter", sans-serif !important;
  }
  .detail-form .form-group .input-group .input-group-text img {
    height: 24px !important;
    width: 100%;
  }
  .detail-form .form-group .input-group .input-group-text i {
    font-size: 17px;
    color: #7b818d;
  }
  .detail-form .form-group .input-group {
    height: 43px;
  }
  .detail-form .form-group .input-group .form-control {
    padding: 0px;
    border: 1px solid transparent;
    border-radius: 9px;
    font-size: 14px;
  }
  .detail-form form .row {
    padding: 0px 10px;
  }
  .detail-form .container {
    max-width: 100%;
  }
  .detail-form .continue-btn {
    margin-top: 20px;
  }
}

/* ------------------------------- */
/* Mini Mobile Media Query (480px) */
/* ------------------------------- */
@media all and (max-width: 320px) {
  .detail-form .form-group .form-label {
    font-size: 12px !important;
  }
  .detail-form .form-group .input-group .form-control {
    font-size: 12px;
  }
  .detail-form .form-group .input-group .input-group-text i {
    font-size: 15px;
  }
  .detail-form .form-group .input-group .input-group-text img {
    height: 40px !important;
    width: 22px !important;
  }
  .detail-form .form-group .input-group {
    padding: 0px 5px 0px 0px;
  }
  .detail-form .continue-btn {
    font-family: "Inter", sans-serif;
    font-size: 14px;
  }
  .detail-form h3 {
    font-size: 18px;
  }
  .detail-form .form-group .text-danger {
    font-size: 12px;
  }
  .detail-form .form-group .input-group {
    margin: 0px;
  }
}
