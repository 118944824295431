.payment-modal .modal-content {
  width: 58%;
  position: absolute;
  border-radius: 0px;
  border: 0px;
  height: 400px;
}
.payment-modal .modal-dialog{
    display: flex!important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.payment-modal .payment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.payment-modal .payment .success-img {
  width: 124px;
  height: 124px;
  margin-top: 30px;
  padding: 10px;
}

.payment-modal .payment .payment-succ {
  font-size: 24px;
  color: #152232;
  font-family: "Inter", sans-serif !important;
  margin-top: 17px;
  font-weight: 500;
}

.payment-modal .payment .payment-id {
  font-size: 19px;
  color: #7b818d;
  margin-top: 8px;
  font-family: "Inter", sans-serif !important;
}

.payment-modal .payment .transaction-id {
  font-weight: 500;
  font-size: 19px;
  color: #152232;
  font-family: "Inter", sans-serif !important;
}

.payment-modal .payment .viewdet-btn {
  height: 55px;
  background: #fcbb52;
  border-radius: 8px;
  font-size: 14px;
  color: #000000;
  font-weight: 500;
  font-family: "Inter", sans-serif !important;
  padding: 0 100px;
  margin-top: 20px;
}

/* 
  Tab View Media Query
*/
@media only screen and (min-width: 724px) and (max-width: 1024px){
  .payment-modal .modal-content {
    width: 100%;
    position: absolute;
    border-radius: 0px;
    border: 0px;
    height: 500px;
  }
}
/* --------------------------------------*/
/* Mini tab And All Tab View Media Query (767px)  */
/* --------------------------------------*/
@media all and (max-width:767px){
  .payment-modal .modal-content{
    width: 100%;
  }
}
/* Mini Tab */
@media only screen and (min-width: 475px)  and (max-width: 624px){
  .payment-modal .modal-content{
    width:90%;
  }
}
/* 
  Mobile View Media Query
*/
@media only screen and (min-width: 312px) and (max-width: 476px){
  .payment-modal .modal-content {
    width: 100%;
    position: absolute;
    border-radius: 0px;
    border: 0px;
    height: 435px;
  }
  .payment-modal .payment .payment-succ{
    font-size: 20px;
  }
  .payment-modal .payment .payment-id{
    font-size: 17px;
  }
  .payment-modal .payment .viewdet-btn{
    padding: 0px 70px;
  }
}

/* --------------------------------------*/
/* Mini Mobile View Media Query (320px)  */
/* --------------------------------------*/
@media all and (max-width: 320px){
  .payment-modal .payment .payment-succ{
    font-size: 17px;
  }
  .payment-modal .payment .success-img{
    height: 70px;
    width: 70px;
  }
  .payment-modal .payment .payment-id{
    font-size: 13px;
  }
  .payment-modal .payment .transaction-id{
    font-size: 13px;
  }
  .payment-modal .payment .viewdet-btn{
    height: 48px;
    margin-top: 3rem;
    font-size: 14px;
    width: 100%;
    padding: 0 30px;
  }
  .payment-modal .modal-content{
    height: 350px;
  }
}