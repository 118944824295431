.request .container {
  padding-bottom: 6rem;
  padding-top: 2rem;
}
.request .req-list .req-list-div {
  margin: 0px 0px 10px 0px;
  padding: 0px 15px;
}
.request .collapsible {
  background: #efefef;
  cursor: pointer;
  padding: 10px 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  border: 1px solid #e6e6e6;
  border-radius: 8px 8px 0px 0px;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: relative;
}

.request .active,
.request .collapsible:hover {
  background-color: #ebeced;
}

.request .content {
  background-color: #ffffff;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  height: 0px;
  overflow: hidden;
  margin-top: 10px;
}
.request .collapsible.active + .collapsible-bottom .content {
  height: 100%;
  overflow: hidden;
}
.request .collapsible .request-list,
.request .collapsible .request-list {
  border-bottom: 0px solid transparent !important;
  margin: 0px;
}
.request .request-list thead th,
.request .request-list-collapse th {
  color: #7b818d;
  font-family: "Inter", sans-serif !important;
  font-weight: 500;
  font-size: 13px;
}
.request .request-list .primaryTr,
.request .request-list-collapse .primaryTr {
  color: #152232;
  font-family: "Inter", sans-serif !important;
  font-size: 15px;
  font-weight: 500;
}
.request table {
  font-family: "Inter", sans-serif !important;
}
.request .request-list .table-status {
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.request .request-list .table-status .progress-section .progress-btn {
  margin-right: 2rem;
}
.request .request-list .table-status .progress-section .progress-btn.completed {
  padding: 6px 20px;
  background: #ace0a1;
  border-radius: 14px;
  color: #197c3b;
}
.request
  .request-list
  .table-status
  .progress-section
  .progress-btn.processing {
  padding: 6px 20px;
  background: #c7e3ff;
  border-radius: 14px;
  color: #2486ea;
}

.request .request-list .table-status .review-btn {
  padding: 6px 15px;
  background: #e3e3e3;
  border-radius: 14px;
  color: #7b818d;
  white-space: nowrap;
  margin: 0px 5px;
}

.request .collapsible-bottom .request-list-collapse .attach-file{
  cursor: pointer;
}

/*Payment receipt */

.request .collapsible-bottom .request-list-collapse .pay-whno {
  color: #FCBB52;
  font-weight: 600;
  padding-left: 5px;
}

.request .collapsible-bottom .request-list-collapse .receipt{
    display: inline-block;
    position: relative;
}

.request .collapsible-bottom .request-list-collapse .receipt .close_img {
    position: absolute;
    right: -10px;
    top: -10px;
    cursor: pointer;
}

.request .collapsible-bottom .request-list-collapse .receipt .receipt_img{
   width: 70px;
   cursor: pointer;
}

.request .req-list.desktop {
  display: block;
}
.request .req-list.mobile {
  display: none;
}
.request .collapsible-bottom.active + .content {
  height: 100%;
  overflow: hidden;
}
.request .collapsible-bottom .request-list,
.request .collapsible-bottom .request-list {
  border-bottom: 0px solid transparent !important;
  margin: 0px;
}
.request .collapsible-bottom {
  background: white;
  padding: 10px 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  border: 1px solid #e6e6e6;
  border-radius: 0px 0px 8px 8px;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.request .collapsible-bottom,
.request .collapsible-bottom:hover {
  background-color: #ffffff;
}
.request .collapsible tr th,
.request .collapsible-bottom tr th,
.request .collapsible tr td,
.request .collapsible-bottom tr td {
  width: 20%;
}
.request .collapsible-bottom .image-data-list {
  display: flex;
}

.request .collapsible-bottom .request-list-collapse .image-list,
.request .collapsible-bottom .request-list-collapse .video-list,
.request .collapsible-bottom .request-list-collapse .pdf-list {
  width: 57px;
  height: 55px;
  margin: 3px 7px;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem;
}
.request .collapsible-bottom .request-list-collapse .image-list img,
.request .collapsible-bottom .request-list-collapse .video-list img,
.request .collapsible-bottom .request-list-collapse .pdf-list img {
  height: 100%;
  width: 100%;
  border-radius: 6px;
  box-shadow: rgb(20 20 20 / 15%) 0rem 0.5rem 1.625rem -0.25rem,
    rgb(20 20 20 / 6%) 0rem 0.5rem 0.5625rem -0.3125rem;
  object-fit: contain;
}

.nodata {
  text-align: center;
  padding: 20px;
}

.nodata h5 {
 font-size: 20px;
}
.request .container .upload-payment-ui .upload-ui-section{
  width: 90%;
}
.request .container .upload-payment-ui .upload-ui-section div.upload-ui-inner{
  width: 100%;
}
.request .upload-ui-div{
  padding: 3.5rem 0px;
  position: relative;
  border: 1px dashed #7494ec;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f4f7ff;
}
.request .upload-image-div{
  padding: 3rem 13rem;
  border: 1px dashed #7494ec;
  position: relative;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f4f7ff;
}
.request .upload-ui-div .fa-cloud-upload{
  font-size: 40px;
  color: #7494ec;
}
.request .upload-ui-div span{
  line-height: 35px;
  color: #909cbf;
}
.request .container .upload-payment-ui{
  padding-bottom: 0rem;
  padding-top: 0rem;
  display: flex;
  justify-content: center;
}
.request .container .upload-image-ui{
  padding-bottom: 0rem;
  padding-top: 0rem;
  display: flex;
  justify-content: flex-start;
}
.request .upload-ui-div .button-browse{
  margin-top: 17px;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  letter-spacing: 0.02857em;
  min-width: 64px;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 700;
  border-radius: 0.5rem;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  transition: all 150ms ease-in 0s;
  min-height: 2.5rem;
  padding: 0.625rem 1.5rem;
  background-image: linear-gradient(195deg, rgb(116 148 236), rgb(116 148 236));
  background-position-y: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  color: rgb(255, 255, 255);
  box-shadow: rgb(147 178 229) 0rem 0.1875rem 0.1875rem 0rem, rgb(181 198 246) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(179 197 245) 0rem 0.0625rem 0.3125rem 0rem;
  background-size: 150% !important;
  background-position-x: 25% !important;
}
/* --------------------------------------*/
/* Mini Laptop And All Tab View Media Query (1024px)  */
/* --------------------------------------*/
@media all and (max-width: 1024px) {
  .request .container {
    max-width: 100%;
  }
  .request .collapsible {
    padding: 10px 10px;
  }
  .request .collapsible tr th,
  .request .collapsible-bottom tr th,
  .request .collapsible tr td,
  .request .collapsible-bottom tr td {
    width: 25%;
  }
}

/* --------------------------------------*/
/* Mini tab And All Tab View Media Query (767px)  */
/* --------------------------------------*/
@media all and (max-width: 767px) {
  .request .flex-1 {
    display: flex;
  }
  .request .secound {
    color: #7b818d;
    font-size: 12px;
  }
  .request .primary {
    color: #152232;
    font-weight: 500;
    font-size: 14px;
  }
  .request .container {
    padding-left: 0px;
    padding-right: 0px;
    max-width: 95%;
  }
  .request .req-list.desktop {
    display: none;
  }
  .request .req-list.mobile .req-list-div {
    overflow: scroll;
  }
  .request .mobile .table-status {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .request .mobile .table-status .progress-section{
    display: flex;
    align-items: center;
  }
  .request .col-6.status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
  .myaccount .myaccount-tabs .nav-pills .nav-item .nav-link .tabs-list{
    padding: 0px 12px;
  }
  .request .mobile .progress-section .processing {
    padding: 5px 12px;
    background: #c7e3ff;
    border-radius: 14px;
    color: #2486ea;
    margin-right: 4px;
    font-size: 12px;
    text-align: center;
  }
  .request .mobile .progress-section .completed {
    padding: 5px 12px;
    background: #ace0a1;
    border-radius: 14px;
    color: #197c3b;
    font-size: 12px;
    margin-right: 4px;
  }
  .request .mobile .table-status .arrow-section {
    height: 20px;
    width: 20px;
  }
  .request .mobile .table-status .arrow-section img {
    height: 100%;
    width: 100%;
  }
  .request .req-list.mobile {
    display: block;
  }

  .request .req-list .req-list-div {
    overflow: scroll;
  }
  .request .collapsible-bottom tr {
    width: 100%;
  }
  .request .collapsible-bottom tr th,
  .request td,
  .request th {
    white-space: nowrap;
  }

  
  .request .mobile .progress-section .review-btn {
    background: #e3e3e3;
    border-radius: 14px;
    color: #7b818d;
    border-radius: 14px;
    margin-right: 4px;
    font-size: 12px;
    text-align: center;
    vertical-align: middle;
    padding: 5px 12px;
  }
  .request .upload-ui-div{
    padding: 2.1rem 0px;
  }
  .request .container.upload-payment-ui{
    max-width: 100%;
  }
  .request .container .upload-payment-ui .upload-ui-section{
    width: 100%;
  }
}

/* --------------------------------------*/
/* Mini tab And All Mobile View Media Query (575px)  */
/* --------------------------------------*/
@media all and (max-width: 575px) {
  .request .container {
    padding-top: 0px;
    max-width: 93%;
  }
  .request .mobile .table-status{
    position: relative;
    top: 0px;
    right: 0pc;
    justify-content: flex-end;
  }
  .request .mobile .progress-section .review-btn {
    background: #e3e3e3;
    border-radius: 14px;
    color: #7b818d;
    border-radius: 14px;
    margin-right: 4px;
    font-size: 12px;
    /* margin-bottom: 5px; */
  }
  .request .upload-ui-div span,
  .request .upload-ui-div .button-browse{
    font-size: 10px;
  }
  .request .upload-ui-div .fa-cloud-upload{
    font-size: 24px;
  }
  .request .upload-ui-div .button-browse{
    line-height: 1.2;
    min-height: 1.5rem;
  }
  .request .upload-ui-div span{
    line-height: 2.2;
  }
}
/* --------------------------------------*/
/* Mini Mobile And All Mobile View Media Query (480px)  */
/* --------------------------------------*/
@media all and (max-width: 480px) {
  .request .container {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 100%;
  }
  .request .flex-1 {
    display: flex;
  }
  .request .secound {
    color: #7b818d;
    font-size: 12px;
  }
  .request .primary {
    color: #152232;
    font-size: 14px;
    font-weight: 500;
  }
  .request .req-list.desktop {
    display: none;
  }
  .request .req-list.mobile .req-list-div {
    overflow: scroll;
  }
  .request .mobile .table-status {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-end;
  }
  .request .mobile .table-status .progress-section{
    flex-direction: row;
    align-items: center;
  }
  .request .col-6.status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
  .request .mobile .progress-section .processing {
    padding: 5px 12px;
    background: #c7e3ff;
    border-radius: 14px;
    color: #2486ea;
    margin-right: 6px;
    font-size: 12px;
  }
  .request .mobile .progress-section .completed {
    padding: 5px 12px;
    background: #ace0a1;
    border-radius: 14px;
    color: #197c3b;
    font-size: 12px;
    margin-right: 0px;
  }
  .request .mobile .table-status .arrow-section {
    height: 20px;
    width: 20px;
  }
  .request .mobile .table-status .arrow-section img {
    height: 100%;
    width: 100%;
  }
  .request .req-list.mobile {
    display: block;
  }

  .request .req-list .req-list-div {
    overflow: scroll;
  }
  .request .collapsible-bottom tr {
    width: 100%;
  }
  .request .collapsible-bottom tr th,
  .request td,
  .request th {
    white-space: nowrap;
  }

  .request .mobile .progress-section .review-btn {
    background: #e3e3e3;
    border-radius: 14px;
    color: #7b818d;
    border-radius: 14px;
    margin-right: 7px;
    font-size: 12px;
    margin-bottom: 0px;
  }

  .request .collapsible-bottom .recipt-mobile{
    white-space: break-spaces !important;
  }

}
/* --------------------------------------*/
/* Mini Mobile View Media Query (320px)  */
/* --------------------------------------*/
@media all and (max-width: 320px) {
  .request .mobile .progress-section .processing {
    padding: 4px 8px;
  }
  .request .mobile .progress-section .review-btn {
    background: #e3e3e3;
    border-radius: 14px;
    color: #7b818d;
    border-radius: 14px;
    margin-right: 4px;
    font-size: 12px;
  }
  .request .primary {
    font-size: 13px;
  }
}
