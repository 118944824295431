.admin.account-pages .logo-title{
    font-size: 32px;
    padding-top: 30px;
    color: #383936;
    font-weight: 900;
}

.admin.account-pages{
    height: 100%;
    width: 100%;
}

.admin.account-pages .logo-title label{
    font-weight: 900;
    font-size: 32px;
    color: #fcbb52;
}
.login-dashboard .admin.account-pages .input-group input{
    border-right: 0px solid transparent;
}
.login-dashboard .admin.account-pages .input-group .input-group-text{
    background-color: transparent;
    border-left: 0px solid transparent;
    color: #343a40;
}
.admin.btn-submit{
    background: #fcbb52;
    color: #343a40;
    font-weight: 500;
}

.admin.form-check .admin.form-check-input:checked[type=checkbox]{
  background-image: url(../../../../assets/remember_me.svg);
}
