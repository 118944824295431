.profile .profile-box .profile-form .form-title {
  font-size: 15px;
  color: #152232;
  font-weight: 500;
  padding-bottom: 5px;
  font-family: "Inter", sans-serif !important;
}
.profile .profile-box .profile-form .input-field:focus {
  z-index: 1;
  border-color: transparent;
  box-shadow: rgb(49 130 206) 0px 0px 0px 0px;
}
.profile p,
.profile h5,
.profile h6,
.profile button,
.profile input {
  font-family: "Inter", sans-serif !important;
}
.profile .profile-box .profile-form .input-field {
  font-family: "Inter", sans-serif !important;
  outline: transparent solid 2px;
  height: 100%;
}
.profile .profile-box .profile-form .input-group {
  border: 1px solid #dbdcde;
  padding: 0px 5px;
}
.profile .profile-box .profile-form .input-group:hover {
  background-color: #fff;
}
.profile .profile-box .profile-form .input-group-text {
  background: transparent;
  border: 0px;
  border-radius: 100px;
  padding: 0px 6px;
  height: 100%;
  width: 37px;
}
.profile .profile-box .profile-form input.input-field {
  padding: 0px;
  border: 1px solid transparent;
  border-radius: 9px;
  font-size: 14px;
}
.profile .profile-box .profile-form .input-group:focus-within {
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
.profile .change-btn,
.profile .save-btn {
  font-size: 12px !important;
  color: #152232;
  font-weight: 500;
  font-family: "Inter", sans-serif !important;
}
.profile .save-btn {
  height: 39px;
}
.profile .profile-box {
  padding-top: 2rem;
}
.profile .profile-box .profile-action {
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  height: 100%;
  align-items: flex-end;
}
.profile .form-label {
  font-size: 15px;
  color: #152232;
  font-weight: 500;
  font-family: "Inter", sans-serif !important;
}
.profile .shadow-btn {
  box-shadow: rgb(26 115 232 / 15%) 0rem 0.1875rem 0.1875rem 0rem,
    rgb(26 115 232 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem,
    rgb(26 115 232 / 15%) 0rem 0.0625rem 0.3125rem 0rem;
  transition: all 150ms ease-in 0s;
  border: 1px solid transparent;
}
.profile .shadow-btn:hover {
  border-color: transparent !important;
  border: 1px solid transparent;
  transform: scale(1.02);
}
.profile .container {
  padding-bottom: 6rem;
  padding-top: 2rem;
}
.profile .profile-panel .profile-page {
  padding-left: 2rem !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 2rem !important;
  box-shadow: rgb(20 20 20 / 15%) 0rem 0.5rem 1.625rem -0.25rem,
    rgb(20 20 20 / 6%) 0rem 0.5rem 0.5625rem;
}
.profile .profile-panel .profile-page .pro-pic-div {
  display: flex;
  align-items: center;
}
.profile .profile-panel .profile-page .pro-image-div {
  height: 65px;
  width: 65px;
  margin-right: 10px;
}
.profile .profile-panel .profile-page .pro-image-div img {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}
.profile .profile-panel .profile-page .primarytxt {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  margin: 0px;
  line-height: 1.8;
}
.profile .profile-panel .profile-page .secoundrytxt {
  color: #ffffff;
  font-size: 13px;
  font-weight: 450;
  margin: 0px;
  line-height: 1.8;
}
.profile .logout-div button {
  background: #ff3c3c;
  border-radius: 3px;
  font-size: 12px;
  padding: 5px 10px;
  box-shadow: rgb(26 115 232 / 15%) 0rem 0.1875rem 0.1875rem 0rem,
    rgb(26 115 232 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem,
    rgb(26 115 232 / 15%) 0rem 0.0625rem 0.3125rem 0rem;
  transition: all 150ms ease-in 0s;
  border: 1px solid transparent;
}
.profile .logout-div button:focus {
  box-shadow: rgb(26 115 232 / 15%) 0rem 0.1875rem 0.1875rem 0rem,
    rgb(26 115 232 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem,
    rgb(26 115 232 / 15%) 0rem 0.0625rem 0.3125rem 0rem;
}
.profile .profile-box .profile-form {
  display: flex;
  flex-wrap: wrap;
}
.profile .profile-box .profile-form .col-6 {
  padding: 0px 15px;
}
.profile .profile-div .profile-panel {
  padding: 0px 15px;
}

.profile .profile-pic {
   margin-right: 10px;
}
.profile .img-profile-div{
  position: relative;
}
.profile .img-profile-div .profile-pic{
  position: absolute;
  top: -8px;
  right: -10px;
}
.profile .profile-pic label{
  font-size: 11px;
    font-weight: 300;
    cursor: pointer;
    border-radius: 42px;
    padding: 5px 8px;
    background: #fcbb52;
    color: #383936 !important;
}


/* 
  4:48 Feb - 5 / 2022
*/
/* --------------------------------------*/
/* Mini laptop and All Tabs View Media Query (1024px)  */
/* --------------------------------------*/
@media all and (max-width: 1024px) {
  .profile .container {
    max-width: 100%;
  }
  .profile .profile-box .profile-action button {
    padding: 6px 5px !important;
  }
  .profile .profile-box .profile-form {
    display: flex;
    flex-wrap: wrap;
  }
  .profile .profile-box .profile-form .col-6 {
    padding: 0px 15px;
  }
}
/* --------------------------------------*/
/* Mini Tab and All Tabs View Media Query (767px)  */
/* --------------------------------------*/
@media all and (max-width: 767px) {
  .profile .container {
    padding-top: 0px;
    max-width: 95%;
  }

  .profile .profile-panel .profile-page {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .profile .profile-box .profile-form .col-6 {
    width: 100%;
  }
  .profile .profile-box .profile-form .shadow-btn {
    padding: 6px 6px !important;
  }
}
/* --------------------------------------*/
/* Mini tab And All Mobile View Media Query (575px)  */
/* --------------------------------------*/
@media all and (max-width: 575px) {
  .profile .container {
    padding-top: 0px;
    max-width: 93%;
  }
}
/* --------------------------------------*/
/* Mini Mobile and All Mobile View Media Query (480px)  */
/* --------------------------------------*/
@media all and (max-width: 480px) {
  .profile .container {
    padding-top: 0px;
    max-width: 95%;
  }
  .profile .form-label {
    font-size: 14px;
  }
  .profile .profile-box .profile-form .input-field {
    font-size: 14px !important;
  }
  .profile .profile-panel .profile-page {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .profile .profile-box .profile-form .col-6 {
    width: 100%;
  }
  .profile .profile-box .profile-form .shadow-btn {
    padding: 6px 6px !important;
  }
  .profile .profile-panel .profile-page .pro-image-div {
    height: 99px;
    width: 99px;
    padding: 10px;
  }
  .profile .img-profile-div{
    position: relative;
  }
  .profile .logout-div button {
    font-size: 9px;
  }
  .profile .profile-panel .profile-page .primarytxt {
    font-size: 12px;
  }
  .profile .profile-div .profile-panel {
    padding: 0px 10px;
  }
  .profile .profile-panel .profile-page{
    flex-direction: column;
    position: relative;
  }
  .profile .profile-panel .profile-page .pro-pic-div{
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
  .profile .profile-panel .profile-page .pro-image-div,
  .profile .profile-pic{
    margin-right: 0px;
  }
  .profile .profile-panel .logout-div{
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .profile .img-profile-div .profile-pic{
    position: absolute;
    top: 3px;
    right: 3px;
  }
  .profile .profile-box .profile-action{
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    align-items: stretch;
  }
}
/* --------------------------------------*/
/* Mini Mobile View Media Query (320px)  */
/* --------------------------------------*/
@media all and (max-width: 320px) {
  .profile .form-label {
    font-size: 12px;
  }
  .profile .profile-box .profile-form input.input-field {
    font-size: 12px !important;
  }
  .profile .profile-box .profile-form .input-group-text {
    width: 33px;
  }
  .profile .profile-box .profile-form .shadow-btn {
    font-size: 12px !important;
  }
  .profile .profile-box .profile-form .col-6 {
    padding: 0px 10px;
    margin-bottom: 10px !important;
  }
  .profile .profile-box .profile-action {
    display: flex;
    flex-direction: column-reverse;
  }
  .profile .profile-box .profile-action .col {
    width: 100%;
  }
  .profile .profile-div .profile-panel {
    padding: 0px 5px;
  }
  .profile .profile-panel .profile-page{
    padding: 2.3rem 2rem 2rem 2rem!important;
  }
  .profile .profile-panel .logout-div{
    right: 5px;
    top: 5px;
  }
}
