.main-dashboard .admin-content .total-buyer{
    width: 39px;
    height: 23px;
    position: absolute;
    vertical-align: middle;
    border: 0px;
    background: none;
    font: bold 14px Arial;
    text-align: center;
    color: rgb(240, 80, 80);
    padding: 0px;
    appearance: none;
}
.main-dashboard .btn-request:hover {
    color: #fcbb52!important;
    background: #fff;
    border: 1px solid #fcbb52;
}
.main-dashboard .admin-content .nav-tabs.nav-bordered .admin.nav-item a{
    font-weight: 900;
    color: #252626!important;
}
.main-dashboard .admin-content th{
    font-weight: 700;
    font-family: Karla, sans-serif;
    font-size: 16px;
}
.main-dashboard .admin-content td{
    font-family: Karla, sans-serif;
    font-size: 15px;
}
.main-dashboard .admin-content .bg-orange {
    background-color: #fd9554 !important;
}
.main-dashboard .admin-content .card .card-body h5{
    font-weight: 700;
    color: #343a40;
}
.main-dashboard .admin-content .row-title{
    margin: 10px 0;
    font-family: Karla, sans-serif;
    color: #343a40;
    font-weight: 600;
    font-size: 1.125rem;
}
.main-dashboard .admin-content .total-seller{
    width: 39px;
    height: 23px;
    position: absolute;
    vertical-align: middle;
    border: 0px;
    background: none;
    font: bold 14px Arial;
    text-align: center;
    color: rgb(255, 189, 74);
    padding: 0px;
    appearance: none;
}

.main-dashboard .admin-content .progress-circles {
    height: 70px;
}
.main-dashboard .admin-content .progress-circles .CircularProgressbar .CircularProgressbar-path{
    color: red;
}
.main-dashboard .admin-content .dropdown button.dropdown-toggle{
    background-color: white!important;
    vertical-align: middle;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px 6px;
}
.main-dashboard .admin-content .dropdown button.dropdown-toggle:focus{
    box-shadow: 0 0 0 0rem rgb(49 132 253 / 50%);
}
.main-dashboard .admin-content .dropdown button.dropdown-toggle::after{
    display: none!important;
}

.main-dashboard .admin-content .dropdown .dropdown-menu{
    box-shadow: 0 5px 20px rgb(0 0 0 / 20%);
    animation-name: DropDownSlide;
    animation-duration: .3s;
    top: 100%;
    left: 0px;
    border: none;
}

.main-dashboard .admin-content .total-cnt .nav-item{
    padding: 0pc;
}
.main-dashboard .admin-content .total-cnt .nav-item .nav-link{
    padding: 10px 6px;
    margin: 0px 12px;
    background-color: white;
    border-radius: 0px;
}
.main-dashboard .admin-content .total-cnt .nav-item .nav-link.active{
    border-bottom: 2px solid #fcbb52;
}
.main-dashboard .admin-content .total-cnt .nav-item .nav-link.active div{
    color: #fcbb52;
}
.main-dashboard .admin-content .total-cnt .nav-item .nav-link .header-title{
    background-color: white!important;
    color: #252626;
    margin-bottom: 0px;
}
.main-dashboard .admin-content .admin.nav-bordered li a{
    padding: 0px;
    color: #252626;
}
.main-dashboard .admin-content .total-cnt.admin.nav-bordered{
    border: 0px solid!important
}
.main-dashboard .admin-content .total-cnt + .tab-content{
    margin-top: 20px;
}
.admin-content .text-viewall{
    color: #343a40;
    float: right;
    font-size: 13px;
}
.admin-dash-main.admin-content .admin.img-fluid {
    width: 100%;
    height: 100%;
}
.admin.inbox-widget .admin.inbox-item .admin.inbox-item-img img{
    height: 40px;
}
/* Laptops and Desktops */
@media (min-width: 1099px) and (max-width: 1280px){
    .admin-dash-main .admin.col-xl-3{
        width: 50%;
    }
}

/* --------------------------------------*/
/* Mini tab And All Mobile View Media Query (575px)  */
/* --------------------------------------*/
@media all and (max-width: 575px){
    .admin-dash-main.admin-content .d-flex{
        display: flex;
        flex-direction: row;
    }
}