.terms-main{
    padding-top: 8rem;
    font-family: 'Inter', sans-serif!important;
    text-align: justify;
}
.terms-main .container{
    padding: 0px 36px;
    margin-bottom: 4rem;
}
.terms-main .title{
    color: #152232;
    font-size: 27px;
    padding-bottom: 20px;
    font-weight: 800;
}
.terms-main p{
    font-size: 15px;
    color: #58657E;
    font-family: 'Inter', sans-serif!important;
}
.terms-main ul li{
    color: #58657E;
    font-size: 15px;
    font-family: 'Inter', sans-serif!important;
}
.terms-main ul li::marker {
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 2px !important;
    text-align: start !important;
    text-align-last: start !important;
    color: #fcbb52;
    font-size: 20px;
}
/* ----------------------------------------------- */
/* Mini Laptop and All Tab devices Media Query (1024px) */
/* ------------------------------------------------*/
@media all and (max-width: 1024px){
    .terms-main .container{
        max-width: 95%;
    }
}
/* ----------------------------------------------- */
/* Mini Tab and All Tab devices Media Query (767px) */
/* ------------------------------------------------*/
@media all and (max-width: 767px){
    .terms-main .container{
        max-width: 94%;
    }
}

/* ----------------------------------------------- */
/* Mini Mobile and All Mobile devices Media Query (480px) */
/* ------------------------------------------------*/
@media all and (max-width: 480px){
    .terms-main .container{
        max-width: 100%;
        padding: 0px 15px;
   }
   .terms-main p,
   .terms-main ul li{
       font-size: 14px;
   }
   .terms-main .title{
       font-size: 24px;
   }
}

/* ----------------------------------------------- */
/* Mini Mobile  View Media Query (320px) */
/* ------------------------------------------------*/
@media all and (max-width: 320px){
    .terms-main .title{
        font-size: 20px;
    }
    .terms-main p,
   .terms-main ul li{
       font-size: 12px;
   }
}