.buyers-content .form-switch .form-check-input{
    height: 24px;
    width: 44px;
}
.buyers-content .form-switch .form-check-input:checked{
    background-color: #f1b53d;
    border-color: #f1b53d;
}
.buyers-content .ripple {
    background-position: center;
    transition: background 0.8s;
}
.buyers-content .ripple:hover {
    background: #f1f3fa  radial-gradient(circle, transparent 1%, #f1f3fa  1%) center/15000%;
}
.buyers-content .ripple:active {
    background-color: #a7a7a7;
    background-size: 100%;
    transition: background 0s;
}
.buyers-content th,
.buyers-content td{
    font-size: 0.9rem;
    font-family: Roboto, sans-serif!important;
}