.footer p,
.footer label,
.footer h5,
.footer h6,
.footer button,
.footer span,
.footer input{
    font-family: 'Inter', sans-serif;
}

.footer .footer-img{
    width: 100%;
    position: relative;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    background-image: url("../../../assets/footer_ima@.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 280px;
}

.footer .footer-4 .footer-info{
    height: 100%;
    width: 100%;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.footer .footer-4.col-3 .footer-info li h6{
    margin-bottom: 18px;
}
.footer .footer-4 .footer-info h6{
    color: white;
    font-size: 20px;
    margin-bottom: 10px;
}
.footer .footer-4 .footer-info h6.subscib{
    margin-bottom: 23px;
}
.footer .p-4{
    padding-left: 4rem;
}
.footer .rights-divs{
    height: 50px;
    width: 100%;
    background: #1f232c;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer .contact-list{
    color: #7A87A7;
    font-size: 16px;
}
.footer .rights-divs label{
    color: #778EC4;
    font-size: 17px;
}
.footer .input-field{
    font-family: 'Inter', sans-serif!important;
    font-weight: 400;
    background-color: white;
    border-radius: 7px;
    height: 47px;
    margin-right: 5px;
}
.footer .input-field input{
    padding: 0px 0px 0px 20px;
    height: 47px;
}
.footer ul{
    padding-top: 25px;
}
.footer ul,
.footer li{
    list-style-type: none;
    line-height: 2;
}
.footer .container{
    max-width: 93%;
    margin-left: 5%;
    margin-right: 2%;
}
.footer .footer-lists{
    padding-left: 40px;
}
.footer input{
    padding-left: 10px;
    border: 1px solid grey;
}
.footer input:focus{
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
.footer a li{
    line-height: 3!important;
}
.footer a li:hover{
    color: #fcbb52;
}
.footer .social-list{
    display: flex;
    margin-top: 15px;
}
.footer .social-list .social-div{
    margin: 0px 3px;
    height: 40px;
    width: 40px;
}
.footer .social-list .social-div img{
    height: 100%;
    width: 100%;
    cursor: pointer;
}
.footer .container .col-12 .col-3.w-50{
    width: 20%!important;
}
.footer .container .col-12 .col-4{
    width: 40%!important;
}
/* 
  Tab View Media Query
*/
@media all and (max-width:  1024px){
    .footer .footer-lists{
        padding-left: 10px;
    }
    .footer .container{
        max-width: 100%;
        margin: 0px!important;
    }
    .footer .container .col-12{
        padding: 0px 10px;
        margin: 0px;
    }
    .footer .container .col-12 .col-3.w-50,
    .footer .container .col-12 .col-4.w-50{
        width: 50%!important;
    }
    .footer .contact-list{
        font-size: 14px;
    }
    .footer .footer-img{
        height: 100%;
        padding: 12px;
    }
    .footer .footer-4 .footer-info{
        align-items: flex-start;
    }
    .footer .container .col-12 .col-4{
        width: 100%!important;
    }
    .footer .footer-lists .subscirb{
        display: flex!important;
        align-items: center;
    }
    .footer .footer-lists li.subscirb .input-field{
        margin-bottom: 4px;
    }
    .footer .footer-lists li.subscirb button{
        float: right;
        margin-left: 10px;
    }
}
/* Mini Tabs  */
@media all and (max-width: 992px){

}
/* Mini Tab */
@media all and (max-width:767px){
    .footer .footer-img{
        height: 100%;
    }
    .footer .container{
        max-width: 100%;
        margin: 0px;
        padding: 0px;
    }
    .footer .footer-img .container .row{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin: 0px;
    }
    .footer .footer-img .container .row .footer-4{
        width: 100%!important;
    }
    .footer .subscirb{
        display: block!important;
    }
    .footer .footer-4 .footer-info{
        align-items: flex-start;
    }
    .footer .footer-lists{
        padding-left: 10px;
        width: 100%;
    }
    .footer .subscirb button{
        float: right;
        margin: 14px 10px;
    }
    .footer .rights-divs label{
        font-size: 12px;
    }
    .footer .footer-4 .footer-info h6{
        font-size: 15px;
    }
    .footer .contact-list{
        font-size: 12px;
        line-height: 30px!important;
    }
    .footer .input-field{
        width: 100%;
        height: 40px;
        font-size: 14px;
    }
    .footer .footer-4.col-3 .footer-info li h6{
        margin-bottom: 10px;
    }
   
}
@media all and (max-width: 667px){
  
}
/* 
  Mobile View Media Query
*/
@media all and (max-width: 480px){
    .footer .footer-img{
        height: 100%;
    }
    .footer .container{
        max-width: 100%;
        margin: 0px;
        padding: 0px;
    }
    .footer .footer-img .container .row{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin: 0px;
        padding: 0px;
    }
    .footer .footer-img .container .row .footer-4{
        width: 100%!important;
        padding: 0px;
    }
    .footer .footer-lists .subscirb{
        display: block!important;
    }
    .footer .footer-4 .footer-info{
        align-items: flex-start;
    }
    .footer .footer-lists{
        padding-left: 10px;
        width: 100%;
    }
    .footer .subscirb button{
        float: right;
        margin: 14px 10px;
    }
    .footer .rights-divs label{
        font-size: 12px;
    }
    .footer .footer-4 .footer-info h6{
        font-size: 15px;
    }
    .footer .contact-list{
        font-size: 12px;
        line-height: 30px!important;
    }
    .footer .input-field{
        width: 100%;
        height: 40px;
        font-size: 14px;
    }
    .footer .footer-4.col-3 .footer-info li h6{
        margin-bottom: 10px;
    }
    .footer .footer-lists li.subscirb button{
        float: right;
        margin: 10px 0px 10px 10px;
    }
   
}
/* 
    Mini Mobile View Media Query
*/
@media all and (max-width: 320px){
    .footer .footer-img .container .row .footer-4{
        padding: 0px;
    }
    .footer .footer-img .container .row{
        padding: 0px;
    }
    .footer .footer-4 .footer-info h6.subscib{
        margin-bottom: 14px;
    }
    .footer .footer-lists .subscirb{
        display: block!important;
    }
    .footer .footer-lists{
        padding-left: 0px;
    }
}