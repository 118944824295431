.package-info .modal-dialog {
max-width: 650px;
}

.package-info .modal-content{
  border-radius: 18px;
}

.package-info .modal-body{
  padding: 0px;
}

.package-info .modal-body .info-content{
 padding: 35px;
}

.package-info .modal-body .info-content .content-f{
    color: #152232;
    font-weight: 600;
    font-size: 17px;
    font-family: "Inter", sans-serif !important;
}

.package-info .modal-body .info-content .content-s{
    font-size: 15px;
    color: #7B818D;
    font-family: "Inter", sans-serif !important;
    font-weight: 500;
}

.package-info .modal-body .info-content .content-t{
    font-size: 15px;
    color: #7B818D;
    font-weight: 500;
    font-family: "Inter", sans-serif !important;
}

.package-info .modal-body img{
    position: absolute;
    right: 0;
    opacity: 0.57;
}

.package-info .modal-footer{
    padding: 0px;
    border-top: none;
    justify-content: flex-start;
}

.package-info .modal-footer button{
    border-radius: 8px;
    background: #FCBB52;
}