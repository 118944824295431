.card-form {
  padding-top: 12rem;
  
}


.card-form .detail {
  background: #FFF7EA;
  border-radius: 15px;
  padding: 25px;

}

.card-form .package-detail {
border-bottom: 2px dashed #EFD8B5;
}

.card-form .package-detail h5 { 
  font-size: 21px;
  font-weight: 600;
}

.card-form .package-detail h3 { 
  font-size: 27px;
  color: #F0A731;
  font-weight: 600;
}

.card-form .package-detail p ,
.card-form .package-detail-wh p{
  font-size: 19px;
    font-weight: 500;
    font-family: "Inter", sans-serif !important;
}

.card-form .bank-detail-parent {
  background: #F7F7F7;
  padding: 62px;
  border-radius: 22px;
}

.card-form .bank-detail-parent .table-responsive {
    position: relative;
}

.card-form .bank-detail {
  background: white;
  padding: 25px;
  margin-top: -105px;
  border-radius: 17px;
  box-shadow: 0 10px 30px rgb(0 0 0 / 20%);
  position: relative;
}

.card-form .bank-detail .doller-sapn{
  font-size: 140px;
  position: absolute;
  bottom: 0;
  right: 22%;
  color: #F6F6F6;
  font-weight: bold;
}

.card-form .bank-detail .bank-detail-header h5,
.card-form .bank-detail .bank-detail-header span {
  font-size: 25px;
  font-weight: 600;
}

.card-form .bank-detail .bank-detail-header span {
  color: #FCBB52;
}

.card-form .bank-detail-parent .bank-detail .bank-detail-header {
  display: flex;
  justify-content: space-between;
}

.card-form .bank-detail-parent .bank-detail .bank-detail-header img{
  width: 50px;
}

.card-form .bank-detail .account-detail .account-no-text {
  padding-top: 50px;
  font-size: 17px;
  font-style: italic;
  color: #9F9F9F;
  font-weight: 500;
  font-family: "Inter", sans-serif !important;
}

.card-form .bank-detail .account-detail .account-no {
  padding-top: 50px;
  font-size: 17px;
  color: #152232;
  font-weight: 500;
  font-family: "Inter", sans-serif !important;
}

.card-form .bank-detail .account-detail .bank-name-text {
  font-size: 17px;
  font-style: italic;
  color: #9F9F9F;
  font-weight: 500;
  font-family: "Inter", sans-serif !important;
  padding-top: 20px;
}

.card-form .bank-detail .account-detail .bank-name {
  padding-top: 20px;
  font-size: 17px;
  color: #152232;
  font-weight: 500;
  font-family: "Inter", sans-serif !important;
  position: absolute;
  z-index: 1;
}

.card-form .sub-order {
  text-align: center;
  padding: 15px 0px;
}

.card-form .sub-order .sub-order-btn {
  background: #FCBB52;
  border-radius: 6px;
  padding: 15px;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  cursor: pointer;
}



/* Responsive for mobile and tab */

/*--------------------------------------------*/
/* Laptop with Desktop (1600px) View*/
/*--------------------------------------------*/
@media all and (max-width:1600px){

}

/*--------------------------------------------*/
/* Laptop with Desktop (1400px) View*/
/*--------------------------------------------*/
@media all and (max-width:1400px){
}

/*--------------------------------------------*/
/* Laptop with Tab (1199px) View*/
/*--------------------------------------------*/
@media all and (max-width:1199px){
}

/* ------------------------------------- */
/* Mini laptop and mini desktop View Media Query (1200px) */
/* --------------------------------------*/
@media (max-width: 1200px) {
}

/* --------------------------------------*/
/* Mini Laptop And All Tab View Media Query (1024px)  */
/* --------------------------------------*/
@media all and (max-width:1024px){
}

/*--------------------------------------------*/
/* Mini Laptop with Tab (991px) View*/
/*--------------------------------------------*/
@media all and (max-width: 991px){

.card-form .col-sm-12{
  width: 100%;
  padding-top: 5rem;
}

.card-form{
  padding-top: 5rem;
}

}


/* --------------------------------------*/
/* Mini tab And All Tab View Media Query (767px)  */
/* --------------------------------------*/
@media all and (max-width:767px){

}

/* --------------------------------------*/
/* Mini tab And All Mobile View Media Query (575px)  */
/* --------------------------------------*/
@media all and (max-width:575px){
  
}

/* --------------------------------------*/
/* Mini Mobile And All Mobile View Media Query (480px)  */
/* --------------------------------------*/
@media all and (max-width: 480px) {
  .card-form .bank-detail{
    margin-top: auto;
  }
  .card-form .bank-detail-parent{
    padding: 0px;
  }
  .card-form .col-sm-12{
    padding-top: 4rem;
  }
  .card-form .sub-order{
    margin-top: 5px;
  }
  .card-form .package-detail h5{
    font-size: 18px;
  }
  .card-form .package-detail h3{
    font-size: 22px;
  }
  .card-form .package-detail p, .card-form .package-detail-wh p{
    font-size: 14px;
  }
  .card-form .bank-detail .bank-detail-header h5, .card-form .bank-detail .bank-detail-header span{
    font-size: 23px;
  }
  .card-form .bank-detail-parent .bank-detail .bank-detail-header img{
    width: auto;
    height: 40px;
  }
  .card-form .bank-detail .account-detail .account-no,
  .card-form .bank-detail .account-detail .account-no-text,
  .card-form .bank-detail .account-detail .bank-name-text,
  .card-form .bank-detail .account-detail .bank-name{
    font-size: 15px;
    padding-left: 0px;
  }
}

/* --------------------------------------*/
/* Mini Mobile View Media Query (320px)  */
/* --------------------------------------*/
@media all and (max-width: 320px) {

  .card-form .bank-detail .doller-sapn {
      left: 90%;
  }

  .card-form .bank-detail-parent{
    padding: 0px;
  }

  .card-form .bank-detail-parent .bank-detail .bank-detail-header{
      flex-direction: row;
  }
  .card-form .bank-detail .bank-detail-header h5, .card-form .bank-detail .bank-detail-header span{
    font-size: 20px;
  }
  .card-form .bank-detail-parent .bank-detail .bank-detail-header img{
    width: auto;
    height: 35px;
  }
  .card-form .bank-detail .account-detail .account-no-text,
  .card-form .bank-detail .account-detail .account-no{
    padding-top: 35px;
    font-size: 14px;
    padding-left: 0px;
  }
  .card-form .bank-detail .account-detail .bank-name-text,
  .card-form .bank-detail .account-detail .bank-name{
    font-size: 14px;
    padding-left: 0px;
  }
  .card-form .bank-detail{
    padding: 20px;
  }
  .card-form .package-detail h5{
    font-size: 18px;
  }
  .card-form .package-detail h3{
    font-size: 20px;
  }
  .card-form .package-detail p, .card-form .package-detail-wh p{
    font-size: 14px;
  }
  .card-form .bank-detail{
    margin-top: -20px;
  }
}