.myaccount-contents .flex-row{
    display: flex;
    align-items: center;
    flex-direction: row;
}
.myaccount-contents .admin.btn-warning,
.myaccount-contents .admin.btn-secondary{
    min-width: 100%;
    width: 100%;
}

/* --------------------------------------*/
/* Mini tab and All mobile View Media Query (780px)  */
/* --------------------------------------*/
@media all and (max-width: 860px){
    .myaccount-contents .admin.col-md-6{
        width: 100%;
    }
}

/* --------------------------------------*/
/* Mini mobile and All mobile View Media Query (480px)  */
/* --------------------------------------*/
@media all and (max-width: 480px){
    .myaccount-contents .flex-row{
        justify-content: space-between;
        flex-direction: column!important;
    }
    .myaccount-contents .flex-row .col-6.px-2{
        width: 100%!important;
    }
}